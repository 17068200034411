import React from 'react';
import soon from "../assets/images/app-coming-soon.png";

const Download = () => {
    return (
        <>
            <div className="justify-content-center d-flex align-items-center">
                <img className="img-fluid mt-5" src={soon} alt=""/>
            </div>
        </>
    );
};

export default Download;
