import React from 'react';
import {useParams} from 'react-router-dom';
import {loader} from "../config";
import {RequestGet} from "./Request";
import {RequestG} from "../routes/Request";
import EditJobItem from "./items/EditJobItem";
import Email from "./warning/Email";
import YaSubscription from "./warning/YaSubscription";
import NoSubscription from "./warning/NoSubscription";

const Repost = () => {
    const {id} = useParams();
    const [job, setJob] = React.useState(false);
    const [company, setCompany] = React.useState("");
    const [data, setData] = React.useState(null);
    const [fields, setFields] = React.useState(false);
    React.useEffect(() => {
        RequestGet('jobView/' + id).then((response) => {
            if (response.status === 200) {
                RequestGet('employ/company').then((response) => {
                    setCompany(response.company.name)
                    setData(response)
                })
                RequestG('fields').then((response) => {
                    setFields(response)
                })
                setJob(response.data)
            }
        })
    }, [id])
    if (job !== false && data !== null) {
        return (
            <>
                <Email/>
                {parseInt(data.company.id) === parseInt(job.company_id)
                    ?
                    <>
                        {data.annual !== null || data.payper !== null ? <YaSubscription data={data}/> :
                            <NoSubscription/>}
                        {data.annual !== null || data.payper !== null ?
                            <EditJobItem data={data} company={company} fields={fields} editJob={job}
                                         statusUpdate={false}
                                         subscription={data.annual !== null ? data.annual : data.payper}/>
                            : ""}
                    </>
                    : <>Deny</>}
            </>
        );
    } else {
        return loader("20rem")
    }
};

export default Repost;
