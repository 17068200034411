import React from 'react';
import {Button, TextField} from "@mui/material";
import {alertOptions, apiURI} from "../config";
import {toast} from "react-toastify";
import {useParams} from 'react-router-dom';

const ResetPassword = () => {
    const [email, setEmail] = React.useState();
    const [newPassword, setNewPassword] = React.useState();
    const [conPassword, setConPassword] = React.useState();
    const [passwordMatch, setPasswordMatch] = React.useState(false);
    const {token} = useParams();
    const formSubmit = (e) => {
        e.preventDefault();
        const headers = new Headers();
        headers.append("Accept", "application/json");
        let formdata = new FormData();
        formdata.append("token", token);
        formdata.append("email", email);
        formdata.append("password", newPassword);
        formdata.append("password_confirmation", conPassword);
        let requestOptions = {
            method: 'POST',
            headers: headers,
            body: formdata,
            redirect: 'follow'
        };
        fetch(apiURI + "reset-password", requestOptions)
            .then(response => response.json())
            .then(result => {
                setEmail("");
                setNewPassword("");
                setConPassword("");
                toast.success(result.message, alertOptions)
                setTimeout(() => {
                    window.location.href = '/login'
                }, 3000)
            })
            .catch(error => console.log('error', error));
    }
    React.useEffect(() => {
        if (localStorage.getItem('token') !== null) {
            window.location.href = '/'
        }
    })
    if (token) {
        return (
            <div className="login d-flex justify-content-center align-items-center">
                <div className="col-md-6 col-10 col-lg-4 col-xxl-3">
                    <form onSubmit={(e) => formSubmit(e)} className="content rounded">
                        <h2 className="text-center mb-3">Reset Password</h2>
                        <hr/>
                        <div className="mx-5 my-4">
                            <TextField className="mt-3 w-100" id="outlined-basic" label="Email Address" required
                                       onChange={(e) => setEmail(e.target.value)}
                                       variant="outlined" value={email}/>
                            <TextField className="mt-3 w-100" required id="outlined-basic" label="New Password"
                                       onChange={(e) => {
                                           setNewPassword(e.target.value)
                                       }}
                                       value={newPassword}
                                       variant="outlined"/>
                            <TextField className="mt-2 w-100" required id="outlined-basic"
                                       label="Confirm Password"
                                       onChange={(e) => {
                                           setConPassword(e.target.value);
                                       }}
                                       onKeyUp={(e) => {
                                           if (conPassword !== newPassword) {
                                               setPasswordMatch(true);
                                           } else {
                                               setPasswordMatch(false)
                                           }
                                       }}
                                       variant="outlined" value={conPassword}/>
                            <p style={{fontSize: "11px", fontWeight: "bold"}}
                               className="text-danger mb-3 mt-1 text-center">{passwordMatch !== false && "Password doesn't match"}</p>
                            <Button color="primary" type="submit" className="w-100 mt-3"
                                    variant={"contained"}>Submit</Button>
                        </div>
                    </form>
                </div>
            </div>
        );
    } else {
        return (
            <div style={{paddingTop: "5rem"}}>
                Nothing to show
            </div>
        )
    }
};

export default ResetPassword;
