import React, {useState} from 'react';
import Ppr from "./items/Ppr";
import Annual from "./items/Annual";

const Pricing = () => {
    const [option, setOption] = useState(1);
    return (
        <>
            <div className="min-h-screen pricing" style={{paddingTop: "6rem"}}>
                <div className="container">
                    <div className=" bg-white pt-4 mt-2">
                        <h6 className="text-center font-medium text-gray-700 text-center text-xl">Employer Pricing Plans</h6>
                        <div className="ap-buttons text-center my-4">
                            <button onClick={() => setOption(1)} className={`btn1 ${option === 1 ? 'active' : ''}`}>Annual
                            </button>
                            <button onClick={() => setOption(2)}
                                    className={`btn1 ${option === 2 ? 'active' : ''}`}>Pay-per-job
                            </button>
                        </div>
                        <div className="row">
                            <div className="col-md-8 row m-auto">
                                {option === 1 && <Annual/>}
                                {option === 2 && <Ppr/>}
                            </div>
                        </div>
                        <div className="row pricing-footer">
                            <div className="col-12 text-center text-sm color-p mt-5 mb-3">
                                <p>
                                    To post a job, just sign up here as an employer and subscribe for the suitable
                                    package. <br/>
                                    Use an official company email address for signing up the employer account as this cannot
                                    be changed later.

                                </p>
                                <p className="mt-2">
                                    For assistance reach us on +960 7379494 or mail@jobsicle.mv
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Pricing;