import {Skeleton} from '@mui/material';
import bgImg from "./assets/images/app-bg.jpg";
import icon from "./assets/images/logo-icon.png";
import logo from "./assets/images/logo.png";
import moment from "moment";

export const mainURI = "https://jobsicle.org";
// export const mainURI = "http://localhost:8000";
export const rootURI = "https://jobsicle.mv";
// export const rootURI = "http://localhost:3000";
export const thumbnailsURI = "https://jobsicle.org/images/jobseekers/";
export const apiURI = mainURI + "/api/";
export const websiteData = {
    header_logo: logo,
    favicon: icon,
    cover: bgImg,
}

export const alertOptions = {
    position: "top-center",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
}
export const loader = (height, width = '100%') => {
    return (
        <div style={{width: width, height: height}}>
            <Skeleton style={{height: '30%'}}/>
            <Skeleton variant="rectangular" animation="wave"
                      className=""
                      style={{maxWidth: 'none', height: '20%'}}/>
            <Skeleton style={{height: '30%'}} animation={false}/>
        </div>
    )
}
export const defaultSkills = [
    {
        "id": 1,
        "name": "Communication",
        "level": 1
    },
    {
        "id": 2,
        "name": "Problem-solving",
        "level": 1
    },
    {
        "id": 3,
        "name": "Time management",
        "level": 1
    },
    {
        "id": 4,
        "name": "Ability to work under pressure",
        "level": 1
    },
    {
        "id": 5,
        "name": "Computer Skills",
        "level": 1
    }
]
export const countDays = (start) => {
    let startD = moment(start, "YYYY-MM-DD");
    let current = moment().startOf('day');
    return moment.duration(startD.diff(current)).asDays();
}
export const filtered = (name, fields) => fields.filter(function (el) {
    if (el.field == name) {
        return el;
    }
});
export const sortedFields = (name, fields) => {
    return filtered(name, fields).sort((a, b) => (a.value > b.value) ? 1 : -1);
}