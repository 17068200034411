import React from 'react';
import {WorkOutlineOutlined} from "@mui/icons-material";
import {RequestG} from "../routes/Request";
import {loader} from "../config";
import {RequestGet, RequestM} from "../employer/Request";
import AddEducation from "./modal/AddEducation";
import {IconButton} from "@mui/material";
import {AiFillDelete} from "react-icons/ai";
import EditEdu from "./modal/EditEdu";
import ProfileStatus from "./alerts/ProfileStatus";

const Education = () => {
    const [fields, setFields] = React.useState(false);
    const [resume, setResume] = React.useState(null);
    const [qualification, setQualification] = React.useState(null);
    const [profile, setProfile] = React.useState(null);
    React.useEffect(() => {
        if (fields === false) {
            RequestGet('user/profile').then((response) => {
                if (response.preference == null) {
                    window.location.href = '/pref'
                }
                if (response.profile == null) {
                    window.location.href = '/step'
                }
                setProfile(response)
            })
            RequestGet("user/resumes-usr").then((response) => {
                setResume(response);
            })
            RequestGet('user/qualification-usr').then((response) => {
                setQualification(response)
            })
            RequestG('fields').then((response) => {
                setFields(response)
            })
        }
    }, [fields])
    const year = new Date().getFullYear();
    let list = []
    for (let i = 2000; i <= year; i++) {
        list.push(i)
    }
    const deleteData = (id) => {
        RequestM('job-seeker/qualification/' + id, new FormData(), 'delete').then((response) => {
            setFields(false)
        })
    }
    if (fields !== false && profile !== null) {
        return (
            <>
                <ProfileStatus profile={profile}/>
                <div className="content p-2">
                    <div className="d-flex py-2 px-2">
                        <WorkOutlineOutlined className="me-2 text-two"/>
                        <h4 className="heading text-main my-0">Add Education</h4>
                    </div>
                    <div className="text-right">
                        {resume !== null && <AddEducation resume={resume} fields={fields} setFields={setFields}/>}
                    </div>
                    <div className="px-2 py-2">
                        {qualification !== null && qualification.map((item) =>
                            <>
                                <div className='grid grid-cols-1 lg:grid-cols-4 pt-2'>
                                    <div>
                                        <p className='text-gray-500 my-0'>{item.year}</p>
                                    </div>
                                    <div className='lg:col-span-2'>
                                        <p className='font-semibold my-1 text-gray-700'>{item.course_name}</p>
                                        <p className='italic my-1 text-gray-500'>{item.institute}</p>
                                        <div className='pt-2 flex items-center gap-1'>
                                            <p className='font-semibold my-1 text-gray-700'>Level :</p>
                                            <p className='my-1 italic text-gray-500'>{item.level}</p>
                                        </div>

                                    </div>
                                    <div className='lg:col-span-1'>
                                        {resume !== null ? <EditEdu
                                            resume={resume.data} setFields={setFields} fields={fields} item={item}
                                            setQualification={setQualification}/> : ""}
                                        <IconButton aria-label="delete" onClick={() => deleteData(item.id)}
                                                    size="small"><AiFillDelete/>
                                        </IconButton>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </>
        );
    } else {
        return loader("20rem")
    }
};

export default Education;
