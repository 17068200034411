import React from 'react';
import Employer from "./routes/Employer";
import Public from "./routes/Public";
import Login from "./auth/Login";
import {BrowserRouter, Switch, Route} from "react-router-dom";
import {ToastContainer} from "react-toastify";
import Register from "./auth/Register";
import MyCompany from "./employer/MyCompany";
import Subscriptions from "./employer/Subscriptions";
import Jobs from "./employer/Jobs";
import JobApplications from "./employer/JobApplications";
import CreateJob from "./employer/CreateJob";
import 'suneditor/dist/css/suneditor.min.css';
import "react-toastify/dist/ReactToastify.min.css";
import ViewApplications from "./employer/ViewApplications";
import Private from "./routes/Private";
import TalentPool from "./employer/TalentPool";
import ForgetPassword from "./auth/ForgetPassword";
import Index from "./root/Index";
import Employers from "./root/Employers";
import JobView from "./root/JobView";
import Talent from "./root/Talent";
import ViewCompany from "./root/ViewCompany";
import Page7 from "./root/Page7";
import Verify from "./auth/Verify";
import Profile from "./seeker/Profile";
import ResetPassword from "./auth/ResetPassword";
import About from "./root/About";
import Download from "./root/Download";
import Step from "./seeker/Step";
import BasicInfo from "./seeker/BasicInfo";
import Introduction from "./seeker/Introduction";
import Experience from "./seeker/Experience";
import Education from "./seeker/Education";
import Skills from "./seeker/Skills";
import Languages from "./seeker/Languages";
import Resumes from "./seeker/Resumes";
import Setting from "./seeker/Setting";
import Applications from "./seeker/Applications";
import UJobs from "./seeker/UJobs";
import Preference from "./seeker/Preference";
import ApplyJob from "./seeker/ApplyJob";
import ViewApplication from "./employer/ViewApplication";
import UserView from "./employer/UserView";
import EditJob from "./employer/EditJob";
import Repost from "./employer/Repost";
import Pref from "./seeker/Pref";
import ProfileView from "./root/ProfileView";
import Documents from "./seeker/Documents";
import Pricing from "./employer/Pricing";
import Terms from "./root/Terms";
import JobForm from "./root/JobForm";

function App() {
    const logout = () => {
        localStorage.clear();
        window.location.href = '/login';
    }
    return (
        <BrowserRouter>
            <ToastContainer
                position="top-center"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <Switch>
                <Public exact path="/" component={Index}/>
                <Public exact path="/tos" component={Terms}/>
                <Public exact path="/job/:jobId" component={JobView}/>
                <Route exact path="/verify-email/:userId/:token" component={Verify}/>
                <Public exact path="/register" component={Register}/>
                <Public exact path="/login" component={Login}/>
                <Public exact path="/forget-password" component={ForgetPassword}/>
                <Public exact path="/reset-password/:token" component={ResetPassword}/>
                <Public exact path="/employers" component={Employers}/>
                <Public exact path="/talent-pool" component={Talent}/>
                <Public exact path="/about" component={About}/>
                <Public exact path="/download" component={Download}/>
                <Public exact path="/viewCompany/:id" component={ViewCompany}/>
                <Public exact path="/profile-view/:id" component={ProfileView}/>
                <Public exact path={"/pricing"} component={Pricing}/>
                <Public exact path={"/job-form"} component={JobForm}/>
                <Employer exact sidebar={true} path={"/my-company"} component={MyCompany}/>
                <Employer exact sidebar={true} path={"/subscriptions"} component={Subscriptions}/>
                <Employer exact sidebar={true} path={"/jobs"} component={Jobs}/>
                <Employer exact sidebar={true} path={"/job-applications"} component={JobApplications}/>
                <Employer exact sidebar={true} path={"/job-applications/:jobId"} component={JobApplications}/>
                <Employer exact sidebar={false} path={"/view-application/:applyId"} component={ViewApplication}/>
                <Employer exact sidebar={true} path={"/post-job"} component={CreateJob}/>
                <Employer exact sidebar={false} path={"/view-profile/:id"} component={UserView}/>
                <Employer exact sidebar={false} path={"/edit-job/:id"} component={EditJob}/>
                <Employer exact sidebar={false} path={"/repost-job/:id"} component={Repost}/>
                <Private exact sidebar={false} path={"/step"} component={Step}/>
                <Private exact sidebar={false} path={"/pref"} component={Pref}/>
                <Private exact sidebar={true} path={"/view-application"} component={ViewApplications}/>
                <Private exact sidebar={true} path={"/employ/talent-pool"} component={TalentPool}/>
                <Private exact sidebar={true} path={"/profile"} component={Profile}/>
                <Private exact sidebar={true} path={"/basic-info"} component={BasicInfo}/>
                <Private exact sidebar={true} path={"/introduction"} component={Introduction}/>
                <Private exact sidebar={true} path={"/work-experience"} component={Experience}/>
                <Private exact sidebar={true} path={"/educations"} component={Education}/>
                <Private exact sidebar={true} path={"/skills"} component={Skills}/>
                <Private exact sidebar={true} path={"/languages"} component={Languages}/>
                <Private exact sidebar={true} path={"/resumes"} component={Resumes}/>
                <Private exact sidebar={true} path={"/setting"} component={Setting}/>
                <Private exact sidebar={true} path={"/my-applications"} component={Applications}/>
                <Private exact sidebar={true} path={"/my-jobs"} component={UJobs}/>
                <Private exact sidebar={true} path={"/my-preference"} component={Preference}/>
                <Private exact sidebar={true} path={"/documents"} component={Documents}/>
                <Public exact path={"/apply-job/:jobId"} component={ApplyJob}/>
                <Route exact path={'/logout'} component={logout}/>
            </Switch>
        </BrowserRouter>
    );
}

export default App;
