import React from 'react';
import {WorkOutlineOutlined} from "@mui/icons-material";
import {RequestG} from "../routes/Request";
import {loader} from "../config";
import {RequestGet, RequestM} from "../employer/Request";
import AddLanguage from "./modal/AddLanguage";
import {IconButton} from "@mui/material";
import {AiFillDelete} from "react-icons/ai";
import EditLang from "./modal/EditLang";
import ProfileStatus from "./alerts/ProfileStatus";

const Languages = () => {
    const [fields, setFields] = React.useState(false);
    const [data, setData] = React.useState(null);
    const [profile, setProfile] = React.useState(null);
    React.useEffect(() => {
        if (fields === false) {
            RequestGet('user/languages-usr').then((response) => {
                setData(response);
            })
            RequestGet('user/profile').then((response) => {
                if (response.preference == null) {
                    window.location.href = '/pref'
                }
                if (response.profile == null) {
                    window.location.href = '/step'
                }
                setProfile(response);
            })
            RequestG('fields').then((response) => {
                setFields(response)
            })
        }
    }, [fields])
    const year = new Date().getFullYear();
    let list = []
    for (let i = 2000; i <= year; i++) {
        list.push(i)
    }
    const deleteData = (id) => {
        RequestM('job-seeker/languages/' + id, new FormData(), 'delete').then((response) => {
            setFields(false)
        })
    }
    if (fields !== false && profile !== null) {
        return (
            <>
                <ProfileStatus profile={profile}/>
                <div className="content p-2">
                    <div className="d-flex py-2 px-2">
                        <WorkOutlineOutlined className="me-2 text-two"/>
                        <h4 className="heading text-main my-0">Languages</h4>
                    </div>
                    <div className="text-right">
                        <AddLanguage profile={profile.profile}
                                     setFields={setFields}/>
                    </div>
                    <div className="px-2 py-2">
                        <div className='grid grid-cols-1 lg:grid-cols-4'>
                            <div className='lg:col-span-3'>
                                {profile !== null && data !== null && data.map((item) =>
                                    <>
                                        <p className='font-semibold pt-2 mb-1 my-0 text-gray-700 mt-4'>{item.language}</p>
                                        <div className='flex items-center gap-1'>
                                            <p className='my-0 font-semibold text-gray-700'>Fluency Level :</p>
                                            <p className='mb-1 mt-1 italic text-gray-500'>{item.level}</p>
                                            <div className="ms-3">
                                                <EditLang setData={setData} item={item} setFields={setFields}
                                                          profile={profile.profile}/>
                                                <IconButton aria-label="delete" onClick={() => deleteData(item.id)}
                                                            size="small"><AiFillDelete/>
                                                </IconButton>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    } else {
        return loader("20rem")
    }
};

export default Languages;
