import React from 'react';
import {Button, TextField, Slider} from "@mui/material";
import {Modal} from "react-bootstrap";
import {RequestM} from "../../employer/Request";
import {toast} from "react-toastify";
import {alertOptions, defaultSkills} from "../../config";
import './design.scss';

const AddSkills = ({profile, setFields, data}) => {
    const [name, setName] = React.useState("");
    const [level, setLevel] = React.useState(0);
    const [modal, setModal] = React.useState(false);
    const formSubmit = (e) => {
        e.preventDefault();
        var formdata = new FormData();
        formdata.append("job_seeker_profile_id", profile.id);
        formdata.append("title", name);
        formdata.append("level", level);
        RequestM('job-seeker/skills', formdata).then((response) => {
            toast.success(response.message, alertOptions)
            setModal(false)
            setName("");
            setLevel(0);
            setFields(false)
        })
    }
    const year = new Date().getFullYear();
    let list = []
    for (let i = 2000; i <= year; i++) {
        list.push(i)
    }
    let levelarray = []
    for (let i = 1; i <= 5; i++) {
        levelarray.push(i)
    }
    const [defaultSkillSt, setDefaultSkillSt] = React.useState(defaultSkills);
    const addSkill = (e) => {
        e.preventDefault()
        const dsLength = defaultSkillSt.length;
        for (let i = 0; i < dsLength; i++) {
            let name = defaultSkillSt[i].name;
            let level = defaultSkillSt[i].level;
            let formdata = new FormData();
            formdata.append("job_seeker_profile_id", profile.id);
            formdata.append("title", name);
            formdata.append("level", level);
            RequestM('job-seeker/skills', formdata).then((response) => {
                console.log(response)
            })
        }
        setModal(false)
        setName("");
        setLevel(0);
        setFields(false)
    }
    return (
        <>
            <Button onClick={() => setModal(true)} color="success" type="button" className="text-capitalize"
                    variant={"contained"}>+ Add Another</Button>
            <Modal
                size="lg"
                show={modal} centered
                onHide={() => setModal(false)}
                aria-labelledby="example-modal-sizes-title-lg">
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Add Skills
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="px-2 py-2">
                        {data.length > 0 ?
                            <form onSubmit={(e) => formSubmit(e)}>
                                <div className="row">
                                    <div className="col-lg-6">
                                        <TextField required className="mt-3 w-100" id="outlined-basic" label="Title"
                                                   variant="outlined" value={name}
                                                   onChange={(e) => setName(e.target.value)}/>
                                    </div>
                                    <div className="col-lg-6">
                                        <Slider
                                            className="mt-4"
                                            aria-label="Level"
                                            defaultValue={level}
                                            onChange={(e, val) => setLevel(val)}
                                            valueLabelDisplay="auto"
                                            step={1}
                                            marks
                                            min={1}
                                            max={5}
                                        />
                                    </div>
                                </div>
                                <Button color="info" type="submit" className="text-capitalize mt-3"
                                        variant={"contained"}>Save Skills</Button>
                            </form>
                            :
                            <form onSubmit={(e) => addSkill(e)}>
                                {defaultSkillSt.map((item, index) =>
                                    <div className="row my-2">
                                        <div className="col-lg-6">
                                            {item.name}
                                        </div>
                                        <div className="col-lg-6">
                                            <Slider
                                                aria-label="Level"
                                                defaultValue={item.level}
                                                onChange={(e, val) => {
                                                    defaultSkillSt[index].level = val;
                                                }}
                                                valueLabelDisplay="auto"
                                                step={1}
                                                marks
                                                min={1}
                                                max={5}
                                            />
                                        </div>
                                    </div>
                                )}
                                <Button color="info" type="submit" className="text-capitalize mt-3"
                                        variant={"contained"}>Save Skills</Button>
                            </form>
                        }
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default AddSkills;
