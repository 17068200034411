import React from 'react';
import icon from "../../assets/images/003m.png";

const NoJobs = () => {
    return (
        <>

            <div className="text-center py-5">
                <div className="icon w-25 m-auto mb-4"><img src={icon} className="img-fluid" alt=""/></div>
                <h3 className="text-two">Ooopsie!</h3>
                <p className="mb-0">It seems you have not applied to a job yet</p>
            </div>
        </>
    );
};

export default NoJobs;
