import React from 'react';
import Moment from "react-moment";
import CompanyProgress from "../items/CompanyProgress";

const YaSubscription = ({data}) => {
    return (
        <>
            {data.annual !== null &&
            <div className="content p-3 mb-2">
                <h4 className="text-main heading mb-0">Yay! You have an active subscription</h4>
                <p className="text-two mt-2 mb-0">{data.annual.package} Package - Expires <Moment
                    format="DD MMM YYYY">{data.annual.expiry_date}</Moment></p>
            </div>
            }
            {data.payper !== null &&
            <div className="content p-3 mb-2">
                <h4 className="text-main heading mb-0">Yay! You have an active subscription</h4>
                <p className="text-two mt-2 mb-0">{data.payper.package} Package - Job
                    Left {data.payper.jobs_left}</p>
            </div>
            }
        </>
    );
};

export default YaSubscription;
