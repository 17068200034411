import React from 'react';
import {Button, TextField, Slider, IconButton} from "@mui/material";
import {Modal} from "react-bootstrap";
import {RequestM} from "../../employer/Request";
import {toast} from "react-toastify";
import {alertOptions, defaultSkills} from "../../config";
import './design.scss';
import {BsPencilSquare} from "react-icons/bs";

const EditSkill = ({item, setFields, profile, data, setData}) => {
    const [name, setName] = React.useState(item.title);
    const [level, setLevel] = React.useState(item.level);
    const [modal, setModal] = React.useState(false);
    const formSubmit = (e) => {
        e.preventDefault();
        var formdata = {
            "id": item.id,
            "job_seeker_profile_id": profile.id,
            "title": name,
            "level": level,
        };
        RequestM('job-seeker/skills/' + item.id, formdata, 'put').then((response) => {
            toast.success(response.message, alertOptions)
            setName("");
            setLevel(0);
            setData(false)
            setFields(false)
            setModal(false)
        })
    }
    const year = new Date().getFullYear();
    let list = []
    for (let i = 2000; i <= year; i++) {
        list.push(i)
    }
    let levelarray = []
    for (let i = 1; i <= 5; i++) {
        levelarray.push(i)
    }
    return (
        <>
            <IconButton aria-label="edit" onClick={() => setModal(true)} color="primary"
                        size="small"><BsPencilSquare/>
            </IconButton>
            <Modal
                size="lg"
                show={modal} centered
                onHide={() => setModal(false)}
                aria-labelledby="example-modal-sizes-title-lg">
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Edit Skills
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="px-2 py-2">
                        <form onSubmit={(e) => formSubmit(e)}>
                            <div className="row">
                                <div className="col-lg-6">
                                    <TextField required className="mt-3 w-100" id="outlined-basic" label="Title"
                                               variant="outlined" value={name}
                                               onChange={(e) => setName(e.target.value)}/>
                                </div>
                                <div className="col-lg-6">
                                    <Slider
                                        className="mt-4"
                                        aria-label="Level"
                                        defaultValue={level}
                                        onChange={(e, val) => setLevel(val)}
                                        valueLabelDisplay="auto"
                                        step={1}
                                        marks
                                        min={1}
                                        max={5}
                                    />
                                </div>
                            </div>
                            <Button color="info" type="submit" className="text-capitalize mt-3"
                                    variant={"contained"}>Save Skills</Button>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default EditSkill;
