import React from 'react';
import Header from "../layouts/Header";
import {ToastContainer} from "react-toastify";
import {Route} from "react-router-dom";
import Footer from "../layouts/Footer";
import {RequestGet} from "../employer/Request";


const Public = ({children, ...rest}) => {
    const [data, setData] = React.useState(false);
    React.useEffect(() => {
        if (data === false && localStorage.getItem('token')) {
            RequestGet('profile').then((response) => {
                setData({
                    'user': response,
                    'token': localStorage.getItem('token')
                })
            })
        }
    })
    if (data === false) {
        return (
            <>
                <Header authentication={false}/>
                <ToastContainer/>
                <div className="main-content pb-3">
                    {<Route {...rest} render={() => (children)}/>}
                </div>
                <Footer/>
            </>
        );
    } else {
        return (
            <>
                <Header authentication={data.user}/>
                <ToastContainer/>
                <div className="main-content pb-3">
                    {<Route {...rest} render={() => (children)}/>}
                </div>
                <Footer/>
            </>
        );
    }
};

export default Public;
