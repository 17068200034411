import axios from "axios";
import {apiURI} from "../config";


const headers = {
    'Accept': 'application/json'
};
export const RequestG = async (page) => {
    return await axios({
        headers,
        url: apiURI + page,
        method: "get"
    }).then(function (response) {
        return response.data;
    }).catch(function (error) {
        console.log(error);
    });
}