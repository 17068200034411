import React from 'react';
import {useParams} from "react-router-dom";
import {ImProfile} from "react-icons/im";
import {BiCloudDownload} from "react-icons/bi";
import ProfileItem from "../employer/items/ProfileItem";
import {loader} from "../config";
import {Container} from "react-bootstrap";
import {RequestG} from "../routes/Request";

const ProfileView = () => {
    const {id} = useParams();
    const [data, setData] = React.useState(null);
    React.useEffect(() => {
        RequestG('user-profile/' + id).then((response) => {
            if (response.status === 200) {
                setData(response.data)
            }
        })
    }, [id])
    if (data !== null) {
        return (
            <>
                <div className="company">
                    <Container className="py-5">
                        <div className="col-lg-12 pt-5 mx-auto">
                            <div className="row">
                                <div className="col-lg-3 px-0">
                                    <div className="card rounded-0 border-0 card-body">
                                        <p className="my-2 text-sm text-gray-450 d-flex text-hover-main cursor-pointer">
                                            <ImProfile
                                                className="mr-3 w-5 h-5"/>Candidate Profile
                                        </p>
                                        {data.profile.resume !== null &&
                                        <a href={data.profile.resume} target="_blank" rel="noreferrer"
                                           className="my-2 text-sm text-gray-450 d-flex text-hover-main cursor-pointer"><BiCloudDownload
                                            className="mr-3 w-5 h-5"/>
                                            Download Attached Resume
                                        </a>}
                                        {data.documents.length > 0 && data.documents.map((item, index) =>
                                            <a href={item.file_url} target="_blank" rel="noreferrer"
                                               className="my-2 text-sm text-gray-450 d-flex text-hover-main cursor-pointer"><BiCloudDownload
                                                className="mr-3 w-5 h-5"/>
                                                {item.type === 'cert' ? "Download Certificate" : ""}
                                                {item.type === 'other' ? "Download Ref-letters" : ""}
                                            </a>)}
                                    </div>
                                </div>
                                <div className="col-lg-9 px-0 px-lg-2">
                                    <ProfileItem data={data}/>
                                </div>
                            </div>
                        </div>
                    </Container>
                </div>
            </>
        );
    } else {
        return <>
            <div className="company">
                <Container className="py-5">
                    <div className="col-lg-12 pt-5 mx-auto">
                        <div className="row">
                            {loader("12rem")}
                        </div>
                    </div>
                </Container>
            </div>
        </>
    }
};

export default ProfileView;
