import React from 'react';
import {HiCheckCircle} from 'react-icons/hi';

const Ppr = () => {
    return (
        <>
            <div className="col-md-6">
                <div className="card card-height-2 my-2 overflow-hidden price-content">
                    <div className="card-body">
                        <h4>Private Sector</h4>
                        <p className="color-2 my-3 height-p-2">
                            Pay as you go. Valid for 1 job ad.
                        </p>
                        <h1 className="mb-0">MVR 500</h1>
                        <p className="color-2 my-0">/ job ad</p>
                    </div>
                    <hr/>
                    <div className="card-body rounded-0">
                        <h4 className="color-2 size-2">WHAT’S INCLUDED</h4>
                        <div
                            className="checklist my-3 text-sm d-flex justify-content-start align-items-center">
                                            <span className="icon text-pr">
                                                <HiCheckCircle/>
                                            </span>
                            <p className="my-0 ms-2">Receive & manage online
                                applications</p>
                        </div>
                        <div
                            className="checklist my-3 text-sm d-flex justify-content-start align-items-center">
                                            <span className="icon text-pr">
                                                <HiCheckCircle/>
                                            </span>
                            <p className="my-0 ms-2">Attach application form and
                                job pdf</p>
                        </div>
                        <div className="checklist my-3 text-sm d-flex justify-content-start">
                                            <span className="icon mt-1 text-pr">
                                                <HiCheckCircle/>
                                            </span>
                            <p className="ms-2 my-0">Limit applications from Maldivians
                                candidates or Limit foreign
                                candidates</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-md-6">
                <div className="card card-height-2 my-2 overflow-hidden price-content">
                    <div className="card-body rounded-0">
                        <h4>Tourism, Resort Sector</h4>
                        <p className="color-2 my-3 height-p-2">Pay as you go. Valid for 1 job ad.
                        </p>
                        <h1 className="mb-0">MVR 1000</h1>
                        <p className="color-2 my-0">/ job ad</p>
                    </div>
                    <hr/>
                    <div className="card-body rounded-0">
                        <h4 className="color-2 size-2">WHAT’S INCLUDED</h4>
                        <div
                            className="checklist my-3 text-sm d-flex justify-content-start align-items-center">
                                            <span className="icon text-pr">
                                                <HiCheckCircle/>
                                            </span>
                            <p className="my-0 ms-2">Receive & manage online
                                applications</p>
                        </div>
                        <div
                            className="checklist my-3 text-sm d-flex justify-content-start align-items-center">
                                            <span className="icon text-pr">
                                                <HiCheckCircle/>
                                            </span>
                            <p className="my-0 ms-2">Attach application form and
                                job pdf</p>
                        </div>
                        <div className="checklist my-3 text-sm d-flex justify-content-start">
                                            <span className="icon mt-1 text-pr">
                                                <HiCheckCircle/>
                                            </span>
                            <p className="ms-2 my-0">Limit applications from Maldivians
                                candidates or Limit foreign
                                candidates</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Ppr;