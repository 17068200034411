import React from 'react';
import {Link} from "react-router-dom";

const NoSubscription = () => {
    return (
        <>
            <div className="content mb-2 p-3">
                <div className="row align-items-center">
                    <div className="col-md-9">
                        <h4 className="text-main heading mb-0">You have no active subscriptions</h4>
                        <p className="text-two mt-2 mb-0">Get subscribed and start posting job offers in no time.</p>
                    </div>
                    <div className="col-md-3 text-right">
                        <Link to="/subscriptions" className={"btn btn-primary text-light"}>Get Subscribed</Link>
                    </div>
                </div>
            </div>
        </>
    );
};

export default NoSubscription;
