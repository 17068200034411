import React from 'react';
import {WorkOutlineOutlined} from "@mui/icons-material";
import {Button, LinearProgress} from "@mui/material";
import {RequestG} from "../routes/Request";
import {alertOptions, loader} from "../config";
import icon from "../assets/images/001m.png";
import {RequestGet, RequestM} from "../employer/Request";
import {toast} from "react-toastify";
import ResumeView from "./modal/ResumeView";
import ProfileStatus from "./alerts/ProfileStatus";
import {AiOutlineInfoCircle} from "react-icons/ai";

const Resumes = () => {
    const [resumePdf, setResumePdf] = React.useState("");
    const [resumeOld, setResumeOld] = React.useState(null);
    const [data, setData] = React.useState(null);
    const [profile, setProfile] = React.useState(null);
    const [loading, setLoading] = React.useState(false);

    const [fields, setFields] = React.useState(false);
    React.useEffect(() => {
        if (fields === false) {
            RequestG('fields').then((response) => {
                setFields(response)
            })
        }
    }, [fields])

    const [imgPreview, setImgPreview] = React.useState("");
    const onImageChange = (event) => {
        setLoading(true);
        if (event.target.files && event.target.files[0]) {
            setImgPreview(event.target.files[0].name)
            setResumePdf(event.target.files[0])
            uploadResume(event.target.files[0])
        }
    }
    React.useEffect(() => {
        if (data === null) {
            RequestGet("user/resumes-usr").then((response) => {
                if (response.status === 200) {
                    setResumeOld(response.data.resume)
                }
                setData(response);
            })
            RequestGet('user/profile').then((response) => {
                if (response.preference == null) {
                    window.location.href = '/pref'
                }
                if (response.profile == null) {
                    window.location.href = '/step'
                }
                setProfile(response);
            })
        }
    }, [data])
    const uploadResume = (file) => {
        if (file !== "") {
            var formdata = new FormData();
            formdata.append("job_seeker_profile_id", profile.profile.id);
            formdata.append("resume", file);
            if (parseInt(data.status) !== 200) {
                RequestM('job-seeker/resumes', formdata).then((response) => {
                    toast.success(response.message, alertOptions)
                    setTimeout(() => window.location.href = '/resumes'
                        , 2000)
                    setLoading(false);
                });
            } else {
                RequestM('job-seeker/resume-update/' + data.data.id, formdata).then((response) => {
                    toast.success(response.message, alertOptions)
                    console.log("Test 2")
                    setTimeout(() => window.location.href = '/resumes'
                        , 2000)
                    setLoading(false);
                });
            }
        }
    }
    const formSubmit = (e) => {
        e.preventDefault();
        var formdata = new FormData();
        formdata.append("job_seeker_profile_id", profile.profile.id);
        formdata.append("resume", resumePdf);
        if (data.status !== 200) {
            RequestM('job-seeker/resumes', formdata).then((response) => {
                toast.success(response.message, alertOptions)
                setTimeout(() => window.location.href = '/resumes'
                    , 2000)
            });
        } else {
            RequestM('job-seeker/resume-update/' + data.data.id, formdata).then((response) => {
                toast.success(response.message, alertOptions)
                setTimeout(() => window.location.href = '/resumes'
                    , 2000)
            });
        }
    }
    if (fields !== false && profile !== null) {
        return (
            <>
                <ProfileStatus profile={profile}/>
                <div className="content p-2">
                    <div className="d-flex py-2 px-2">
                        <WorkOutlineOutlined className="me-2 text-two"/>
                        <h4 className="heading text-main my-0">Upload Resume</h4>
                    </div>
                    <p className="mx-2 mb-3 mt-1">
                        By default your Jobsicle profile will be sent to employers during online job applications. But
                        you can add a fancy resume of your own.
                    </p>
                    <form onSubmit={(e) => formSubmit(e)} className="px-2 text-center py-2">
                        <img src={icon} className="img-fluid w-25 m-auto mb-3" alt=""/>
                        <p className="mx-2 mb-3 mt-1 text-left">
                            Allowed file type is PDF. Maximum file size should not exceed 10MB
                        </p>
                        <div className="border-1 row py-3 mx-0">
                            <div className="col-6">
                                <div className="w-100 mb-3">
                                    {loading && <LinearProgress color="primary"/>}
                                </div>
                                <h5 className="d-flex main-color">Resume <span
                                    className={`ms-2 ${resumeOld === "" ? "text-danger" : "color-pr"}`}><AiOutlineInfoCircle/></span>
                                </h5>
                                <p className="mt-2 text-left text-xxs mb-0 text-muted">
                                    Attach your own custom resume
                                </p>
                                {resumeOld !== "" && resumeOld !== undefined && resumeOld !== null ?
                                    <div
                                        className="my-2 text-xxs text-left">{resumeOld.split("/")[resumeOld.split("/").length - 1]}
                                        <span className="ms-3"><ResumeView url={resumeOld}/></span>
                                    </div> : ""}
                            </div>
                            <div className="col-6 text-right">
                                <label htmlFor="logo" className="btn mt-2 btn-sm btn-outline-primary">+ Add /
                                    Replace</label>
                            </div>
                        </div>
                        <input type="file" required className="d-none" onChange={(event) => onImageChange(event)}
                               id="logo"/>
                    </form>
                </div>
            </>
        );
    } else {
        return loader("20rem")
    }
};

export default Resumes;
