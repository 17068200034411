import React, {useEffect} from 'react';
import {DescriptionOutlined} from "@mui/icons-material";
import {Link, useParams} from "react-router-dom";
import {FormControl, InputLabel, MenuItem, Pagination, Select} from "@mui/material";
import VerifyIcon from "./warning/VerifyIcon";
import Email from "./warning/Email";
import {ComContext} from "../routes/Employer";
import {RequestGet} from "./Request";
import {alertOptions, loader} from "../config";
import Moment from "react-moment";
import {toast} from "react-toastify";
import NoApplications from "./warning/NoApplications";
import {RequestG} from "../routes/Request";

const JobApplications = () => {
    const [sort, setSort] = React.useState(3);
    const {user} = React.useContext(ComContext);
    const {jobId} = useParams();
    const [data, setData] = React.useState(false);
    const [update, setUpdate] = React.useState(0);
    const [profile, setProfile] = React.useState(false);
    const [page, setPage] = React.useState(1);
    const [nationality, setNationality] = React.useState("");
    const [qualification, setQualification] = React.useState("");
    const [experience, setExperience] = React.useState("");
    const [gender, setGender] = React.useState("");
    const [fields, setFields] = React.useState(false);
    const arShortList = (id) => {
        RequestGet('employ/ar-shortlist/' + id)
            .then((response) => {
                if (response.is_favorite == 1) {
                    toast.success("Added to shortlist", alertOptions);
                    setUpdate(1)
                } else {
                    toast.success("Removed from shortlist", alertOptions);
                    setUpdate(1)
                }
            })
    }
    useEffect(() => {
        RequestG('fields').then((response) => {
            setFields(response)
        })
        RequestGet('employ/company').then((response) => {
            setProfile(response.company)
        })
        console.log(gender)
        if (jobId !== undefined) {
            RequestGet('employ/job-applications/' + jobId + '?sort=' + sort + '&page=' + page + '&gender=' + gender + '&nationality=' + nationality + '&qualification=' + qualification + '&experience=' + experience).then((response) => {
                setData(response)
            })
        } else {
            RequestGet('employ/job-applications?sort=' + sort + '&page=' + page + '&gender=' + gender + '&nationality=' + nationality + '&qualification=' + qualification + '&experience=' + experience).then((response) => {
                setData(response)
            })
        }
        setUpdate(0)
    }, [sort, update, page, nationality, qualification, experience, gender])
    const pagination = (e) => {
        if (parseInt(data.last) > page) {
            setPage(data.last);
        } else {
            setPage(e.target.textContent)
        }
        window.scrollTo(0, 0);
    }
    if (data !== false && profile !== false && fields !== false) {
        return (
            <>
                <Email/>
                <div className="content p-2">
                    <div className="d-flex py-2">
                        <DescriptionOutlined className="me-2 text-two"/>
                        <h4 className="heading text-main my-0">Job Applications</h4>
                    </div>

                    {user.email_verified_at !== null ?
                        <>
                            <div className="text-right">
                                <div className="d-flex justify-content-end">
                                    <div className="form-group d-block">
                                        <label htmlFor="" className="text-xs w-100 ms-1 text-left">Gender</label>
                                        <select style={{width: '8rem', fontSize: '.9rem'}} name="" id=""
                                                value={gender} onChange={(e) => setGender(e.target.value)}
                                                className="form-select mx-1 py-1 px-2">
                                            <option value={""}>All</option>
                                            {fields.map((data) => {
                                                if (data.field === 'gender') {
                                                    return <option key={data.value}
                                                                   value={data.value}>{data.value}</option>;
                                                }
                                            })}
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="" className="text-xs w-100 ms-1 text-left">Nationality</label>
                                        <select style={{width: '8rem', fontSize: '.9rem'}} name="" id=""
                                                value={nationality} onChange={(e) => setNationality(e.target.value)}
                                                className="form-select mx-1 py-1 px-2">
                                            <option value={""}>All</option>
                                            {fields.map((data) => {
                                                if (data.field === 'nationality') {
                                                    return <option key={data.value}
                                                                   value={data.value}>{data.value}</option>;
                                                }
                                            })}
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="" className="text-xs w-100 ms-1 text-left">Qualification</label>
                                        <select style={{width: '8rem', fontSize: '.9rem'}} name="" id=""
                                                value={qualification} onChange={(e) => setQualification(e.target.value)}
                                                className="form-select mx-1 py-1 px-2">
                                            <option value={""}>All</option>
                                            {fields.map((data) => {
                                                if (data.field === 'qualifications') {
                                                    return <option key={data.value}
                                                                   value={data.value}>{data.value}</option>;
                                                }
                                            })}
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="" className="text-xs w-100 ms-1 text-left">Experience</label>
                                        <select style={{width: '8rem', fontSize: '.9rem'}} name="" id=""
                                                value={experience} onChange={(e) => setExperience(e.target.value)}
                                                className="form-select mx-1 py-1 px-2">
                                            <option value={""}>All</option>
                                            {fields.map((data) => {
                                                if (data.field === 'experience') {
                                                    return <option key={data.value}
                                                                   value={data.value}>{data.value}</option>;
                                                }
                                            })}
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="" className="text-xs w-100 ms-1 text-left">Status</label>
                                        <select style={{width: '8rem', fontSize: '.9rem'}} name=""
                                                id=""
                                                className="form-select mx-1 py-1 px-2" value={sort} onChange={(e) => {
                                            setData(false)
                                            setSort(e.target.value)
                                        }}>
                                            <option value={3}>All</option>
                                            <option value={0}>Applied</option>
                                            <option value={1}>Shortlisted</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="jobsContent my-2">
                                {data.data.length > 0 ? data.data.map((item) =>
                                    <>
                                        {parseInt(item.company_id) === parseInt(profile.id) &&
                                        <>
                                            <div className="bg-light content pt-3 pb-2 row mx-0 my-3">
                                                <div className="col-md-9">
                                                    <h4 className="mb-3 text-capitalize">{item.name !== null ? item.name : "User not found!"}</h4>
                                                    <span className="status me-4 text-two">{item.title}</span>
                                                    <a target="_blank" href={`/view-application/${item.id}`}
                                                       className="me-4 text-decoration-none text-main">View
                                                        Application</a>
                                                    <button onClick={(e) => arShortList(item.id)}
                                                            className="me-4 text-xs text-decoration-none text-main">
                                                        {item.is_favorite == 1 ? "Remove Shortlist" : "Short-list"}
                                                    </button>
                                                </div>
                                                <div className="col-md-3 rightContent text-md-right">
                                                    <p className="text-two">Applied on <Moment
                                                        format="DD MMM YYYY">{item.created_at}</Moment></p>
                                                    <p className="postDate text-two">
                                                        <span className="mr-2">Status: </span>
                                                        {item.is_favorite == 1 ? "Shortlisted" : "Applied"}
                                                    </p>
                                                </div>
                                            </div>
                                        </>
                                        }
                                    </>
                                ) : <NoApplications/>}
                                <div className="py-3 text-center">
                                    <Pagination
                                        count={Math.ceil(data.total / 20)}
                                        onClick={(e) => pagination(e)}
                                        hidePrevButton hideNextButton
                                        color="primary"
                                        className="d-flex justify-content-center"/>
                                </div>
                            </div>
                        </> : <VerifyIcon/>}
                </div>
            </>
        );
    } else {
        return loader("20rem")
    }
};

export default JobApplications;
