import React from 'react';
import {useParams} from 'react-router-dom';
import {loader} from "../config";
import {RequestGet} from "./Request";
import {RequestG} from "../routes/Request";
import EditJobItem from "./items/EditJobItem";

const EditJob = () => {
    const {id} = useParams();
    const [job, setJob] = React.useState(false);
    const [company, setCompany] = React.useState("");
    const [data, setData] = React.useState(false);
    const [fields, setFields] = React.useState(false);
    React.useEffect(() => {
        RequestGet('jobView/' + id).then((response) => {
            if (response.status === 200) {
                RequestGet('employ/company').then((response) => {
                    setCompany(response.company.name)
                    setData(response)
                })
                RequestG('fields').then((response) => {
                    setFields(response)
                })
                setJob(response.data)
            }
        })
    }, [id])
    if (job !== false && data !== false) {
        return (
            <>
                {parseInt(data.company.id) === parseInt(job.company_id)
                    ?
                    <>
                        <EditJobItem data={data} company={company} fields={fields} editJob={job} statusUpdate={true}
                                     subscription={data.annual !== null ? true : false}/>
                    </>
                    : <>Deny</>}
            </>
        );
    } else {
        return loader("20rem")
    }
};

export default EditJob;
