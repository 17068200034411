import React from 'react';
import {Modal} from "react-bootstrap";
import imgIco from "../assets/images/001m.png";
import {Link} from "react-router-dom";
import {FaRegBookmark, FaShareSquare} from "react-icons/fa";
import {alertOptions, rootURI} from "../config";
import {toast} from "react-toastify";

const Incomplete = ({id}) => {
    const [modal, setModal] = React.useState(false);
    const copyText = () => {
        navigator.clipboard.writeText(rootURI + "/job/" + id);
        toast.success("Link copied", alertOptions)
    }
    return (
        <>
            <button onClick={() => setModal(true)}
                    className="bg-pr text-white py-1 rounded-sm px-8">
                Apply Online
            </button>
            <Modal
                size="sm"
                show={modal}
                onHide={() => setModal(false)} centered
                backdrop="static" className="modalAO"
                keyboard={false}>
                <Modal.Body>
                    <img src={imgIco} className="img-fluid imgIconAO" alt=""/>
                    <h5 className="my-2 font-weight-600 text-center">Your profile is not complete</h5>
                    <p className="my-3 text-center text-sm">Fill in your profile to apply for jobs <br/> and join the
                        Talentpool
                    </p>
                    <div>
                        <button className="btnAO1">
                            <button onClick={() => window.location.href = '/profile'} className="p-0">Complete Profile</button>
                        </button>
                        <button className="btnAO2" onClick={() => setModal(false)}>Maybe later</button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default Incomplete;
