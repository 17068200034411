import React from 'react';
import "./design.scss";


const AttachmentViewer = ({url}) => {
    return (
        <div className="col-lg-12 jobPdfViewer">
            {url !== "" ?
                <iframe src={"https://viewer-js.jobsicle.org/index.html#viewer.php?url=" + url}
                        frameBorder="0" height="100%" width="100%"/> : ""}
        </div>
    );
};

export default AttachmentViewer;
