import React from 'react';
import {useParams} from 'react-router-dom';
import {RequestGet} from "./Request";
import {ImProfile} from "react-icons/im";
import {BiCloudDownload} from "react-icons/bi";
import ProfileItem from "./items/ProfileItem";
import {loader} from "../config";

const UserView = () => {
    const {id} = useParams();
    const [data, setData] = React.useState(null);
    const [profile, setProfile] = React.useState(false);
    React.useEffect(() => {
        RequestGet('employ/company').then((response) => {
            setProfile(response)
        })
        RequestGet('employ/user-profile/' + id).then((response) => {
            if (response.status === 200) {
                setData(response.data)
            }
        })
    }, [id])
    if (data !== null && profile !== false) {
        return (
            <>
                {
                    profile.annual !== null
                        ?
                        <>
                            <div className="col-lg-3 px-0">
                                <div className="card rounded-0 border-0 card-body">
                                    <p className="my-2 text-sm text-gray-450 d-flex text-hover-main cursor-pointer">
                                        <ImProfile
                                            className="mr-3 w-5 h-5"/>Candidate Profile
                                    </p>
                                    {data.profile.resume !== null &&
                                    <a href={data.profile.resume} target="_blank" rel="noreferrer"
                                       className="my-2 text-sm text-gray-450 d-flex text-hover-main cursor-pointer"><BiCloudDownload
                                        className="mr-3 w-5 h-5"/>
                                        Download Attached Resume
                                    </a>}
                                    {data.documents.length > 0 && data.documents.map((item, index) =>
                                        item.type === 'cert' || item.type === 'other' ?
                                            <a href={item.file_url} target="_blank" rel="noreferrer"
                                               className="my-2 text-sm text-gray-450 d-flex text-hover-main cursor-pointer"><BiCloudDownload
                                                className="mr-3 w-5 h-5"/>
                                                {item.type === 'cert' ? "Download Certificate" : ""}
                                                {item.type === 'other' ? "Download Ref-letters" : ""}
                                            </a> : ""
                                    )}
                                </div>
                            </div>
                            <div className="col-lg-9 px-0 px-lg-2">
                                <ProfileItem data={data}/>
                            </div>
                        </>
                        : <>
                            Data not found!
                        </>
                }
            </>
        );
    } else {
        return loader("12rem")
    }
};

export default UserView;
