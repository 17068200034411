import React from 'react';
import {Button, FormControl, IconButton, InputLabel, MenuItem, Select, TextField} from "@mui/material";
import {Modal} from "react-bootstrap";
import {RequestM} from "../../employer/Request";
import {toast} from "react-toastify";
import {alertOptions} from "../../config";
import {BsPencilSquare} from "react-icons/bs";

const EditEdu = ({item, fields, resume, setFields, setQualification}) => {
    const [level, setLevel] = React.useState(item.level);
    const [institute, setInstitute] = React.useState(item.institute);
    const [course, setCourse] = React.useState(item.course_name);
    const [yeare, setYeare] = React.useState(item.year);
    const [modal, setModal] = React.useState(false);
    const formSubmit = (e) => {
        e.preventDefault();
        var datareq = {
            "id": item.id,
            "resume_id": resume.id,
            "institute": institute,
            "year": yeare,
            "level": level,
            "course_name": course
        };

        RequestM('job-seeker/qualification/' + item.id, datareq, 'put').then((response) => {
            toast.success(response.message, alertOptions)
            setQualification(null);
            setLevel("");
            setInstitute("");
            setCourse("");
            setYeare("");
            setFields(false)
            setModal(false)
        })
    }
    const year = new Date().getFullYear();
    let list = []
    for (let i = 2000; i <= year; i++) {
        list.push(i)
    }
    return (
        <>
            <IconButton aria-label="edit" onClick={() => setModal(true)} color="primary"
                        size="small"><BsPencilSquare/>
            </IconButton>
            <Modal
                size="lg"
                show={modal} centered
                onHide={() => setModal(false)}
                aria-labelledby="example-modal-sizes-title-lg">
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Edit Education
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={(e) => formSubmit(e)} className="px-2 py-2">
                        <div className="row">
                            <div className="col-lg-6">
                                <FormControl required className="mt-3" fullWidth>
                                    <InputLabel id="demo-simple-select-label">Qualification Level</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={level} onChange={(e) => setLevel(e.target.value)}
                                        label="qualification Level">
                                        {fields.map((data) => {
                                            if (data.field === 'qualifications') {
                                                return <MenuItem key={data.value}
                                                                 value={data.value}>{data.value}</MenuItem>;
                                            }
                                        })}
                                    </Select>
                                </FormControl>
                                <TextField required className="mt-3 w-100" id="outlined-basic" label="Institute"
                                           variant="outlined" value={institute}
                                           onChange={(e) => setInstitute(e.target.value)}/>
                                <TextField required className="mt-3 w-100" id="outlined-basic" label="Course Name"
                                           variant="outlined" value={course}
                                           onChange={(e) => setCourse(e.target.value)}/>
                            </div>
                            <div className="col-lg-6">
                                <FormControl required className="mt-3" fullWidth>
                                    <InputLabel id="demo-simple-select-label">Year of Graduation</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={yeare} onChange={(e) => setYeare(e.target.value)}
                                        label="Year of Graduation">
                                        {list.map((item) =>
                                            <MenuItem key={item} value={item}>{item}</MenuItem>
                                        )}
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                        <Button color="info" type="submit" className="text-capitalize mt-3"
                                variant={"contained"}>Save Education</Button>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default EditEdu;
