import React from 'react';
import {Modal} from "react-bootstrap";
import AttachmentViewer from "../../modals/AttachmentViewer";

const ResumeView = ({url}) => {
    const [modal, setModal] = React.useState(false);
    return (
        <>
            <a onClick={() => setModal(true)} className="cursor-pointer">View</a>
            <Modal
                size="lg"
                show={modal}
                onHide={() => setModal(false)} centered>
                <Modal.Body>
                    <a onClick={() => setModal(false)} className="text-main mb-3 d-block cursor-pointer font-medium">Close</a>
                    <AttachmentViewer url={url}/>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default ResumeView;
