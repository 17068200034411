import React, {useState} from 'react';
import {Link, useParams, useHistory} from "react-router-dom"
import {RequestGet, RequestM} from "../employer/Request";
import {alertOptions, loader, mainURI, thumbnailsURI} from "../config";
import {Button} from "@mui/material";
import "./design.scss";
import {toast} from "react-toastify";
import ResumeView from "./modal/ResumeView";
import {AiOutlineCheckCircle} from 'react-icons/ai';
import {LinearProgress} from '@mui/material';
import {MdContactPage} from "react-icons/md";

const ApplyJob = () => {
    const {jobId} = useParams();
    const [data, setData] = React.useState(false);
    const [userInfo, setUserInfo] = React.useState(false);
    const [profile, setProfile] = React.useState(false);
    const [pitch, setPitch] = React.useState("");
    const [resumeOld, setResumeOld] = React.useState(null);
    const [certOld, setCertOld] = useState("");
    const [otherOld, setOtherOld] = useState("");
    const history = useHistory();
    const [applicationChecker, setApplicationChecker] = React.useState(null);
    const [profileStatus, setProfileStatus] = React.useState(false);
    const [update, setUpdate] = React.useState(true);
    const [pdfPreview, setPdfPreview] = React.useState("");
    const [resumePdf, setResumePdf] = React.useState("");
    const [loader2, setLoader] = React.useState(false);
    React.useEffect(() => {
        if (localStorage.getItem('token')) {
            RequestGet('jobView/' + jobId).then((response) => {
                if (parseInt(response.status) === 200) {
                    setData(response.data)
                }
            })
            RequestGet('job-seeker/document-by-profile').then((response) => {
                if (parseInt(response.status) === 202) {
                    if (response.data.cert !== null) {
                        setCertOld(response.data.cert)
                    }
                    if (response.data.other !== null) {
                        setOtherOld(response.data.other)
                    }
                }
            })
            RequestGet('user/applicationChecker/' + jobId).then((response) => {
                setApplicationChecker(response)
            })
            RequestGet("user/resumes-usr").then((response) => {
                if (parseInt(response.status) === 200) {
                    setResumeOld(response.data.resume)
                } else {
                    setResumeOld(false)
                }
            })
            RequestGet('user/profile').then((response) => {
                if (response.preference == null) {
                    window.location.href = '/pref'
                }
                if (response.profile == null) {
                    window.location.href = '/step'
                }
                setProfileStatus(true);
                setProfile(response)
                if (response.resume === null) {
                    setProfileStatus(false)
                }
                if (response.experience === null || response.experience.length === 0) {
                    setProfileStatus(false)
                }
                if (response.language === null || response.language.length === 0) {
                    setProfileStatus(false)
                }
                if (response.qualification === null || response.qualification.length === 0) {
                    setProfileStatus(false)
                }
                if (response.skill === null || response.skill.length === 0) {
                    setProfileStatus(false)
                }
            })
            RequestGet('job-seeker/profile').then((response) => {
                setUserInfo(response)
            })
        } else {
            window.location.href = '/'
        }
        setUpdate(false)
    }, [update])
    const formSubmit = (e) => {
        e.preventDefault();
        if (data.application_form === null || data.application_form === "") {
            applicationSubmit();
        } else {
            if (resumePdf !== "") {
                applicationSubmit();
            } else {
                toast.error("Application Form Required", alertOptions)
            }
        }
    }
    const applicationSubmit = () => {
        var formdata = new FormData();
        formdata.append("candidate_id", profile.profile.id);
        formdata.append("job_id", jobId);
        formdata.append("email", userInfo.email);
        formdata.append("cover_note", pitch);
        formdata.append("custom_resume", resumePdf);
        RequestM('job/application', formdata).then((ress) => {
            toast.success(ress.message, alertOptions)
            setResumePdf("")
            setPitch("")
            setData(false)
            setUpdate(true)
            setTimeout(() => {
                history.push("/")
            }, 2000)
        })
    }
    const onPdfChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            if (event.target.files[0].type == 'application/pdf') {
                setLoader(true);
                setTimeout(() => {
                    setLoader(false);
                    setPdfPreview(event.target.files[0].name)
                }, 500)
                setResumePdf(event.target.files[0])
            } else {
                toast.error("File format not supported", alertOptions)
            }
        }
    }
    if (data !== false) {
        if (profileStatus !== false) {
            if (applicationChecker !== null && profile !== false) {
                if (parseInt(applicationChecker.status) === 404) {
                    return (
                        <div style={{paddingTop: "5rem"}}>
                            <div className="container py-4">
                                <div className="row">
                                    <form onSubmit={(e) => formSubmit(e)} className="col-md-10 col-xl-8 m-auto">
                                        <div className="card border-0">
                                            <div className="card-body shadow rounded py-3">
                                                <h4 className="text-center apply-job-title text-capitalize text-pr">Online
                                                    Job
                                                    Application</h4>
                                                <p className="mb-1 color-sr mt-2 text-center text-gray-450 text-sm-1">
                                                    When you apply online through Jobsicle, The Employer will directly
                                                    received
                                                    your
                                                    application. <br/>
                                                    You can view and manage job applications from your JobSeeker
                                                    Dashboard.
                                                    Experience a hassle <br/>
                                                    free and redesigned job application process anywhere anytime
                                                </p>
                                                <hr className="my-3"/>
                                                <h5 className="text-xl text-center text-gray-600 mb-3 text-size-2 mt-2 d-block">Apply
                                                    for
                                                    "{data.title}"
                                                    by {data.name}</h5>
                                                <div className="row">
                                                    <div className="col-md-8 col-xl-6 m-auto">
                                                        <hr className="mt-0 mb-3"/>
                                                        <div
                                                            className="d-flex justify-content-center">
                                                            <img
                                                                src={profile.profile.picture !== "" ? thumbnailsURI + profile.profile.picture : mainURI + "/uploads/no-img.jpg"}
                                                                className="applyProfileImg rounded mt-2"
                                                                alt=""/>
                                                            <div className="w-75 ps-3">
                                                                <p className="text-xs color-sr text-xl my-0 text-gray-600">{profile.profile.name}</p>
                                                                <p className="text-xs color-sr my-0 font-medium text-gray-600 text-sm-1">{profile.profile.phone}</p>
                                                                <p className="text-xs color-sr mb-0 mt-1 font-medium text-gray-600 text-sm-1">{profile.profile.employment_status}</p>
                                                                <p className="text-xs color-sr mb-0 mt-1 font-medium text-gray-600 text-sm-1">Living
                                                                    in
                                                                    <span
                                                                        className="ml-1">{profile.profile.address}</span>
                                                                </p>
                                                                <p className="text-xs mt-3 font-medium">
                                                                    <a href="/profile" target="_blank"
                                                                       className="text-pr">View Full
                                                                        Profile</a><br/>
                                                                    <Link to="/basic-info" className="text-pr">Edit /
                                                                        Update
                                                                        Profile</Link>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr className="my-3"/>
                                                <h4 className="text-center text-capitalize text-pr apply-job-title">Other
                                                    Documents</h4>
                                                <p className="text-sm color-sr text-gray-500 mt-2 text-center text-sm-1">
                                                    We will securely store your documents. While applying for a job, we
                                                    will directly send the full set of your documents
                                                    <br/>
                                                    to the employer. Hassle free!</p>
                                                <div className="text-center cert-ref-links">
                                                    {/*<div
                                                        className="text-sm certref certref d-flex justify-content-center align-items-center">Certificates
                                                        - <span className="text-warning">Not Linked</span></div>
                                                    <div
                                                        className="text-sm certref certref d-flex justify-content-center align-items-center mb-2">Reference
                                                        Letters - <span className="text-warning">Not Linked</span></div>*/}
                                                    {certOld !== "" && otherOld !== "" ?
                                                        <>
                                                            <div
                                                                className="text-sm my-2 certref d-flex justify-content-center align-items-center">Certificates
                                                                - <span
                                                                    className="color-pr d-flex">
                                                                    <span className="me-1">Linked</span>
                                                                    <AiOutlineCheckCircle className="mt-1"/>
                                                                    <a href={certOld.file_url} target="_blank"
                                                                       className="ms-2 cursor-pointer color-pr">View</a>
                                                                </span>
                                                            </div>
                                                            <div
                                                                className="text-sm my-2 certref d-flex justify-content-center align-items-center mb-2">Reference
                                                                Letters - <span
                                                                    className="color-pr d-flex">
                                                                    <span className="me-1">Linked</span>
                                                                    <AiOutlineCheckCircle className="mt-1"/>
                                                                    <a href={otherOld.file_url} target="_blank"
                                                                       className="ms-2 cursor-pointer color-pr">View</a>
                                                                </span>
                                                            </div>
                                                            <div className="text-center">
                                                                <Link to="/documents"
                                                                      className="btn btn-outline-primary btn-sm px-5">
                                                                    Replace
                                                                </Link>
                                                            </div>
                                                        </>
                                                        :
                                                        <>
                                                            <div
                                                                className="text-sm certref certref d-flex justify-content-center align-items-center">Certificates
                                                                - <span className="text-warning">Not Linked</span></div>
                                                            <div
                                                                className="text-sm certref certref d-flex justify-content-center align-items-center mb-2">Reference
                                                                Letters - <span
                                                                    className="text-warning">Not Linked</span></div>
                                                            <div className="text-center mt-3">
                                                                <Link to="/documents"
                                                                      className="btn btn-outline-primary btn-sm px-5">
                                                                    Link Documents
                                                                </Link>
                                                            </div>
                                                        </>
                                                    }
                                                </div>
                                                <hr className="my-3"/>
                                                <h3 className="text-center text-capitalize text-pr apply-job-title">External
                                                    Resume</h3>
                                                <p className="text-xxs my-0 text-center color-sr">(Optional)</p>
                                                <p className="text-sm color-sr text-gray-500 mt-2 text-center text-sm-1">
                                                    By default your profile (Jobsicle Resume) will be sent to employer
                                                    along with the Job Application. If you want to add your <br/>
                                                    own custom resume, link it here.
                                                </p>
                                                {resumeOld !== false && resumeOld !== null
                                                    ?
                                                    <>
                                                        <div
                                                            className="text-center mt-3 font-bold d-flex justify-content-center align-items-center mb-2 text-sm color-tree font-weight-600">
                                                            {resumeOld.split("/")[resumeOld.split("/").length - 1]}
                                                            <span
                                                                className="color-pr"><AiOutlineCheckCircle
                                                                className="ms-1"/></span>
                                                        </div>
                                                        <div className="text-center">
                                                            <button className="btn btn-outline-primary btn-sm px-4">
                                                                <a href={resumeOld} target="_blank">View</a>
                                                                <span className="mx-2">|</span>
                                                                <span className="">
                                                            <Link to="/resumes">Replace </Link>
                                                        </span>
                                                            </button>
                                                        </div>
                                                    </> :
                                                    <div className="text-center mt-3">
                                                        <Link to="/resumes"
                                                              className="btn btn-outline-primary btn-sm px-3">Link
                                                            Resume
                                                        </Link>
                                                    </div>
                                                }
                                                {data.application_form === null || data.application_form === "" ?
                                                    "" :
                                                    <>
                                                        <hr className="my-3"/>
                                                        <h4 className="text-center text-capitalize text-pr apply-job-title">Application
                                                            Form</h4>
                                                        <p className="text-sm color-sr text-gray-500 mt-2 text-center text-sm-1">
                                                            This job requires an application form to be filled and
                                                            submitted.
                                                            You can download it from the job details page. <br/>
                                                            Please attach the filled application form here. Allowed PDF
                                                            only.
                                                            Max 10MB.
                                                        </p>
                                                        {pdfPreview !== "" ?
                                                            <div
                                                                className="text-center font-bold d-flex justify-content-center align-items-center my-2 text-sm color-tree font-weight-600">
                                                                {pdfPreview}
                                                                <span
                                                                    className="color-pr"><AiOutlineCheckCircle
                                                                    className="ms-1"/></span>
                                                            </div> : ""}
                                                        {loader2 ?
                                                            <div className="w-50 m-auto my-2">
                                                                <LinearProgress color="primary"/>
                                                            </div> : ""}
                                                        <div className="text-center my-2">
                                                            <label htmlFor="upload-resume"
                                                                   className="btn btn-outline-primary btn-sm px-3">
                                                                {pdfPreview !== "" ? "Replace" : "+ Upload Form"}
                                                            </label>
                                                            <input type="file" id="upload-resume"
                                                                   onChange={(event) => onPdfChange(event)}
                                                                   className="d-none"/>
                                                        </div>
                                                    </>
                                                }

                                                <hr className="my-3"/>
                                                <h4 className="text-center pt-3 text-capitalize mb-2 apply-job-title text-pr">Covering
                                                    Letter</h4>
                                                <p className="text-center color-sr mb-1 text-sm text-gray-600">In a few
                                                    sentences, let the employer know why you are right for this
                                                    job. </p>
                                                <div className="text-center">
                                                    <a style={{fontSize: '.9rem'}}
                                                       onClick={() => setPitch('Dear Sir/Madam\n' +
                                                           'I am writing to apply for the position advertised on Jobsicle.\n' +
                                                           'The opportunity presented in this listing is very interesting, and I believe that my experience and education will make me a very competitive candidate for this position.\n' +
                                                           'Please see my resume for additional information.\n' +
                                                           'Thank you for your time and consideration. I look forward to speaking with you about this employment opportunity.\n' +
                                                           'Sincerely,')}
                                                       className="text-main cursor-pointer font-medium">Add
                                                        Sample</a>
                                                </div>
                                                <textarea required name="" id="" cols="30" rows="10"
                                                          style={{fontSize: '.9rem'}}
                                                          maxLength={2500} onChange={(e) => {
                                                    setPitch(e.target.value);
                                                }} className="mt-2 form-control" placeholder="" value={pitch}/>
                                                <div className="text-right text-sm w-100 color-sr">
                                                    {pitch !== null ? 2500 - pitch.length : 2500} characters left
                                                </div>
                                                <div className="text-center pb-3">
                                                    <Button color="primary" type="submit" className="text-capitalize"
                                                            variant="contained">Submit Application</Button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    )
                } else {
                    return (
                        <div style={{paddingTop: "5rem"}}>
                            <div className="container py-4">
                                <div className="row">
                                    <div className="col-md-10 text-center col-xl-8 m-auto">
                                        Applied
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
            } else {
                return (
                    <div style={{paddingTop: "5rem"}}>
                        <div className="container py-4">
                            <div className="row">
                                <div className="col-md-10 col-xl-8 m-auto">
                                    {loader("20rem")}
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        } else {
            return (
                <div style={{paddingTop: "5rem"}}>
                    <div className="container py-4">
                        <div className="row">
                            <div className="col-md-10 col-xl-8 text-center m-auto">
                                Please complete your profile
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    } else {
        return (
            <div style={{paddingTop: "5rem"}}>
                <div className="container py-4">
                    <div className="row">
                        <div className="col-md-10 col-xl-8 m-auto">
                            {loader("20rem")}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
};

export default ApplyJob;
