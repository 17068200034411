import React from 'react';
import Email from "./warning/Email";
import {RequestGet} from "./Request";
import {loader} from "../config";
import {RequestG} from "../routes/Request";
import CreateJobItem from "./items/CreateJobItem";
import YaSubscription from "./warning/YaSubscription";
import NoSubscription from "./warning/NoSubscription";

const CreateJob = () => {
    const [company, setCompany] = React.useState("");
    const [data, setData] = React.useState(false);
    const [fields, setFields] = React.useState(false);
    React.useEffect(() => {
        if (data === false) {
            RequestGet('employ/company').then((response) => {
                setCompany(response.company.name)
                setData(response)
            })
            RequestG('fields').then((response) => {
                setFields(response)
            })
        }
    }, [data]);
    if (data !== false && fields !== false) {
        return (
            <>
                <Email/>
                {data.annual !== null || data.payper !== null ? <YaSubscription data={data}/> : <NoSubscription/>}
                {data.annual !== null || data.payper !== null ?
                    <CreateJobItem data={data} subscription={data.annual !== null ? data.annual : data.payper}
                                   fields={fields} company={company}/> : ""}
            </>
        )
    } else {
        return loader("20rem")
    }
};

export default CreateJob;
