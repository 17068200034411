import React, {useState} from 'react'
import {AiFillCheckCircle} from "react-icons/ai"
import {Link, useParams} from 'react-router-dom'
import {loader, mainURI} from "../config";
import {RequestG} from "../routes/Request";
import Moment from "react-moment";
import "./design.scss";
import verified from "../img/comp-verified.png";
import staff from "../img/number-of-staffs.png";
import founded from "../img/founded-on.png";
import govern from "../img/government-sect.png";
import privates from "../img/private-others-sect.png";
import tourism from "../img/tourism-sect.png";
import moment from 'moment';
import {Pagination} from "@mui/material";

const ViewCompany = () => {
    const {id} = useParams();
    const [data, setData] = React.useState(null);
    const [sector, setSector] = useState(privates);
    const [page, setPage] = useState(1);

    React.useEffect(() => {
        RequestG('employers/' + id + '?page=' + page).then((response) => {
            if (response.data.profile.sector == 'Private (Others)') {
                setSector(privates)
            }
            if (response.data.profile.sector == 'Private (Tourism)') {
                setSector(tourism)
            }
            if (response.data.profile.sector == 'Government / Civil Service') {
                setSector(govern)
            }
            setData(response)
        })
    }, [id, page])

    function expiryDate(date_string) {
        var expiration = moment(date_string).format("YYYY-MM-DD");
        var current_date = moment().format("YYYY-MM-DD");
        return moment(expiration).diff(current_date, 'days');
    }

    const pagination = (e) => {
        setPage(e.target.textContent);
    }
    if (data !== null) {
        if (parseInt(data.status) === 200) {
            const data2 = data.data;
            console.log(data2.jobsData.jobs)
            return (
                <div className="min-h-screen" style={{paddingTop: "5rem"}}>
                    <div className="container">
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-0 pt-4">
                            <div className="bg-white padding-com-main py-4 flex items-center gap-4">
                                <div className="w-16 h-16">
                                    <img style={{width: '100%', height: "100%", objectFit: "cover"}}
                                         src={`${data2.profile.logo !== undefined ? data2.profile.logo !== null ? data2.profile.logo : mainURI + "/uploads/no-img.jpg" : mainURI + "/uploads/no-img.jpg"}`}
                                         alt=""/>
                                </div>
                                <div>
                                    <div className="flex items-center gap-2">
                                        <h5 className="text-gray-600"
                                            style={{fontSize: '1.17rem'}}>{data2.profile.name}</h5> {parseInt(data2.profile.is_verified) === 1 &&
                                    <AiFillCheckCircle className="text-pr"/>}
                                    </div>
                                    <div>
                                        <p className="text-gray-500 text-sm">Currently have {data2.totalOpenJob} Open
                                            Job
                                            Offers</p>
                                    </div>
                                </div>
                            </div>
                            <div style={{paddingRight: '2rem'}}
                                 className="bg-white py-2 d-flex justify-content-end align-items-center">
                                <span className="company_since">
                                    On Jobsicle since <span className="ms-1 time"><Moment
                                    format={"Y"}>{data2.profile.created_at}</Moment></span>
                                </span>
                            </div>
                        </div>
                        <div style={{marginTop: '.5px'}} className="w-full px-3 py-4 bg-white">
                            <div className="row mx-2">
                                <div className="col-md-6">
                                    <p className="text-gray-500 mt-0 mb-2 text-sm"><span
                                        className="font-bold">Address : </span> {data2.profile.address}</p>
                                    <p className="text-gray-500 mt-0 mb-2 text-sm"><span className="font-bold">Average Application Processing time : </span> {data2.profile.process}
                                    </p>
                                    <p className="text-gray-500 mt-0 mb-2 text-sm"><span
                                        className="font-bold">Phone : </span> {data2.profile.hr_phone}</p>
                                    <p className="text-gray-500 mt-0 mb-2 text-sm"><span
                                        className="font-bold">Email : </span> {data2.profile.hr_email}</p>
                                </div>
                                <div className="col-md-6 all-icon-items d-flex">
                                    {parseInt(data2.profile.is_verified) === 1 ?
                                        <div className="text-center item">
                                            <img className="icon mt-2" src={verified} alt=""/>
                                            <span className="text mt-0 text-sm text-main">
                                            Verified
                                        </span>
                                        </div> : ""}
                                    <div className="text-center item">
                                        <img className="icon" src={staff} alt=""/>
                                        <span className="text mt-2 text-sm text-main">
                                            {data2.profile.size}
                                        </span>
                                    </div>
                                    <div className="text-center item">
                                        <img className="icon" src={sector} alt=""/>
                                        <span className="text mt-2 text-sm text-main">
                                            {data2.profile.sector}
                                        </span>
                                    </div>
                                    <div className="text-center item">
                                        <img className="icon" src={founded} alt=""/>
                                        <span className="text mt-2 text-sm text-main">
                                            <Moment format={"Y"}>{data2.profile.founded_on}</Moment>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            {/*{parseInt(data2.profile.is_verified) === 1 ? "" : ''}*/}
                            <div className="border-com mx-3 p-4 mt-3">
                                <h5 className="font-normal text-main">Our Story</h5>
                                <p className="text-gray-500 mt-3 text-sm space-y-1">{data2.profile.introduction}</p>
                            </div>
                            <div className="border-com mx-3 p-4 mt-3">
                                <h5 className="font-normal text-main">Staff Benefits</h5>
                                <p className="text-gray-500 mt-3 text-sm space-y-1">{data2.profile.why_work_with_us}</p>
                            </div>
                        </div>
                        <div className="mt-2 w-full padding-com-main py-4 bg-white">
                            <h5 className="font-normal text-main">Jobs Posted</h5>
                            <div className="pt-2 w-full grid grid-cols-1">
                                {data2.jobsData.jobs !== undefined && data2.jobsData.jobs.length > 0 && data2.jobsData.jobs.map((item) =>
                                    <div
                                        className="grid grid-cols-3 gap-y-2 lg:gap-y-0 lg:grid-cols-5 border-b border-gray-200 text-sm py-2 text-gray-600">
                                        <div className="col-span-2">{item.details.title}</div>
                                        <div>Published on <Moment
                                            format="DD MMM, YYYY">{item.details.created_at}</Moment>
                                        </div>
                                        <div>
                                            {expiryDate(item.details.expired_at) > 0 ?
                                                <>Closing on <Moment
                                                    format="DD MMM, YYYY">{item.details.expired_at}</Moment></>
                                                : "Expired"}
                                        </div>
                                        <div>
                                            {parseInt(item.active) === 1
                                                ?
                                                <a target="_blank" rel="noreferrer" href={`/job/${item.details.id}`}
                                                   className="text-pr">View
                                                    Job</a>
                                                : ""}
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="py-3 text-center">
                                <Pagination
                                    count={Math.ceil(data2.jobsData.job_total / 15)}
                                    onClick={(e) => pagination(e)}
                                    hidePrevButton hideNextButton
                                    color="primary"
                                    className="d-flex justify-content-center"/>
                            </div>
                        </div>
                    </div>
                </div>
            )
        } else {
            return <div style={{paddingTop: '5rem'}} className="container text-center"><h4 className="py-5">Employer
                Profile not found!</h4></div>
        }
    } else {
        return <div style={{paddingTop: '5rem'}} className="container">{loader("20rem")}</div>
    }
}

export default ViewCompany
