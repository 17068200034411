import React from 'react';
import "../design.scss";

const CompanyProgress = ({data}) => {
    return (
        <>
            <div className="companyProgress content d-flex">
                <div className="item text-center">
                    <span className="number d-flex justify-content-center align-items-center">{parseInt(data.totalJobs)}</span>
                    <span className="text">Jobs Posted</span>
                </div>
                <div className="item text-center">
                    <span className="number d-flex justify-content-center align-items-center">{parseInt(data.pendingJobs)}</span>
                    <span className="text">Pending Approval</span>
                </div>
                <div className="item text-center">
                    <span className="number d-flex justify-content-center align-items-center">{parseInt(data.applications)}</span>
                    <span className="text">Applications</span>
                </div>
                <div className="item text-center">
                    <span className="number d-flex justify-content-center align-items-center">{parseInt(data.shortListed)}</span>
                    <span className="text">Short-listed</span>
                </div>
            </div>
        </>
    );
};

export default CompanyProgress;