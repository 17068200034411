import React from 'react';
import Email from "./warning/Email";

const TalentPool = () => {
    return (
        <>
            <Email/>
            Talent Pool
        </>
    );
};

export default TalentPool;
