import React from 'react';
import icon from "../../assets/images/001m.png";
import {ComContext} from "../../routes/Employer";

const VerifyIcon = () => {
    const {user} = React.useContext(ComContext);
    return (
        <>

            {user.email_verified_at === null &&
            <div className="text-center py-5">
                <div className="icon w-25 m-auto mb-4"><img src={icon} className="img-fluid" alt=""/></div>
                <h3 className="text-two">You account is not activated</h3>
                <p className="mb-0">Please verify your email and fill out the company profile to active your
                    account.</p>
            </div>}
        </>
    );
};

export default VerifyIcon;
