import React from 'react';
import {TextField} from "@mui/material";

const InvForm = ({option, numberPage, setNumberPage, userPack}) => {
    const currency = parseInt(userPack[0].currency) === 2 ? "USD " : "MVR ";
    return (
        <>
            {option == 1 ?
                <>
                    <div className="card rounded-0">
                        <div className="card-body">
                            <div className="topper">Annual Subscription</div>
                            <div className="content">
                                <p>{currency} {userPack[0].price_per_unit}/-</p>
                                <ul>
                                    <li>Unlimited job postings</li>
                                    <li>Active for 365 days</li>
                                    <li>Access to TALENTPOOL</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </>
                :
                <>
                    <div className="card rounded-0">
                        <div className="card-body">
                            <div className="topper">Pay-per-job Subscription</div>
                            <div className="content">
                                <p>{currency} {userPack[1].price_per_unit}/-</p>
                                <ul>
                                    <li>1 job postings</li>
                                    <li>Job will be active for 20 days</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="mt-2">
                        <TextField className="mt-3 w-100" id="outlined-basic"
                                   label="Number of Jobs" type="number" required
                                   inputProps={{min: 1}} autoComplete="chrome-off"
                                   onChange={(e) => setNumberPage(e.target.value)}
                                   variant="outlined" value={numberPage}/>
                    </div>
                    <div className="mt-2 bg-light d-flex rounded px-3 py-2">
                        <div className="w-50 text-uppercase">Total</div>
                        <div
                            className="w-50 text-right">{currency} {numberPage * userPack[1].price_per_unit}
                        </div>
                    </div>
                </>
            }
        </>
    );
};

export default InvForm;
