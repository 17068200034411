import React, {useState} from 'react';
import {Button, FormControl, InputLabel, MenuItem, Select, TextField} from "@mui/material";
import ReactPhoneInput from "react-phone-input-2";
import Checkbox from '@mui/material/Checkbox';
import {toast} from "react-toastify";
import {alertOptions, apiURI} from "../config";
import {BsCloudUpload} from 'react-icons/bs';
import {IoReloadCircle} from 'react-icons/io5';
import {ProgressBar} from "react-bootstrap";
import "./design.scss";
import axios from "axios";
import Accordion from "react-bootstrap/Accordion";

const label = {inputProps: {'aria-label': 'Checkbox demo'}};

const JobForm = () => {
    const MAX_FILE_SIZE = 10024;
    const [name, setName] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [phone, setPhone] = React.useState("");
    const [application, setApplication] = React.useState("");
    const [nidCard, setNidCard] = useState("");
    const [certifications, setCertifications] = useState("");
    const [resume, setResume] = useState("");
    const [reference, setReference] = useState("");
    const [checkedCon, setCheckedCon] = React.useState(false);
    const [progress, setProgress] = useState("");
    const [prefBranch, setPrefBranch] = useState("");
    const [progress2, setProgress2] = useState("");
    const [progress3, setProgress3] = useState("");
    const [progress4, setProgress4] = useState("");
    const [progress5, setProgress5] = useState("");
    const [loadFile1, setLoadFile1] = useState(0);
    const [loadFile2, setLoadFile2] = useState(0);
    const [loadFile3, setLoadFile3] = useState(0);
    const [loadFile4, setLoadFile4] = useState(0);
    const [loadFile5, setLoadFile5] = useState(0);
    const formSubmit = (e) => {
        e.preventDefault();
        if (name === "" || email === "" || phone === "" || application === "" || nidCard === "" || certifications === "" || resume === "" || prefBranch === "") {
            toast.error("Please complete all required fields", alertOptions)
        } else {
            if (checkedCon === true) {
                let myHeaders = new Headers();
                myHeaders.append("Accept", "application/json");
                let formdata = new FormData();
                formdata.append("name", name);
                formdata.append("phone", phone);
                formdata.append("email", email);
                formdata.append("application", JSON.stringify(application));
                formdata.append("id_card", JSON.stringify(nidCard));
                formdata.append("education_cert", JSON.stringify(certifications));
                formdata.append("resume", JSON.stringify(resume));
                formdata.append("pref_branch", prefBranch);
                if (reference !== "") {
                    formdata.append("reference", JSON.stringify(reference));
                }
                let requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: formdata,
                    redirect: 'follow'
                };
                fetch(apiURI + "job-form", requestOptions)
                    .then(response => response.json())
                    .then(result => {
                        toast.success(result.message, alertOptions)
                        setTimeout(function () {
                            window.location.href = '/'
                        }, 3000)
                    })
                    .catch(error => console.log('error', error));
            } else {
                toast.error("Please confirm checkbox", alertOptions)
            }
        }
    }
    const onFileChange = (e, setState, setLoadFile, setProg) => {
        if (e.target.files.length > 0) {
            const fileSizeKiloBytes = e.target.files[0].size / 1024;
            if (e.target.files[0].type !== 'application/pdf') {
                toast.error("File type error", alertOptions)
                return
            }
            if (fileSizeKiloBytes > MAX_FILE_SIZE) {
                toast.error("File size is greater than maximum limit", alertOptions)
                return
            }
            let formData = new FormData()
            formData.append("file", e.target.files[0])
            axios.post(apiURI + "document-upload", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
                onUploadProgress: data => {
                    setProg(Math.round((100 * data.loaded) / data.total))
                },
            }).then((ress) => {
                setState(ress.data.data)
                setLoadFile(1)
            })
        }
    }
    return (
        <>
            <div className="register">
                <div className="col-md-6 col-xl-4 col-10 m-auto">
                    <form onSubmit={(e) => formSubmit(e)} className="container">
                        <h2 style={{fontSize: 'x-large'}} className="color-pr mb-2">JOB APPLICATION</h2>
                        <p className="mb-2">Applying for the post of “Assistant” at State Bank of India, Maldives</p>
                        <h6 className="mb-2"><b>IMPORTANT:</b></h6>
                        <p className="text-dark mb-4">If you have multiple certificates or documents in one category,
                            please
                            merge them into
                            one PDF and upload below. <br/> Download the application form - <a
                                href="https://bit.ly/jobformsbi" style={{color: '#00BBB1', fontWeight: 'bold'}}
                                target="_blank">Click
                                here</a></p>
                        <Accordion>
                            <Accordion.Item eventKey="0" className="content p-0 mb-2">
                                <Accordion.Header className="accordion_h2">
                                    <h2 style={{fontSize: 'large'}} className="color-pr mb-0">
                                        JOB SUMMARY
                                    </h2>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <div className="content1">
                                        <p className="my-2">Handling all types of general banking work and attending
                                            to
                                            front office
                                            customers in a friendly and
                                            courteous manner.</p>
                                        <p className="my-2">
                                            <b>Workplace:</b> Any branch of SBI Maldives (Male’, Hulhumale,
                                            Maamigili,
                                            Hithadhoo)
                                        </p>
                                        <p className="my-2">
                                            <b>Age:</b> Between 18 to 25 years as on 1st October 2022
                                        </p>
                                        <br/>
                                        <h6 className="mt-2">
                                            QUALIFICATIONS & EXPERIENCE:
                                        </h6>
                                        <div className="my-2 text-dark" style={{fontSize: '13px'}}>
                                            <li>
                                                Minimum GCE A’Level
                                            </li>
                                            <li>
                                                Preference to graduates and candidates with experience in Banking /
                                                Financial sector
                                            </li>
                                            <li>
                                                Preference to candidates with higher grades in English & Mathematics
                                                subjects
                                            </li>
                                        </div>
                                        <br/>
                                        <h6 className="mt-2">
                                            Additional Skills:
                                        </h6>
                                        <div className="my-2 text-dark" style={{fontSize: '13px'}}>
                                            <li>
                                                Fluency in written and spoken English
                                            </li>
                                            <li>
                                                Proficiency in computer applications (MS Office)
                                            </li>
                                            <li>
                                                Excellent communication and relationship management skills
                                            </li>
                                        </div>
                                        <br/>
                                        <h6 className="mt-2">
                                            REMUNERATION:
                                        </h6>
                                        <div className="my-2 text-dark" style={{fontSize: '13px'}}>
                                            <li>
                                                Attractive salary + allowances and benefits
                                            </li>
                                        </div>
                                        <br/>
                                        <h6 className="mt-2">
                                            KEY TRAITS:
                                        </h6>
                                        <div className="my-2 text-dark" style={{fontSize: '13px'}}>
                                            <li>
                                                Adaptability: With ever changing needs of the financial world, State
                                                Bank of
                                                India needs
                                                individuals who are adaptable and mobile, willing to adjust to
                                                variety
                                                of
                                                roles and show
                                                responsiveness to the needs to the bank.
                                            </li>
                                            <li>
                                                Willingness to Learn: The Bank believes in learning by doing. At
                                                SBI,
                                                you
                                                are constantly faced
                                                with new challenges and unfamiliar environments. The ability and
                                                eagerness
                                                to learn quickly
                                                and grow is a crucial trait which the bank values in all of its
                                                employees.
                                            </li>
                                            <li>
                                                Team Player: The employees of SBI are members of a tightly knit
                                                family
                                                and
                                                they work in
                                                teams of motivated people to achieve their goals. SBI truly values
                                                individuals who can work
                                                effectively in team settings and encourage other team members to
                                                work
                                                towards common
                                                goals.
                                            </li>
                                        </div>
                                        <br/>
                                        <h6 className="mt-2">
                                            DOCUMENTS TO BE SUBMITTED:
                                        </h6>
                                        <div className="my-2 text-dark" style={{fontSize: '13px'}}>
                                            <li>
                                                Duly filled in Application Form (<a href="https://bit.ly/jobformsbi"
                                                                                    target="_blank"><b
                                                className="color-pr">download here</b></a>)
                                            </li>
                                            <li>
                                                National ID card copy
                                            </li>
                                            <li>
                                                Reference letters from previous employers (if applicable)
                                            </li>
                                            <li>
                                                Attested copies of educational certificates/transcript (in case of
                                                graduates, copies of
                                                accredited certificates from MQA)
                                            </li>
                                            <li>
                                                Curriculum Vitae (CV)
                                            </li>
                                        </div>
                                        <br/>
                                        <h6 className="mt-2">
                                            HOW TO APPLY:
                                        </h6>
                                        <div className="my-2 text-dark" style={{fontSize: '13px'}}>
                                            <li>
                                                Apply before 7th November 2022, 5:00PM
                                            </li>
                                            <li>
                                                Download and fill out the Application form on your PC / Laptop
                                                (handwritten forms will not
                                                be accepted), print the form and paste your passport-size
                                                photograph.
                                            </li>
                                            <li>
                                                Apply online by uploading the filled Application form and supporting
                                                documents from the
                                                online submission below.
                                            </li>
                                            <li>
                                                Once your application is accepted by the Bank, you will receive an
                                                SMS
                                                notification within 1-
                                                2 working days.
                                            </li>
                                        </div>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                        <div className="content px-5 py-4" style={{borderRadius: '.7rem'}}>
                            <TextField className="mt-3 w-100" id="outlined-basic" label="Full Name"
                                       onChange={(e) => setName(e.target.value)}
                                       variant="outlined" value={name}/>
                            <label htmlFor="mobile" className="text-xxs mb-1 mt-2 text-muted">Mobile
                                Number</label>
                            <ReactPhoneInput
                                value={phone}
                                country='mv'
                                placeholder="Mobile Number"
                                onChange={setPhone}
                                inputExtraProps={{
                                    name: 'mobile',
                                    required: false,
                                    autoFocus: false
                                }}
                            />
                            <TextField className="mt-3 w-100" id="outlined-basic" label="Email Address"
                                       onChange={(e) => setEmail(e.target.value)}
                                       variant="outlined" value={email}/>
                            <FormControl className="mt-3" required fullWidth>
                                <InputLabel id="demo-simple-select-label">Preferred Branch</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={prefBranch}
                                    onChange={(e) => setPrefBranch(e.target.value)}
                                    label="Preferred Branch">
                                    <MenuItem value={"Male'"}>Male'</MenuItem>
                                    <MenuItem value={"Hulhumale"}>Hulhumale</MenuItem>
                                    <MenuItem value={"Maamigili"}>Maamigili</MenuItem>
                                    <MenuItem value={"Hithadhoo"}>Hithadhoo</MenuItem>
                                </Select>
                            </FormControl>
                            <div className="upload-file my-3">
                                <p className="text_main my-0">Application Form * (with passport size photo pasted)</p>
                                <p className="warning_text small text-warning my-0">Allowed 1 PDF, max 10MB</p>
                                {progress && <ProgressBar className="bg-main" now={progress}
                                                          label={`${progress}%`}/>}
                                <label htmlFor="application"
                                       className={`my-1 rounded d-flex ${loadFile1 === 1 ? 'active_icon_sec' : 'icon_sec'}`}>
                                    {loadFile1 === 1 ? <IoReloadCircle className="w-6 h-6 icon"/> :
                                        <BsCloudUpload className="w-6 h-6 icon"/>}
                                    <span className="text ms-1">
                                        {application ? application.original : "Choose File"}
                                    </span>
                                </label>
                                <input type="file" id="application"
                                       onChange={(e) => onFileChange(e, setApplication, setLoadFile1, setProgress)}
                                       className="hidden my-3"/>
                            </div>
                            <div className="upload-file my-3">
                                <p className="text_main my-0">National ID Card *</p>
                                <p className="warning_text small text-warning my-0">Allowed 1 PDF, max 10MB</p>
                                {progress2 && <ProgressBar className="bg-main" now={progress2}
                                                           label={`${progress2}%`}/>}
                                <label htmlFor="nid"
                                       className={`my-1 rounded d-flex ${loadFile2 === 1 ? 'active_icon_sec' : 'icon_sec'}`}>
                                    {loadFile2 === 1 ? <IoReloadCircle className="w-6 h-6 icon"/> :
                                        <BsCloudUpload className="w-6 h-6 icon"/>}
                                    <span className="text ms-1">
                                        {nidCard ? nidCard.original : "Choose File"}
                                    </span>
                                </label>
                                <input type="file" id="nid"
                                       onChange={(e) => onFileChange(e, setNidCard, setLoadFile2, setProgress2)}
                                       className="hidden my-3"/>
                            </div>
                            <div className="upload-file my-3">
                                <p className="text_main my-0">Copies of Educational Certificates *</p>
                                <p className="warning_text small text-warning my-0">Allowed 1 PDF, max 10MB</p>
                                {progress3 && <ProgressBar className="bg-main" now={progress3}
                                                           label={`${progress3}%`}/>}
                                <label htmlFor="certification"
                                       className={`my-1 rounded d-flex ${loadFile3 === 1 ? 'active_icon_sec' : 'icon_sec'}`}>
                                    {loadFile3 === 1 ? <IoReloadCircle className="w-6 h-6 icon"/> :
                                        <BsCloudUpload className="w-6 h-6 icon"/>}
                                    <span className="text ms-1">
                                        {certifications ? certifications.original : "Choose File"}
                                    </span>
                                </label>
                                <input type="file" id="certification"
                                       onChange={(e) => onFileChange(e, setCertifications, setLoadFile3, setProgress3)}
                                       className="hidden my-3"/>
                            </div>
                            <div className="upload-file my-3">
                                <p className="text_main my-0">CV / Resume *</p>
                                <p className="warning_text small text-warning my-0">Allowed 1 PDF, max 10MB</p>
                                {progress4 && <ProgressBar className="bg-main" now={progress4}
                                                           label={`${progress4}%`}/>}
                                <label htmlFor="resume"
                                       className={`my-1 rounded d-flex ${loadFile4 === 1 ? 'active_icon_sec' : 'icon_sec'}`}>
                                    {loadFile4 === 1 ? <IoReloadCircle className="w-6 h-6 icon"/> :
                                        <BsCloudUpload className="w-6 h-6 icon"/>}
                                    <span className="text ms-1">
                                        {resume ? resume.original : "Choose File"}
                                    </span>
                                </label>
                                <input type="file" id="resume"
                                       onChange={(e) => onFileChange(e, setResume, setLoadFile4, setProgress4)}
                                       className="hidden my-3"/>
                            </div>
                            <div className="upload-file my-3">
                                <p className="text_main my-0">Reference Letters (if applicable)</p>
                                <p className="warning_text small text-warning my-0">Allowed 1 PDF, max 10MB</p>
                                {progress5 && <ProgressBar className="bg-main" now={progress5}
                                                           label={`${progress5}%`}/>}
                                <label htmlFor="reference"
                                       className={`my-1 rounded d-flex ${loadFile5 === 1 ? 'active_icon_sec' : 'icon_sec'}`}>
                                    {loadFile5 === 1 ? <IoReloadCircle className="w-6 h-6 icon"/> :
                                        <BsCloudUpload className="w-6 h-6 icon"/>}
                                    <span className="text ms-1">
                                        {reference ? reference.original : "Choose File"}
                                    </span>
                                </label>
                                <input type="file" id="reference"
                                       onChange={(e) => onFileChange(e, setReference, setLoadFile5, setProgress5)}
                                       className="hidden my-3"/>
                            </div>
                        </div>
                        <div className="confirm_check d-flex py-2">
                            <Checkbox className="ps-0"
                                      onChange={(event) => setCheckedCon(event.target.checked)} {...label} />
                            <label htmlFor="confirm" style={{marginTop: ".7rem"}} className="small">I confirm that the
                                above information / documents are true and
                                valid.</label>
                            <br/>
                        </div>
                        <Button color="primary" type="submit" className="text-white mt-2"
                                variant={"contained"}>Submit</Button>
                        <div className="pt-2">
                            For any clarifications, please mail to hr.sbimale@statebank.com
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
        ;
};

export default JobForm;