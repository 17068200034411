import React, {useEffect, useState, useContext} from 'react';
import ProfileStatus from "./alerts/ProfileStatus";
import {alertOptions, loader} from "../config";
import {RequestGet, RequestM} from "../employer/Request";
import {RequestG} from "../routes/Request";
import {MdOutlineContactPage} from "react-icons/md";
import {toast} from "react-toastify";
import {LinearProgress} from "@mui/material";
import "./design.scss";
import {AiOutlineInfoCircle} from 'react-icons/ai';

const Documents = () => {
    const [fields, setFields] = useState(false);
    const [profile, setProfile] = useState(null);
    const [cert, setCert] = useState("");
    const [other, setOther] = useState("");
    const [certOld, setCertOld] = useState("");
    const [otherOld, setOtherOld] = useState("");
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        if (fields === false) {
            RequestGet('user/profile').then((response) => {
                if (response.preference == null) {
                    window.location.href = '/pref'
                }
                if (response.profile == null) {
                    window.location.href = '/step'
                }
                setProfile(response)
            })
            RequestGet('job-seeker/document-by-profile').then((response) => {
                if (parseInt(response.status) === 202) {
                    if (response.data.cert !== null) {
                        setCertOld(response.data.cert)
                    }
                    if (response.data.other !== null) {
                        setOtherOld(response.data.other)
                    }
                }
            })
            RequestG('fields').then((response) => {
                setFields(response)
            })
        }
    }, [fields])
    const onChangeFileUpload = (e, type, setFile, setCoOld) => {
        setLoading(true)
        if (e.target.files[0].type === "application/pdf") {
            const formData = new FormData();
            formData.append('file', e.target.files[0]);
            RequestM('document-upload', formData).then((response) => {
                if (parseInt(response.status) === 202) {
                    toast.success(response.message, alertOptions)
                    setFile(response.data)
                    setLoading(false)
                    if (type == 'other') {
                        uploadDocuments(type, setOther, setOtherOld, response.data, otherOld)
                    } else {
                        uploadDocuments(type, setCert, setCertOld, response.data, certOld)
                    }
                }
            })
        } else {
            setLoading(false)
            toast.error("Document format invalid", alertOptions)
        }
    }
    const uploadDocuments = (type, setCo, setCoOld, file, old) => {
        if (file !== "") {
            setLoading(true)
            var formdata = new FormData();
            formdata.append("job_seeker_profile_id", profile.profile.id);
            formdata.append("type", type);
            formdata.append("file_url", file.uri);
            formdata.append("file_name", file.original);
            if (old !== "") {
                formdata.append("_method", "put");
                RequestM("job-seeker/documents/" + old.id, formdata).then((ress) => {
                    if (parseInt(ress.status) === 201) {
                        setCo("")
                        setCoOld(ress.data)
                        setLoading(false)
                    }
                })
            } else {
                RequestM("job-seeker/documents", formdata).then((ress) => {
                    if (parseInt(ress.status) === 201) {
                        setCo("")
                        setCoOld(ress.data)
                        setLoading(false)
                    }
                })
            }
        }
    }
    if (fields !== false && profile !== null) {
        return (
            <>
                <ProfileStatus profile={profile}/>
                <div className="content py-4 px-2">
                    <div className="d-flex py-2 px-3">
                        <MdOutlineContactPage className="me-2 w-6 h-6 text-two"/>
                        <h4 className="heading text-main my-0">Let's link your documents</h4>
                    </div>
                    <p className="mt-4 px-3 text-sm">
                        No more printing or scanning every time. Upload once and use it for all applications. We will
                        securely store your documents. While applying for a job,
                        we will directly send the full set of your documents to the Employer. Hassle free!
                    </p>
                    <p className="mb-2 px-3 mt-3 text-sm mb-4">Allowed file types is PDF, Maximum file size should not
                        exceed
                        10MB</p>
                    <div className="documentContent rounded-3 mx-3 px-4 py-2">
                        <div className="w-100 mb-3">
                            {loading && <LinearProgress color="primary"/>}
                        </div>
                        <div className="item row">
                            <div className="col-6">
                                <input type="file" onChange={(e) => onChangeFileUpload(e, "cert", setCert, setCertOld)}
                                       className="d-none"
                                       id="cert"/>
                                <h5 className="d-flex main-color">Certificates <span
                                    className={`ms-2 ${certOld === "" ? "text-danger" : "color-pr"}`}><AiOutlineInfoCircle/></span>
                                </h5>
                                <p className="mt-2 text-xxs mb-0 text-muted">
                                    Education, other qualifications
                                </p>
                                {certOld !== undefined && certOld !== "" ?
                                    <>
                                        <span className="mr-4 text-xxs">{certOld.file_name}</span>
                                        <a href={`${certOld !== "" ? certOld.file_url : "#"}`}
                                           target={`${certOld !== "" ? "_blank" : "_self"}`}
                                           className={`${certOld !== "" ? "color-pr text-xxs" : "disabled"}`}>Click to
                                            view</a>
                                    </>
                                    : ""}
                            </div>
                            <div className="col-6 text-right">
                                <label htmlFor="cert" className="btn mt-2 btn-sm btn-outline-primary">+ Add /
                                    Replace</label>
                            </div>
                        </div>
                        <hr className="my-3 hr-color"/>
                        <div className="item row">
                            <div className="col-6">
                                <input type="file"
                                       onChange={(e) => onChangeFileUpload(e, "other", setOther, setOtherOld)}
                                       className="d-none"
                                       id="ref"/>
                                <h5 className="d-flex main-color">Reference Letters <span
                                    className={`ms-2 ${otherOld === "" ? "text-danger" : "color-pr"}`}><AiOutlineInfoCircle/></span>
                                </h5>
                                <p className="mt-2 mb-0 text-xxs text-muted">
                                    Reference letters from employers
                                </p>
                                {otherOld.file_url !== undefined ?
                                    <>
                                        <span
                                            className="mr-4 text-xxs">{otherOld.file_url.split("/")[otherOld.file_url.split("/").length - 1]}</span>
                                        <a href={`${otherOld !== "" ? otherOld.file_url : "#"}`}
                                           target={`${otherOld !== "" ? "_blank" : "_self"}`}
                                           className={`${otherOld !== "" ? "color-pr text-xxs" : "disabled"}`}>Click to
                                            view</a>
                                    </>
                                    : ""}
                            </div>
                            <div className="col-6 text-right">
                                <label htmlFor="ref" className="btn mt-2 btn-sm btn-outline-primary">+ Add /
                                    Replace</label>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    } else {
        return loader("20rem")
    }
};

export default Documents;