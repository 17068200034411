import React from 'react';
import NoJobs from "./alerts/NoJobs";
import {AiFillCheckCircle} from "react-icons/ai";
import {loader} from "../config";
import {RequestGet} from "../employer/Request";
import ViewJob from "../modals/ViewJob";
import Moment from "react-moment";
import "./design.scss";
import ProfileStatus from "./alerts/ProfileStatus";

const Applications = () => {
    const [data, setData] = React.useState(null)
    const [profile, setProfile] = React.useState(null);
    React.useEffect(() => {
        if (data === null) {
            RequestGet('user/profile').then((response) => {
                if (response.preference == null) {
                    window.location.href = '/pref'
                }
                if (response.profile == null) {
                    window.location.href = '/step'
                }
                setProfile(response)
            })
            RequestGet("user/applications-usr").then((items) => setData(items))
        }
    }, [data])
    if (data !== null && profile !== null) {
        return (
            <>
                <ProfileStatus profile={profile}/>
                <div className="content p-2">
                    <h4 className="heading text-main my-2">Job Applications</h4>
                    {data.length > 0 ?
                        <>
                            <div className="mt-4 bg-gray-200 gap-2 py-2 grid grid-cols-10">
                                <div className="lg:col-span-6 col-span-5 text-gray-500 text-sm pl-8">
                                    {data.length} Jobs Found
                                </div>
                                <div className="text-gray-500 jobItemBarNone text-xs">Applies on</div>
                                <div className="text-gray-500 jobItemBarNone text-xs">Status</div>
                                <div className="text-gray-500 jobItemBarNone text-xs">Location</div>
                                <div className="text-gray-500 jobItemBarNone text-xs">Deadline</div>
                            </div>
                            {data.length > 0 && data.map((item) =>
                                <div
                                    className="mt-2 jobItems gap-2 py-2 border-b border-gray-300 items-center grid grid-cols-5 md:grid-cols-10">
                                    <div className="md:col-span-6 col-span-5 text-gray-700 text-sm pl-0 lg:pl-8">
                                        <h1 className="text-main2 mb-2">
                                            <ViewJob id={item.id} title={item.title} profile={profile}/>
                                        </h1>
                                        <div
                                            className="flex salary_range_main_sm_con items-center gap-3 text-xs text-gray-600">
                                            <p className="flex items-center gap-1 italic">{item.name} {item.is_verified == 1 &&
                                            <AiFillCheckCircle className="w-3 h-3 ml-1 text-pr"/>}</p>
                                            <p className="flex salary_range_main items-center gap-1 italic">
                                                <Moment format="DD MMM YYYY">{item.created_at}</Moment>
                                            </p>
                                            <p className="flex salary_range_main_sm items-center gap-1 italic">{item.status === null ? "Applied" : "N/A"}</p>
                                            <p className="flex salary_range_main_sm items-center gap-1 italic">{item.location}</p>
                                            <p className="flex salary_range_main_sm items-center gap-1 italic">
                                                <Moment format="DD MMM YYYY">{item.expired_at}</Moment>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="text-gray-700 salary_range_lg text-xs">
                                        <Moment format="DD MMM YYYY">{item.created_at}</Moment>
                                    </div>
                                    <div
                                        className="text-gray-600 salary_range_sm text-xs">
                                        {parseInt(item.is_favorite) === 1 ? "Shortlisted" : "Applied"}
                                    </div>
                                    <div className="text-gray-600 salary_range_sm text-xs">{item.location}</div>
                                    <div className="text-gray-600 salary_range_sm text-xs">
                                        <Moment format="DD MMM YYYY">{item.expired_at}</Moment>
                                    </div>
                                </div>
                            )}
                        </>
                        : <NoJobs/>}
                </div>
            </>
        );
    } else {
        return loader("20rem")
    }
};

export default Applications;
