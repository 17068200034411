import React from 'react';
import {WorkOutlineOutlined} from "@mui/icons-material";
import {RequestG} from "../routes/Request";
import {loader} from "../config";
import {RequestGet, RequestM} from "../employer/Request";
import AddSkills from "./modal/AddSkills";
import {IconButton} from "@mui/material";
import {AiFillDelete} from "react-icons/ai";
import EditSkill from "./modal/EditSkill";
import ProfileStatus from "./alerts/ProfileStatus";

const Skills = () => {
    const [profile, setProfile] = React.useState(null);
    const [fields, setFields] = React.useState(false);
    const [data, setData] = React.useState(false);
    React.useEffect(() => {
        if (fields === false) {
            RequestGet('user/skills-usr').then((response) => {
                setData(response);
            })
            RequestGet('user/profile').then((response) => {
                if (response.preference == null) {
                    window.location.href = '/pref'
                }
                if (response.profile == null) {
                    window.location.href = '/step'
                }
                setProfile(response);
            })
            RequestG('fields').then((response) => {
                setFields(response)
            })
        }
    }, [fields])
    const year = new Date().getFullYear();
    let list = []
    for (let i = 2000; i <= year; i++) {
        list.push(i)
    }
    const deleteData = (id) => {
        RequestM('job-seeker/skills/' + id, new FormData(), 'delete').then((response) => {
            setFields(false)
        })
    }
    if (fields !== false && profile !== null) {
        return (
            <>
                <ProfileStatus profile={profile}/>
                <div className="content p-2">
                    <div className="d-flex py-2 px-2">
                        <WorkOutlineOutlined className="me-2 text-two"/>
                        <h4 className="heading text-main my-0">Skills</h4>
                    </div>
                    <div className="px-2 py-2">
                        <div className="text-right">
                            {profile !== null ?
                                <AddSkills data={data} profile={profile.profile}
                                           setFields={setFields}/> : "Please complete profile"}
                        </div>
                        <div className='grid grid-cols-1 lg:grid-cols-7'>
                            <div className='lg:col-span-3'>
                                {profile !== null && data !== null && data.length > 0 && data.map((item, index) =>
                                    <div className='pb-4 rating'>
                                        <p className='my-1 font-semibold text-gray-700'>{item.title}</p>
                                        <div className={`relative h-2 bg-gray-300 w-100`}>
                                            <div className={`absolute bg-pr bar-${item.level}`}
                                                 style={{height: "100%"}}/>
                                        </div>
                                        <div className='lg:col-span-1'>
                                            <EditSkill
                                                setData={setData}
                                                setFields={setFields} item={item} profile={profile.profile}
                                                data={data}/>
                                            {index > 4 &&
                                            <IconButton aria-label="delete" onClick={() => deleteData(item.id)}
                                                        size="small"><AiFillDelete/>
                                            </IconButton>}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    } else {
        return loader("20rem")
    }
};

export default Skills;
