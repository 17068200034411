import React from 'react';

const ProfileStatus = ({profile}) => {
    let profileStatus = 0;
    if (profile.resume !== null) {
        profileStatus += 20;
    }
    if (profile.experience !== null && profile.experience.length > 0) {
        profileStatus += 20;
    }
    if (profile.language !== null && profile.language.length > 0) {
        profileStatus += 20;
    }
    if (profile.qualification !== null && profile.qualification.length > 0) {
        profileStatus += 20;
    }
    if (profile.skill !== null && profile.skill.length > 0) {
        profileStatus += 20;
    }
    return (
        <>
            <div className='flex bg-white px-4 py-2 mb-2 items-center justify-between'>
                <h5 className='font-medium text-xl my-0 text-pr'>Your profile is {profileStatus}%
                    complete</h5>
                <div className='flex items-center gap-4'>
                    <div className='flex items-center gap-2'>
                        <h5 className='p-3 rounded-full profileStatusN bg-pr my-0 text-white font-medium'>{profile.applied}</h5>
                        <p className='text-gray-500 font-medium my-0'>Jobs Applied</p>
                    </div>
                    <div className='flex items-center gap-2'>
                        <h5 className='p-3 rounded-full profileStatusN bg-pr my-0 text-white font-medium'>{profile.shortlist}</h5>
                        <p className='text-gray-500 font-medium my-0'>Jobs Short Listed</p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ProfileStatus;
