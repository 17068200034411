import React from 'react'
import {Link} from "react-router-dom";
import {Pagination, TextField} from "@mui/material";
import {loader} from "../config";
import {RequestG} from "../routes/Request";
import {BsSearch} from 'react-icons/bs';

const Employers = () => {
    const [search, setSearch] = React.useState("");
    const [sort, setSort] = React.useState("");
    const [page, setPage] = React.useState(1);
    const [data, setData] = React.useState(false);
    const [update, setUpdate] = React.useState(true);
    React.useEffect(() => {
        RequestG('filter-employers?search=' + search + '&sort=' + sort + '&page=' + page).then((response) => {
            setData(response)
            setUpdate(false)
        })
    }, [search, sort, page])
    const onSubmitData = (e) => {
        e.preventDefault();
    }
    if (update === false && data !== false) {
        return (
            <div className="min-h-screen" style={{paddingTop: "5rem"}}>
                <div className="grid pt-4 container grid-cols-1 lg:grid-cols-3 gap-y-3 lg:gap-3">
                    <div className="flex items-center flex-col gap-3 w-full">
                        <div className="bg-white grid grid-cols-8 py-3 px-2 items-center text-center w-full">
                            <button className={`font-medium text-gray-600 mb-3 ${sort === 'a' ? 'text-primary' : ''}`}
                                    onClick={(e) => setSort('a')}>A
                            </button>
                            <button className={`font-medium text-gray-600 mb-3 ${sort === 'b' ? 'text-primary' : ''}`}
                                    onClick={(e) => setSort('b')}>B
                            </button>
                            <button className={`font-medium text-gray-600 mb-3 ${sort === 'c' ? 'text-primary' : ''}`}
                                    onClick={(e) => setSort('c')}>C
                            </button>
                            <button className={`font-medium text-gray-600 mb-3 ${sort === 'd' ? 'text-primary' : ''}`}
                                    onClick={(e) => setSort('d')}>D
                            </button>
                            <button className={`font-medium text-gray-600 mb-3 ${sort === 'e' ? 'text-primary' : ''}`}
                                    onClick={(e) => setSort('e')}>E
                            </button>
                            <button className={`font-medium text-gray-600 mb-3 ${sort === 'f' ? 'text-primary' : ''}`}
                                    onClick={(e) => setSort('f')}>F
                            </button>
                            <button className={`font-medium text-gray-600 mb-3 ${sort === 'g' ? 'text-primary' : ''}`}
                                    onClick={(e) => setSort('g')}>G
                            </button>
                            <button className={`font-medium text-gray-600 mb-3 ${sort === 'h' ? 'text-primary' : ''}`}
                                    onClick={(e) => setSort('h')}>H
                            </button>
                            <button className={`font-medium text-gray-600 mb-3 ${sort === 'i' ? 'text-primary' : ''}`}
                                    onClick={(e) => setSort('i')}>I
                            </button>
                            <button className={`font-medium text-gray-600 mb-3 ${sort === 'j' ? 'text-primary' : ''}`}
                                    onClick={(e) => setSort('j')}>J
                            </button>
                            <button className={`font-medium text-gray-600 mb-3 ${sort === 'k' ? 'text-primary' : ''}`}
                                    onClick={(e) => setSort('k')}>K
                            </button>
                            <button className={`font-medium text-gray-600 mb-3 ${sort === 'l' ? 'text-primary' : ''}`}
                                    onClick={(e) => setSort('l')}>L
                            </button>
                            <button className={`font-medium text-gray-600 mb-3 ${sort === 'm' ? 'text-primary' : ''}`}
                                    onClick={(e) => setSort('m')}>M
                            </button>
                            <button className={`font-medium text-gray-600 mb-3 ${sort === 'n' ? 'text-primary' : ''}`}
                                    onClick={(e) => setSort('n')}>N
                            </button>
                            <button className={`font-medium text-gray-600 mb-3 ${sort === 'o' ? 'text-primary' : ''}`}
                                    onClick={(e) => setSort('o')}>O
                            </button>
                            <button className={`font-medium text-gray-600 mb-3 ${sort === 'p' ? 'text-primary' : ''}`}
                                    onClick={(e) => setSort('p')}>P
                            </button>
                            <button className={`font-medium text-gray-600 mb-3 ${sort === 'q' ? 'text-primary' : ''}`}
                                    onClick={(e) => setSort('q')}>Q
                            </button>
                            <button className={`font-medium text-gray-600 mb-3 ${sort === 'r' ? 'text-primary' : ''}`}
                                    onClick={(e) => setSort('r')}>R
                            </button>
                            <button className={`font-medium text-gray-600 mb-3 ${sort === 's' ? 'text-primary' : ''}`}
                                    onClick={(e) => setSort('s')}>S
                            </button>
                            <button className={`font-medium text-gray-600 mb-3 ${sort === 't' ? 'text-primary' : ''}`}
                                    onClick={(e) => setSort('t')}>T
                            </button>
                            <button className={`font-medium text-gray-600 mb-3 ${sort === 'u' ? 'text-primary' : ''}`}
                                    onClick={(e) => setSort('u')}>U
                            </button>
                            <button className={`font-medium text-gray-600 mb-3 ${sort === 'v' ? 'text-primary' : ''}`}
                                    onClick={(e) => setSort('v')}>V
                            </button>
                            <button className={`font-medium text-gray-600 mb-3 ${sort === 'w' ? 'text-primary' : ''}`}
                                    onClick={(e) => setSort('w')}>W
                            </button>
                            <button className={`font-medium text-gray-600 mb-3 ${sort === 'x' ? 'text-primary' : ''}`}
                                    onClick={(e) => setSort('x')}>X
                            </button>
                            <button className={`font-medium text-gray-600 mb-3 ${sort === 'y' ? 'text-primary' : ''}`}
                                    onClick={(e) => setSort('y')}>Y
                            </button>
                            <button className={`font-medium text-gray-600 mb-3 ${sort === 'z' ? 'text-primary' : ''}`}
                                    onClick={(e) => setSort('z')}>Z
                            </button>
                        </div>
                        <form onSubmit={(e) => onSubmitData(e)} className="bg-white flex items-center w-full searchII">
                            <button type="submit" className="bg-pr px-3 cursor-pointer w-16 search_icon text-white">
                                <BsSearch className=""/>
                            </button>
                            <input type="text" onChange={(e) => setSearch(e.target.value)}
                                   className="form-control searchInput"/>
                        </form>
                    </div>
                    <div className="col-span-2 grid grid-cols-1">
                        {
                            data.data !== undefined && data.data !== null && data.data.length > 0 ?
                                <>
                                    {
                                        data.data.map((data) => {
                                            return <Link to={`/viewCompany/${data.profile.id}`}
                                                         className="px-3 py-2 border bg-white border-gray-100 text-gray-500  text-sm">{data.profile.name}</Link>
                                        })
                                    }
                                </>
                                : ""
                        }

                        <div className="px-3 py-2 mt-1 text-center">
                            <Pagination count={Math.round(data.total / 20)}
                                        onClick={(e) => setPage(e.target.textContent)}
                                        hidePrevButton hideNextButton
                                        color="primary"
                                        className="d-flex justify-content-center"/>
                        </div>
                    </div>
                </div>
            </div>
        )
    } else {
        return (
            <div className="container" style={{paddingTop: "5rem"}}>
                {loader("30rem")}
            </div>
        )
    }
}

export default Employers
