import React from 'react';
import {WorkOutlineOutlined} from "@mui/icons-material";
import {Button, FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import {RequestG} from "../routes/Request";
import {alertOptions, loader, sortedFields} from "../config";
import {RequestGet, RequestM} from "../employer/Request";
import {UserContext} from "../routes/Private";
import {toast} from "react-toastify";
import ProfileStatus from "./alerts/ProfileStatus";

const Preference = () => {
    const {user} = React.useContext(UserContext)
    const [category, setCategory] = React.useState("");
    const [salary, setSalary] = React.useState("");
    const [location, setLocation] = React.useState("");
    const [type, setType] = React.useState("");
    const [sector, setSector] = React.useState("");
    const [preference, setPreference] = React.useState(null);
    const [fields, setFields] = React.useState(false);
    const [profile, setProfile] = React.useState(null);
    React.useEffect(() => {
        if (fields === false) {
            RequestGet('user/profile').then((response) => {
                if (response.preference == null) {
                    window.location.href = '/pref'
                }
                if (response.profile == null) {
                    window.location.href = '/step'
                }
                setProfile(response)
            })
            RequestGet('user/job-seeker-preference-usr').then((data) => {
                if (data.status === 200) {
                    setCategory(data.data.work_category)
                    setSalary(data.data.salary_range)
                    setLocation(data.data.preferred_location)
                    setType(data.data.job_type)
                    setSector(data.data.work_sector)
                }
                setPreference(data)
            })
            RequestG('fields').then((response) => {
                setFields(response)
            })
        }
    }, [fields])
    const formSubmit = (e) => {
        e.preventDefault()
        let formdata = new FormData();
        formdata.append("user_id", user.id);
        formdata.append("preferred_location", location);
        formdata.append("work_sector", sector);
        formdata.append("work_category", category);
        formdata.append("salary_range", salary);
        formdata.append("job_type", type);
        if (preference.status === 200) {
            formdata.append("id", preference.data.id);
            formdata.append("_method", "put");
            RequestM('job-seeker/preference/' + preference.data.id, formdata).then((response) => toast.success(response.message, alertOptions))
        } else {
            RequestM('job-seeker/preference', formdata).then((response) => toast.success(response.message, alertOptions))
        }
    }
    if (fields !== false && preference !== null && profile !== null) {
        return (
            <>

                <ProfileStatus profile={profile}/>
                <div className="content p-2">
                    <div className="d-flex py-2 px-2">
                        <WorkOutlineOutlined className="me-2 text-two"/>
                        <h4 className="heading text-main my-0">My Preference</h4>
                    </div>
                    <form className="px-2 py-2" onSubmit={(e) => formSubmit(e)}>
                        <div className="row">
                            <div className="col-lg-6">
                                <FormControl required className="mt-3" fullWidth>
                                    <InputLabel id="demo-simple-select-label">Job Category</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={category} onChange={(e) => setCategory(e.target.value)}
                                        label="Job Category">
                                        {sortedFields("work_categories", fields).map((data) => {
                                            return <MenuItem key={data.value}
                                                             value={data.value}>{data.value}</MenuItem>;
                                        })}
                                    </Select>
                                </FormControl>
                                <FormControl required className="mt-3" fullWidth>
                                    <InputLabel id="demo-simple-select-label">Work Location</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={location} onChange={(e) => setLocation(e.target.value)}
                                        label="Work Location">
                                        {sortedFields("preferred_locations", fields).map((data) => {
                                            return <MenuItem key={data.value}
                                                             value={data.value}>{data.value}</MenuItem>;
                                        })}
                                    </Select>
                                </FormControl>
                                <FormControl required className="mt-3" fullWidth>
                                    <InputLabel id="demo-simple-select-label">Sector</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={sector} onChange={(e) => setSector(e.target.value)}
                                        label="Sector">
                                        {fields.map((data) => {
                                            if (data.field === 'work_sectors') {
                                                return <MenuItem key={data.value}
                                                                 value={data.value}>{data.value}</MenuItem>;
                                            }
                                        })}
                                    </Select>
                                </FormControl>
                            </div>
                            <div className="col-lg-6">
                                <FormControl required className="mt-3" fullWidth>
                                    <InputLabel id="demo-simple-select-label">Salary Range</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={salary} onChange={(e) => setSalary(e.target.value)}
                                        label="Sector">
                                        {fields.map((data) => {
                                            if (data.field === 'salary_range') {
                                                return <MenuItem key={data.value}
                                                                 value={data.value}>{data.value}</MenuItem>;
                                            }
                                        })}
                                    </Select>
                                </FormControl>
                                <FormControl required className="mt-3" fullWidth>
                                    <InputLabel id="demo-simple-select-label">Job Type</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={type} onChange={(e) => setType(e.target.value)}
                                        label="Job Type">
                                        {fields.map((data) => {
                                            if (data.field === 'job_types') {
                                                return <MenuItem key={data.value}
                                                                 value={data.value}>{data.value}</MenuItem>;
                                            }
                                        })}
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                        <Button color="info" type="submit" className="text-capitalize mt-3"
                                variant={"contained"}>Save Preference</Button>
                    </form>
                </div>
            </>
        );
    } else {
        return loader("20rem")
    }
};

export default Preference;
