import React from 'react';
import {useParams, Redirect} from "react-router-dom";
import {apiURI} from "../config";
import Header from "../layouts/Header";
import {ToastContainer} from "react-toastify";
import {Container} from "react-bootstrap";
import Footer from "../layouts/Footer";

const Verify = () => {
    const {token} = useParams();
    const {userId} = useParams();
    const [result, setResult] = React.useState(1);
    if (localStorage.getItem('token')) {
        let myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

        let requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        if (result === 1) {
            fetch(apiURI + "verify-email/" + userId + "/" + token, requestOptions)
                .then(response => response.json())
                .then(ress => setResult(ress))
                .catch(error => console.log('error', error));
        }
        if (result !== 1) {
            setTimeout(() => {
                window.location.href = '/'
            }, 3000)
            return (
                <>
                    <Header authentication={localStorage.getItem('token') ? localStorage.getItem('token') : false}/>
                    <Header authentication={localStorage.getItem('token')}/>
                    <ToastContainer/>
                    <div className="company">
                        <Container className="py-5">
                            <div className="row justify-content-center align-items-center" style={{height: "60vh"}}>
                                <div className="col-lg-4 pt-5 mx-auto">
                                    <div className="card">
                                        <div className="card-body rounded text-center shadow">
                                            {result.status === 200 ?
                                                <>
                                                    <h4 className="mb-3">{result.message}</h4>
                                                </>
                                                :
                                                <>
                                                    <h4 className="mb-3">{result.message}</h4>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Container>
                    </div>
                    <Footer/>
                </>
            );
        } else {
            return (
                <>
                    Loading...
                </>
            )
        }
    } else {
        return <Redirect to={"/login"}/>
    }
};

export default Verify;
