import React from 'react';
import {WorkOutlineOutlined} from "@mui/icons-material";
import {Link} from 'react-router-dom';
import Email from "./warning/Email";
import {ComContext} from "../routes/Employer";
import VerifyIcon from "./warning/VerifyIcon";
import {RequestGet} from "./Request";
import {alertOptions, loader} from "../config";
import Moment from "react-moment";
import moment from 'moment';
import {toast} from "react-toastify";
import YaSubscription from "./warning/YaSubscription";
import NoSubscription from "./warning/NoSubscription";
import {Pagination} from "@mui/material";

const Jobs = () => {
    const {user} = React.useContext(ComContext);
    const [data, setData] = React.useState(false);
    const [update, setUpdate] = React.useState(0);
    const [companyData, setCompanyData] = React.useState(null);
    const [page, setPage] = React.useState(1);
    const [status, setStatus] = React.useState(1);
    const [search, setSearch] = React.useState("");
    React.useEffect(() => {
        RequestGet('employ/company').then((response) => {
            setCompanyData(response)
        })
        RequestGet('employ/jobs?page=' + page + "&search=" + search + "&status=" + status).then((response) => {
            setData(response)
        })
        setUpdate(0)
    }, [update, page, search, status])
    const getDaysBetweenDates = function (startDate) {
        let now = startDate.clone(), dates = [];

        while (now.isSameOrBefore(moment(new Date()))) {
            dates.push(now.format('DD'));
            now.add(1, 'days');
        }
        return dates.length;
    };
    const forceClose = (id) => {
        RequestGet('employ/force-close/' + id).then((response) => {
            setUpdate(1)
            toast.success("Success", alertOptions);
        })
    }

    const pagination = (e) => {
        if (parseInt(data.last) > page) {
            setPage(data.last);
        } else {
            setPage(e.target.textContent)
        }
        window.scrollTo(0, 0);
    }
    if (data !== false && companyData !== null) {
        return (
            <>
                <Email/>
                {companyData.annual !== null || companyData.payper !== null ? <YaSubscription data={companyData}/> :
                    <NoSubscription/>}
                <div className="content p-2">
                    <div className="d-flex py-2">
                        <WorkOutlineOutlined className="me-2 text-two"/>
                        <h4 className="heading text-main my-0">Jobs</h4>
                    </div>
                    <div className="text-right mt-3">
                        <div className="d-flex justify-content-between">
                            <div className="form-group d-block">
                                <input type="text" onChange={(e) => setSearch(e.target.value)}
                                       className="form-control text-sm"
                                       style={{fontSize: '.9rem'}} placeholder="Search..."/>
                            </div>
                            <div className="form-group d-block">
                                <select style={{width: '8rem', fontSize: '.9rem'}} name="" id=""
                                        value={status} onChange={(e) => setStatus(e.target.value)}
                                        className="form-select mx-1 py-1 px-2">
                                    <option value={1}>All</option>
                                    <option value={4}>Active</option>
                                    <option value={2}>Pending</option>
                                    <option value={3}>Expired</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    {data.data === null ?
                        <div className="my-3">
                            <p className="mb-3 text-two">No results found</p>
                            <Link to='/post-job' className="pBtn text-decoration-none py-2 px-3 text-white">Post
                                a
                                Job</Link>
                        </div>
                        : ""}
                    {user.email_verified_at !== null && data.data !== null ?
                        <>
                            <div className="jobsContent my-2">
                                {data.data.length > 0 ?
                                    (
                                        data.data.map((item) =>
                                            <div className="bg-light content pt-3 pb-2 row mx-0 my-3">
                                                <div className="col-md-9">
                                                    <h4 className="mb-4">{item.job.title}</h4>
                                                    <span className="status me-4 text-main">
                                                        {
                                                            getDaysBetweenDates(moment(item.job.expired_at)) > 0 ?
                                                                <span className="text-danger font-medium">
                                                                    Expired!
                                                                </span>
                                                                :
                                                                <>
                                                                    {parseInt(item.job.approved) === 1 ? "Published" : "Pending"}
                                                                </>
                                                        }
                                                    </span>

                                                    <Link to={`/job-applications/${item.job.id}`}
                                                          className="me-4 text-decoration-none text-main">
                                                        <span className="mr-2">{item.applications.length}</span>
                                                        Applications
                                                    </Link>
                                                    <Link to={`/edit-job/${item.job.id}`}
                                                          className="me-4 text-decoration-none text-main">
                                                        Edit Job
                                                    </Link>
                                                    <a href={`/job/${item.job.id}`} target="_blank"
                                                       className="me-4 text-decoration-none text-main">
                                                        View Job
                                                    </a>
                                                    {
                                                        getDaysBetweenDates(moment(item.job.expired_at)) > 0 ?
                                                            <Link to={`/repost-job/${item.job.id}`}
                                                                  className="me-4 text-decoration-none text-main">
                                                                Repost
                                                            </Link>
                                                            :
                                                            <button onClick={(e) => forceClose(item.job.id)}
                                                                    className="me-4 text-xs text-xstext-decoration-none text-main">
                                                                Force Close
                                                            </button>
                                                    }
                                                </div>
                                                <div className="col-md-3 rightContent text-md-right">
                                                    <p>Posted on <Moment
                                                        format="DD MMM YYYY">{item.job.created_at}</Moment></p>
                                                    {
                                                        getDaysBetweenDates(moment(item.job.expired_at)) > 0 ?
                                                            <p className="postDate font-medium">
                                                                <span className="text-danger">Expired on <Moment
                                                                    format="DD MMM YYYY">{item.job.expired_at}</Moment></span>
                                                            </p>
                                                            :
                                                            <p className="postDate font-medium">Closing on <Moment
                                                                format="DD MMM YYYY">{item.job.expired_at}</Moment>
                                                            </p>
                                                    }

                                                </div>
                                            </div>
                                        )
                                    ) : ""}
                                <div className="py-3 text-center">
                                    <Pagination
                                        count={Math.ceil(data.total / 20)}
                                        onClick={(e) => pagination(e)}
                                        hidePrevButton hideNextButton
                                        color="primary"
                                        className="d-flex justify-content-center"/>
                                </div>
                            </div>
                        </> : <VerifyIcon/>}
                </div>
            </>
        );
    } else {
        return loader("20rem")
    }
};

export default Jobs;
