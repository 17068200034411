import React from 'react';

const Terms = () => {
    return (
        <>
            <div className="container jobViewModal text-gray-500 pb-3" style={{paddingTop: "5rem"}}>
                <div className="py-3 border-bottom bg-white">
                    <h1 className="mb-0 h4 text-center">Terms of Use &amp; Privacy Policy</h1>
                </div>
                <div className="py-5 px-5 font-larger bg-white">
                    <p>JOBSICLE</p>
                    <p>These Terms of Use were updated effective April 2016.</p>
                    <p>Understanding your rights and responsibilities as a Jobsicle user</p>

                    <h3 className="h5"><b>Privacy Reminder</b></h3>

                    <p>Protect your personal information by never providing your credit card or bank account numbers,
                        details to prospective
                        employers.
                    </p>

                    <h3 className="h5"><b>Terms of Use</b></h3>

                    <p>This page states the Terms of Use ("Terms") under which you ("You") may use the Jobsicle Site and
                        the Services
                        (each as defined below). These Terms include the Jobsicle Privacy Policy, which is incorporated
                        into these
                        Terms.
                    </p>

                    <p>These Terms constitute a binding agreement between You and Jobsicle and are deemed accepted by
                        You each time
                        that You use or access Jobsicle Site. If You do not accept the Terms stated here, do not use the
                        Jobsicle
                        Site!
                    </p>

                    <h3 className="h5"><b>All Jobsicle Users Agree to NOT</b></h3>

                    <ol type="a" className="bracketed-list pl-0">
                        <li>post any content or material that promotes or endorses false or misleading information or
                            illegal activities,
                            or endorses or provides instructional information about illegal activities or other
                            activities prohibited
                            by these Terms, such as making or buying illegal weapons, drugs, violating someone’s
                            privacy, providing
                            or creating computer viruses or pirating media;
                        </li>

                        <li>post any resume or Profile or apply for any job on behalf of another party;
                        </li>
                        <li> defer any contact from an employer to any agent, agency, or other third party;
                        </li>
                        <li> set more than one copy of the same resume to public at any one time;
                        </li>
                        <li> share with a third party any login credentials to Jobsicle;
                        </li>
                        <li> access data not intended for You or logging into a server or account which You are not
                            authorized to access;
                        </li>
                        <li> post or submit to Jobsicle any incomplete, false or inaccurate biographical information or
                            information which
                            is not Your own;
                        </li>
                        <li> post content that contains restricted or password-only access pages, or hidden pages or
                            images;
                        </li>
                        <li> solicit passwords or personally identifiable information from other Users;
                        </li>
                        <li> delete or alter any material posted by any other person or entity;
                        </li>
                        <li> harass, incite harassment or advocate harassment of any group, company, or individual;
                        </li>
                        <li> send unsolicited mail or email, make unsolicited phone calls or send unsolicited faxes
                            promoting and/or advertising
                            products or services to any User, or contact any users that have specifically requested not
                            to be contacted
                            by You;
                        </li>
                        <li> attempt to interfere with service to any User, host or network, including, without
                            limitation, via means
                            of submitting a virus to Jobsicle, overloading, "flooding", "spamming", "mailbombing" or
                            "crashing";
                        </li>
                        <li> promote or endorse an illegal or unauthorized copy of another person’s copyrighted work,
                            such by as providing
                            or making available pirated computer programs or links to them, providing or making
                            available information
                            to circumvent manufacture-installed copy-protect devices, or providing or making available
                            pirated music
                            or other media or links to pirated music or other media files; or
                        </li>
                        <li> use the Jobsicle Services for any unlawful purpose or any illegal activity, or post or
                            submit any content,
                            resume, or job posting that is defamatory, libelous, implicitly or explicitly offensive,
                            vulgar, obscene,
                            threatening, abusive, hateful, racist, discriminatory, of a menacing character or likely to
                            cause annoyance,
                            inconvenience, embarrassment, anxiety or could cause harassment to any person or include any
                            links to
                            pornographic, indecent or sexually explicit material of any kind, as determined by
                            Jobsicle’s discretion.
                        </li>
                    </ol>

                    <p className="my-3">Violations of system or network security may result in civil and/or criminal
                        liability. Jobsicle will investigate
                        occurrences which may involve such violations and may involve, and cooperate with, law
                        enforcement authorities
                        in prosecuting Users who are involved in such violations.</p>

                    <h3 className="h5"><b>Additional Terms Applicable to Employers.</b></h3>

                    <p className="">Employers are solely responsible for their postings on Jobsicle. Jobsicle is not to
                        be considered to be an employer
                        with respect to Your use of Jobsicle Site and Jobsicle shall not be responsible for any
                        employment decisions,
                        for whatever reason, made by any entity posting jobs on Jobsicle Site.</p>

                    <p className="">You understand and acknowledge that if You cancel Your employer account or Your
                        employer account is terminated,
                        all Your account information from Jobsicle, including saved resumes, network contacts, and email
                        mailing
                        lists, will be marked as deleted in and may be deleted from Jobsicle’s databases. Information
                        may continue
                        to be available for some period of time because of delays in propagating such deletion through
                        Jobsicle’s
                        web servers.</p>

                    <p>You agree NOT to use any information on TalentPool for any purposes other than hiring. You agree
                        that
                        You will NOT share any candidate’s personal, profile or resume data with any other third party
                        outside
                        your company or outside the boundaries of your human resources department.</p>

                    <p className="">In order to protect our Jobsicle Users from commercial advertising or solicitation,
                        Jobsicle reserves the right
                        to restrict the number of e-mails which an employer may send to Users to a number which Jobsicle
                        deems appropriate
                        in its sole discretion. You shall use the Jobsicle Networking and Profiles in accordance with
                        all applicable
                        privacy and data protection laws.</p>

                    <h3 className="h5 mt-2 mb-4"><b>Job postings</b></h3>

                    <p className="font-weight-bold">A Job posting may not contain:
                    </p>

                    <ol className="bracketed-list pl-0">
                        <li> any hyperlinks, other than those specifically authorized by Jobsicle;</li>
                        <li> misleading, unreadable, or “hidden” keywords, repeated keywords or keywords that are
                            irrelevant to the job opportunity being
                            presented, as determined in Jobsicle’s reasonable discretion;
                        </li>
                        <li> the names, logos or trademarks of unaffiliated companies other than those of your customer
                            save where expressly agreed by
                            Jobsicle;
                        </li>
                        <li> the names of colleges, cities, states, towns or countries that are unrelated to the
                            posting;
                        </li>
                        <li> more than one job or job description, more than one location, or more than one job
                            category, unless the product so allows;
                        </li>
                        <li> inaccurate, false, or misleading information; and</li>
                        <li> material or links to material that exploits people in a sexual, violent or other manner, or
                            solicits personal information
                            from anyone under 18.
                        </li>
                    </ol>

                    <h3 className="h5 my-4"><b>You may not use Your Jobsicle job posting or Profiles to:</b></h3>

                    <ol className="bracketed-list pl-0">
                        <li>
                            post jobs in a manner that does not comply with applicable local, national and international
                            laws, including but not limited
                            to laws relating to labor and employment, equal employment opportunity and employment
                            eligibility requirements, data privacy,
                            data access and use, and intellectual property;
                        </li>
                        <li> post jobs that require citizenship of any particular country or lawful
                            permanent residence in a country as a condition of employment, unless otherwise required in
                            order to comply with law, regulations,
                            executive order, or federal, state or local government contract;
                        </li>
                        <li> post jobs that include any screening requirement or
                            criterion in connection with a job posting where such requirement or criterion is not an
                            actual and legal requirement of
                            the posted job;
                        </li>
                        <li> with respect to Profiles, determine a consumer’s eligibility for:</li>
                        <li> credit or insurance for person,
                            family, or household purposes; (ii) employment; or (iii) a government license of benefit.
                        </li>
                        <li>post jobs or other advertisements
                            for competitors of Jobsicle or post jobs or other content that contains links to any site
                            competitive with Jobsicle;
                        </li>
                        <li>
                            sell, promote or advertise products or services;
                        </li>
                        <li> post any franchise, pyramid scheme, “club membership”, distributorship,
                            multi-level marketing opportunity, or sales representative agency arrangement;
                        </li>
                        <li> post any business opportunity that requires
                            an up front or periodic payment or requires recruitment of other members, sub-distributors
                            or sub-agents;
                        </li>
                        <li> post any business
                            opportunity that pays commission only unless the posting clearly states that the available
                            job pays commission only and clearly
                            describes the product or service that the job seeker would be selling;
                        </li>
                        <li> promote any opportunity that does not represent
                            bona fide employment.
                        </li>
                        <li> advertise sexual services or seek employees for jobs of a sexual nature;</li>
                        <li> request the use of
                            human body parts or the donation of human parts, including, without limitation, reproductive
                            services such as egg donation
                            and surrogacy;
                        </li>
                        <li> endorse a particular political party, political agenda, political position or issue;</li>
                        <li> promote a particular
                            religion;
                        </li>
                        <li> except where allowed by applicable law, post jobs which require the applicant to provide
                            information relating
                            to his/her (i) racial or ethnic origin (ii) political beliefs (iii) philosophical or
                            religious beliefs (iv) membership of
                            a trade union (v) physical or mental health (vi) sexual life (vii) the commission of
                            criminal offences or proceedings or
                            (vii) age.
                        </li>
                    </ol>

                    <p className="my-3">
                        Jobsicle reserves the right to remove any job posting or content from Jobsicle Site, which in
                        the reasonable exercise of
                        Jobsicle’s discretion, does not comply with the above Terms, or if any content is posted that
                        Jobsicle believes is not in
                        the best interest of Jobsicle.
                    </p>

                    <p className="my-3">
                        If at any time during Your use of the Jobsicle Services, You made a misrepresentation of fact to
                        Jobsicle or otherwise misled
                        Jobsicle in regards to the nature of Your business activities, Jobsicle will have grounds to
                        terminate Your use of the Jobsicle
                        Services.
                    </p>

                    <h3 className="my-4 h5"><b className="">Additional terms applicable to Job Seekers.</b></h3>

                    <p>When You register with Jobsicle Site, You will be asked to create an account and provide Jobsicle
                        with certain information
                        including, without limitation, a valid email address (Your “Information”).</p>

                    <p>Any Profile You submit must be accurate and describe You, an individual person. The Profile
                        requires standard fields to be
                        completed and you may not include in these fields any telephone numbers, street addresses, email
                        addresses or other means
                        of contacting You, other than Your last name and URLs.</p>

                    <p>You acknowledge and agree that You are solely responsible for the form, content and accuracy of
                        any resume or material contained
                        therein placed by You on the Jobsicle Sites.</p>

                    <p>Any Profile You submit must be accurate and describe You, an individual person. The Profile
                        requires standard fields to be completed where National ID number is optional. These profiles
                        and resumes will be viewable to verified and subscribed employers ONLY.</p>

                    <p>You agree that verified employers on Jobsicle can view your profile, resume information and
                        contact You in case of job openings or hiring.</p>

                    <p>Jobsicle reserves the right to offer third party services and products to You based on the
                        preferences that You identify
                        in Your registration and at any time thereafter or you have agreed to receive, such offers may
                        be made by Jobsicle or by
                        third parties. You understand and acknowledge that You have no ownership rights in Your account
                        and that if You cancel Your
                        Jobsicle account or Your Jobsicle account is terminated, all Your account information from
                        Jobsicle, including resumes, Profiles
                        and saved jobs will be marked as deleted in and may be deleted from Jobsicle’s databases and
                        will be removed from any public
                        area of the Jobsicle Site. Information may continue to be available for some period of time
                        because of delays in propagating
                        such deletion through Jobsicle’s web servers. In addition, third parties may retain saved copies
                        of Your Information.</p>

                    <p>Jobsicle reserves the right to delete Your account and all of Your Information after a
                        significant duration of inactivity</p>
                </div>
            </div>
        </>
    );
};

export default Terms;