import React from 'react';
import {FaRegBookmark, FaShareSquare} from "react-icons/fa";
import {BsBookmarkFill} from "react-icons/bs";
import ApplyOnline from "../modals/ApplyOnline";
import {Link} from "react-router-dom";
import Incomplete from "../modals/Incomplete";
import {RequestD, RequestM} from "../employer/Request";
import {alertOptions, rootURI} from "../config";
import {toast} from "react-toastify";

const ApplyItem = ({data, jobId, profile, save, setSave, applicationChecker}) => {
    const saveJob = () => {
        const formData = new FormData();
        formData.append('job_id', jobId)
        RequestM('job/save', formData).then((data) => setSave(true))
    }
    const removeJob = () => {
        RequestD('job/save/' + jobId).then((data) => setSave(false))
    }
    const copyText = () => {
        navigator.clipboard.writeText(rootURI + "/job/" + jobId);
        toast.success("Link copied", alertOptions)
    }
    return (
        <>
            <FaShareSquare onClick={copyText}
                           className="h-8 w-8 cursor-pointer text-gray-500"/>
            {profile !== undefined && profile !== false && profile.profile !== null ?
                <>
                    {save === false ?
                        <FaRegBookmark onClick={() => saveJob()}
                                       className="h-8 w-8 cursor-pointer text-gray-500"/>
                        : <BsBookmarkFill onClick={() => removeJob()}
                                          className="h-8 w-8 cursor-pointer text-gray-500"/>}
                </> : <ApplyOnline status={true} id={jobId}/>
            }
            {parseInt(data.prevent_online_application) !== 1 && profile !== undefined && profile !== false && profile.profile !== null ?
                <>
                    {
                        profile.resume !== null && profile.experience !== null && profile.experience.length > 0 && profile.language !== null && profile.language.length > 0 && profile.qualification !== null && profile.qualification.length > 0 && profile.skill !== null && profile.skill.length > 0
                            ?
                            <>
                                {parseInt(data.prevent_international_applicants) === 1 ?
                                    profile.profile.nationality == "Maldives" ?
                                        <>
                                            {applicationChecker !== null ?
                                                parseInt(applicationChecker.status) === 200
                                                    ?
                                                    <button onClick={() => alert("Already Applied")}
                                                            className="bg-pr text-white py-1 rounded-sm px-8">Applied</button>
                                                    :
                                                    <Link to={"/apply-job/" + jobId}
                                                          className="bg-pr text-white py-1 rounded-sm px-8">Apply
                                                        Online</Link>
                                                : <ApplyOnline/>}

                                        </>
                                        : "Country Blocked"
                                    : <>
                                        {applicationChecker !== null ?
                                            parseInt(applicationChecker.status) === 200
                                                ?
                                                <button onClick={() => alert("Already Applied")}
                                                        className="bg-pr text-white py-1 rounded-sm px-8">Applied</button>
                                                :
                                                <Link to={"/apply-job/" + jobId}
                                                      className="bg-pr text-white py-1 rounded-sm px-8">Apply
                                                    Online</Link>
                                            : <ApplyOnline/>}
                                    </>}
                            </>
                            : <Incomplete id={jobId}/>
                    }
                </>
                : ""}
        </>
    );
};

export default ApplyItem;