import React from 'react';
import Moment from "react-moment";
import {BsBagCheck, BsFileEarmarkMedical, BsLaptop} from "react-icons/bs";
import {FiCodesandbox} from "react-icons/fi";
import {AiOutlineGlobal} from "react-icons/ai";
import {mainURI, thumbnailsURI} from "../../config";
import {MdCameraAlt} from "react-icons/md";

const ProfileItem = ({data}) => {
    return (
        <>
            <div className='container px-0 min-h-screen'>
                <div className='p-4 pt-0 bg-white'>
                    {data.profile &&
                    <div className='grid grid-cols-1 lg:grid-cols-2 divide-x border-b-2 py-4'>
                        {data.profile &&
                        <div className='row'>
                            <div className="col-lg-12 col-xl-4 profileImg3">
                                <label htmlFor="logo"
                                       className={`placeholder ${!data.profile.picture ? "active" : ""}`}>
                                    {data.profile.picture &&
                                    <img className="imgPreview rounded" id="logoImg"
                                         src={thumbnailsURI + data.profile.picture}
                                         alt=""/>}
                                    {!data.profile.picture &&
                                    <span className="text text-capitalize">{data.profile.name.charAt(0)}</span>}
                                </label>
                            </div>
                            <div className='col-lg-12 col-xl-7 d-flex flex-column'>
                                <h1 className='text-gray-500 text-2xl mb-1 mt-0'>{data.profile.name}</h1>
                                <p className='text-gray-450 mb-1 text-sm'>+{data.profile.phone}</p>
                                <p className='text-gray-450 mb-1 text-sm'>{data.profile.email}</p>
                                <p className='text-gray-450 mb-1 text-sm'>Living in, {data.profile.address}</p>
                                <p className='text-gray-450 mb-1 text-sm'>{data.profile.employment_status}</p>
                            </div>
                        </div>
                        }
                        <div className='pl-4 flex justify-content-center'>
                            <div className="w-50">
                                <p className='text-gray-450 my-1 text-sm'>Gender: {data.profile.gender}</p>
                                <p className='text-gray-450 my-1 text-sm'>Nationality: {data.profile.nationality}</p>
                                <p className='text-gray-450 my-1 text-sm'>
                                    DOB: <Moment format="DD MMM YYYY">{data.profile.dob}</Moment>
                                </p>
                            </div>
                            <div className='w-50'>
                                <p className='text-gray-400 text-sm text-xxs'>Last Updated:
                                    <Moment format="DD MMM YYYY">{data.profile.updated_at}</Moment>
                                </p>
                            </div>
                        </div>
                    </div>
                    }
                    {data.resume &&
                    <div className='py-3 border-b-2'>
                        <h1 className='flex items-center gap-2 text-2xl'><BsFileEarmarkMedical
                            className='text-gray-400'/> <span className='text-pr'>Introduction</span></h1>
                        <p className='text-gray-400 text-sm pt-2'
                           style={{wordBreak: "break-word"}}>{data.resume.introduction}</p>
                    </div>}
                    {data.experience && data.experience.length > 0 &&
                    <div className='py-3 border-b-2'>
                        <h1 className='flex items-center gap-2 text-2xl'><BsBagCheck
                            className='text-gray-400'/>
                            <span className='text-pr'>Work Experience</span></h1>
                        {data.experience.map((data, index) =>
                            <div className='grid grid-cols-1 lg:grid-cols-4 pt-3'>
                                <div>
                                    <p className='text-gray-500 text-sm my-1'>
                                        <Moment
                                            format="DD MMM YYYY">{data.year_from}</Moment> - {parseInt(data.current) === 1 ? "Current"
                                        : data.year_to !== null ?
                                            <Moment format="DD MMM YYYY">{data.year_to}</Moment> : "Current"
                                    }
                                    </p>
                                </div>
                                <div className='lg:col-span-3'>
                                    <p className='font-semibold text-gray-700 my-1'>{data.employer}</p>
                                    <p className='italic text-gray-500 my-1'>{data.job_title}</p>
                                    <div className='pt-2 flex items-center gap-1'>
                                        <p className='font-semibold text-gray-700 my-1'>Job Category :</p>
                                        <p className=' italic text-gray-500 my-1'>{data.job_category}</p>
                                    </div>
                                    <div className='flex items-center gap-1'>
                                        <p className='my-1 font-semibold text-gray-700'>Referee Name :</p>
                                        <p className='my-1 text-gray-500'>{data.referee_name}</p>
                                    </div>
                                    <div className='flex items-center gap-1'>
                                        <p className='my-1 font-semibold text-gray-700'>Referee Email :</p>
                                        <p className='my-1 text-gray-500'>{data.referee_contact}</p>
                                    </div>
                                    <div className='flex items-center gap-1'>
                                        <p className='my-1 font-semibold text-gray-700'>Referee Phone :</p>
                                        <p className='my-1 text-gray-500'>{data.referee_phone}</p>
                                    </div>
                                    <div>
                                        <p className='font-semibold text-gray-700 pt-1 mb-1'>Summary of Work Done :</p>
                                        <p className='text-gray-500 text-sm my-0'>{data.work_summary}</p>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    }
                    {data.qualification &&
                    <div className='py-3 border-b-2'>
                        <h1 className='flex items-center gap-2 text-2xl'><BsLaptop
                            className='text-gray-400'/>
                            <span className='text-pr'>Education</span></h1>
                        {data.qualification.map((item) =>
                            <div className='grid grid-cols-1 lg:grid-cols-4 pt-2'>
                                <div>
                                    <p className='text-gray-500 my-0'>{item.year}</p>
                                </div>
                                <div className='lg:col-span-3'>
                                    <p className='font-semibold my-1 text-gray-700'>{item.course_name}</p>
                                    <p className='italic my-1 text-gray-500'>{item.institute}</p>
                                    <div className='pt-2 flex items-center gap-1'>
                                        <p className='font-semibold my-1 text-gray-700'>Level :</p>
                                        <p className='my-1 italic text-gray-500'>{item.level}</p>
                                    </div>

                                </div>
                            </div>
                        )}
                    </div>
                    }
                    {data.skill &&
                    <div className='py-3 border-b-2'>
                        <h1 className='flex items-center gap-2 text-2xl'><FiCodesandbox
                            className='text-gray-400'/> <span className='text-pr'>Skills</span></h1>
                        <div className='grid grid-cols-1 lg:grid-cols-4'>
                            <div/>
                            <div className='lg:col-span-3'>
                                {data.skill.map((item) =>
                                    <div className='pb-4 rating'>
                                        <p className='my-1 font-semibold text-gray-700'>{item.title}</p>
                                        <div className={`relative h-2 bg-gray-300 w-50`}>
                                            <div className={`absolute bg-pr bar-${item.level}`}
                                                 style={{height: "100%"}}/>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>

                    </div>
                    }
                    {data.language && data.language.length > 0 ?
                        <div className='py-3'>
                            <h1 className='flex items-center gap-2 text-2xl'><AiOutlineGlobal
                                className='text-gray-400'/> <span className='text-pr'>Languages</span></h1>
                            <div className='grid grid-cols-1 lg:grid-cols-4'>
                                <div/>
                                <div className='lg:col-span-3'>
                                    {data.language.map((item, index) =>
                                        <>
                                            <p className={`font-semibold mb-1 my-0 text-gray-700 ${index !== 0 ? 'mt-4' : ''}`}>{item.language}</p>
                                            <div className='flex items-center gap-1'>
                                                <p className='my-0 font-semibold text-gray-700'>Fluency Level :</p>
                                                <p className='mb-1 italic text-gray-500'>{item.level}</p>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                        : ""}
                </div>
            </div>
        </>
    );
};

export default ProfileItem;
