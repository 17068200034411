import React from 'react';
import {WorkOutlineOutlined} from "@mui/icons-material";
import {alertOptions, loader, thumbnailsURI} from "../config";
import {RequestGet, RequestM} from "../employer/Request";
import {Button, FormControl, InputLabel, MenuItem, Select, TextField} from "@mui/material";
import {Link} from "react-router-dom";
import {RequestG} from "../routes/Request";
import {toast} from "react-toastify";
import ReactPhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css';
import ProfileStatus from "./alerts/ProfileStatus";

const BasicInfo = () => {
    const [catchProfile, setCatchProfile] = React.useState(null);
    const [id, setId] = React.useState(0);
    const [userId, setUserId] = React.useState("");
    const [name, setName] = React.useState("");
    const [phone, setPhone] = React.useState("");
    const [gender, setGender] = React.useState("");
    const [nationality, setNationality] = React.useState("");
    const [birth, setBirth] = React.useState("");
    const [status, setStatus] = React.useState("");
    const [qualification, setQualification] = React.useState("");
    const [experience, setExperience] = React.useState("");
    const [location, setLocation] = React.useState("");
    const [fields, setFields] = React.useState(false);
    const [imgPreview, setImgPreview] = React.useState(null);
    const [imagePrev, setImagePrev] = React.useState(null);
    const [img, setImg] = React.useState("");
    const [data, setData] = React.useState(null);
    const onImageChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            let imgData = new FormData();
            imgData.append('_method', 'put');
            imgData.append('picture', event.target.files[0]);
            RequestM('user/profileImg/' + catchProfile.id, imgData).then((response) => {
                toast.success(response.message, alertOptions)
            })
            let reader = new FileReader();
            reader.onload = (e) => {
                setImagePrev(e.target.result)
            };
            reader.readAsDataURL(event.target.files[0]);
            setImg(event.target.files[0])
        }
    }
    React.useEffect(() => {
        if (catchProfile === null) {
            RequestGet('user/profile').then((response) => {
                if (response.preference == null) {
                    window.location.href = '/pref'
                }
                if (response.profile == null) {
                    window.location.href = '/step'
                }
                setImgPreview(response.profile.picture)
                setData(response);
            })
            RequestGet("user/job-seeker-profile-usr").then((response) => {
                if (response.status === 200) {
                    setUserId(response.data.user_id)
                    setId(response.data.id)
                    setName(response.data.name)
                    setPhone(response.data.phone)
                    setGender(response.data.gender)
                    setNationality(response.data.nationality)
                    setBirth(response.data.dob)
                    setStatus(response.data.employment_status)
                    setQualification(response.data.qualification)
                    setExperience(response.data.experience)
                    setLocation(response.data.address)
                    setImgPreview(response.data.picture)
                    setCatchProfile(response.data)
                } else {
                    setCatchProfile(null);
                }
            })
        }
    }, [catchProfile])
    React.useEffect(() => {
        if (fields === false && catchProfile === null) {
            RequestG('fields').then((response) => {
                setFields(response)
            })
        }
    }, [fields])
    const formSubmit = (e) => {
        e.preventDefault();
        if (id !== 0) {
            var formdata = new FormData();
            formdata.append("user_id", userId);
            formdata.append("name", name);
            formdata.append("phone", phone);
            formdata.append("gender", gender);
            formdata.append("nationality", nationality);
            formdata.append("dob", birth);
            formdata.append("employment_status", status);
            formdata.append("qualification", qualification);
            formdata.append("experience", experience);
            formdata.append("address", location);
            RequestM('job-seeker/profile-update/' + id, formdata).then((response) => {
                toast.success(response.message, alertOptions);
            })
        }
    }
    if (fields !== false && data !== null) {
        return (
            <>
                <ProfileStatus profile={data}/>
                <div className="content p-2">
                    <div className="d-flex py-2 px-2">
                        <WorkOutlineOutlined className="me-2 text-two"/>
                        <h4 className="heading text-main my-0">Basic Information</h4>
                    </div>
                    {catchProfile !== null ?
                        <form className="px-2 py-2" onSubmit={(e) => formSubmit(e)}>
                            <div className="row">
                                <div className="col-lg-6">
                                    <TextField required className="my-3 w-100" id="outlined-basic" label="Full Name"
                                               onChange={(e) => setName(e.target.value)}
                                               variant="outlined" value={name}/>
                                    <ReactPhoneInput
                                        value={phone}
                                        country='mv'
                                        placeholder="Mobile Number"
                                        onChange={setPhone}
                                        inputExtraProps={{
                                            name: 'mobile',
                                            required: true,
                                            autoFocus: true
                                        }}
                                    />
                                    <FormControl required className="mt-3" fullWidth>
                                        <InputLabel id="demo-simple-select-label">Gender</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={gender}
                                            onChange={(e) => setGender(e.target.value)}
                                            label="Gender">
                                            {fields.map((data) => {
                                                if (data.field === 'gender') {
                                                    return <MenuItem key={data.value}
                                                                     value={data.value}>{data.value}</MenuItem>;
                                                }
                                            })}
                                        </Select>
                                    </FormControl>
                                    <FormControl required className="mt-3" fullWidth>
                                        <InputLabel id="demo-simple-select-label">Nationality</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={nationality}
                                            onChange={(e) => setNationality(e.target.value)}
                                            label="Nationality">
                                            {fields.map((data) => {
                                                if (data.field === 'nationality') {
                                                    return <MenuItem key={data.value}
                                                                     value={data.value}>{data.value}</MenuItem>;
                                                }
                                            })}
                                        </Select>
                                    </FormControl>
                                    <div className="form-group mx-0 row mt-3">
                                        <label htmlFor="" className="my-0 mx-0 text-xs px-0">Date of Birth <span
                                            className="text-small">*</span></label>
                                        <input type="date" className="form-control w-100" required value={birth}
                                               onChange={(e) => setBirth(e.target.value)}/>
                                    </div>
                                    <FormControl required className="mt-3" fullWidth>
                                        <InputLabel id="demo-simple-select-label">Employment Status</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={status}
                                            onChange={(e) => setStatus(e.target.value)}
                                            label="Employment Status">
                                            {fields.map((data) => {
                                                if (data.field === 'rate_employer_role') {
                                                    return <MenuItem key={data.value}
                                                                     value={data.value}>{data.value}</MenuItem>;
                                                }
                                            })}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="col-lg-6">
                                    <div className="profileImg mb-2 mt-3">
                                        <img src={imagePrev == null ? thumbnailsURI + imgPreview : imagePrev}
                                             className="imgPreview me-auto" style={{margin: "initial"}}
                                             alt=""/>
                                        <input type="file" className="d-none" onChange={(event) => onImageChange(event)}
                                               id="logo"/>
                                        <label htmlFor="logo" style={{width: "8rem"}}
                                               className="btn mt-1 btn-sm d-block btn-primary">Upload
                                            Picture</label>
                                    </div>
                                    <FormControl required className="mt-3" fullWidth>
                                        <InputLabel id="demo-simple-select-label">Highest Qualification</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={qualification}
                                            onChange={(e) => setQualification(e.target.value)}
                                            label="Highest Qualification">
                                            {fields.map((data) => {
                                                if (data.field === 'qualifications') {
                                                    return <MenuItem key={data.value}
                                                                     value={data.value}>{data.value}</MenuItem>;
                                                }
                                            })}
                                        </Select>
                                    </FormControl>
                                    <FormControl required className="mt-3" fullWidth>
                                        <InputLabel id="demo-simple-select-label">Total work Experience</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={experience}
                                            onChange={(e) => setExperience(e.target.value)}
                                            label="Total work Experience">
                                            {fields.map((data) => {
                                                if (data.field === 'experience') {
                                                    return <MenuItem key={data.value}
                                                                     value={data.value}>{data.value}</MenuItem>;
                                                }
                                            })}
                                        </Select>
                                    </FormControl>
                                    <TextField required className="mt-3 w-100" id="outlined-basic"
                                               label="Present Location"
                                               onChange={(e) => setLocation(e.target.value)}
                                               variant="outlined" value={location}/>
                                </div>
                            </div>
                            <p className="py-3 mb-0">All Fields marked with * are required</p>
                            <Button color="info" type="submit" className="text-capitalize"
                                    variant={"contained"}>Save Profile</Button>
                        </form>
                        : <Link to="/step">Go back to fillup account</Link>}
                </div>
            </>
        );
    } else {
        return loader("20rem")
    }
};

export default BasicInfo;
