import React from 'react';
import {useParams} from 'react-router-dom';
import {alertOptions, loader} from "../config";
import {blobFD, RequestGet} from "./Request";
import ProfileItem from "./items/ProfileItem";
import {BiCloudDownload} from 'react-icons/bi';
import {ImProfile} from 'react-icons/im';
import ViewPitch from "./items/ViewPitch";
import {toast} from "react-toastify";

const ViewApplication = () => {
    const {applyId} = useParams();
    const [data, setData] = React.useState(null);
    const [profile, setProfile] = React.useState(false);
    const [update, setUpdate] = React.useState(1);
    React.useEffect(() => {
        RequestGet('employ/company').then((response) => {
            setProfile(response.company)
        })
        RequestGet('employ/view-application-byjobid/' + applyId).then((response) => {
            if (response.status === 200) {
                setData(response.data)
            }
            setUpdate(0)
        })
    }, [applyId, update])
    const arShortList = (id) => {
        RequestGet('employ/ar-shortlist/' + id)
            .then((response) => {
                if (parseInt(response.is_favorite) === 1) {
                    toast.success("Added to shortlist", alertOptions);
                    setUpdate(1)
                } else {
                    toast.success("Removed from shortlist", alertOptions);
                    setUpdate(1)
                }
            })
    }

    const download = (id) => {
        blobFD(localStorage.getItem('token'), "download-profile/" + id, "Profile-" + id + ".pdf")
    }
    if (data !== null && profile !== false) {
        return (
            <>
                {profile.id === data.profile.company_id
                    ?
                    <>
                        <div className="col-lg-3 px-0">
                            <div className="card rounded-0 border-0 card-body">
                                <a onClick={(e) => download(data.profile.user_id)} target="_blank" rel="noreferrer"
                                   className="my-2 text-sm text-gray-450 d-flex text-hover-main cursor-pointer"><BiCloudDownload
                                    className="mr-3 w-5 h-5"/>
                                    Download Profile PDF
                                </a>
                                <p className="my-2 text-sm text-gray-450 d-flex text-hover-main cursor-pointer">
                                    <ImProfile
                                        className="mr-3 w-5 h-5"/>Candidate Profile
                                </p>
                                <ViewPitch pitch={data.profile.cover_note} data={data}/>
                                {data.profile.resume !== null &&
                                <a href={data.profile.resume} target="_blank" rel="noreferrer"
                                   className="my-2 text-sm text-gray-450 d-flex text-hover-main cursor-pointer"><BiCloudDownload
                                    className="mr-3 w-5 h-5"/>
                                    Download Attached Resume
                                </a>}
                                {data.documents.length > 0 && data.documents.map((item, index) =>
                                    item.type === 'cert' || item.type === 'other' ?
                                        <a href={item.file_url} target="_blank" rel="noreferrer"
                                           className="my-2 text-sm text-gray-450 d-flex text-hover-main cursor-pointer"><BiCloudDownload
                                            className="mr-3 w-5 h-5"/>
                                            {item.type === 'cert' ? "Download Certificate" : ""}
                                            {item.type === 'other' ? "Download Ref-letters" : ""}
                                        </a> : "")}
                                {data.application.custom_resume !== null &&
                                <a href={data.application.custom_resume} target="_blank" rel="noreferrer"
                                   className="my-2 text-sm text-gray-450 d-flex text-hover-main cursor-pointer"><BiCloudDownload
                                    className="mr-3 w-5 h-5"/>
                                    Download Form
                                </a>}
                            </div>
                            <button onClick={() => arShortList(data.application.id)}
                                    className="btn btn-primary my-2 w-100 rounded-0">
                                {parseInt(data.application.is_favorite) === 1 ? "Remove Short-list" : "Short-list"}
                            </button>
                        </div>
                        <div className="col-lg-9 px-0 px-lg-2">
                            <ProfileItem data={data}/>
                        </div>
                    </>
                    : "Access Deny"}
            </>
        );
    } else {
        return loader("12rem")
    }
};

export default ViewApplication;
