import React from 'react';
import {alertOptions} from "../../config";
import {ComContext} from "../../routes/Employer";
import {RequestM} from "../Request";
import {toast} from "react-toastify";

const Email = () => {
    const {user} = React.useContext(ComContext);
    const resetEmail = () => {
        RequestM('email-verification').then((response) => {
            toast.warning(response.message, alertOptions)
        })
    }
    return (
        <>

            {user.email_verified_at === null &&
            <div className="emailWarning mb-2">
                We have sent you an email for account verification, Please verify to active your account. dont forget to
                check the Junk/Spam folder.
                <button className="text-bold ml-2" style={{fontWeight: "bold"}} onClick={(e) => resetEmail()}>Resend
                    Now.</button>
            </div>
            }
        </>
    );
};

export default Email;
