import React, {createContext} from 'react';
import Header from "../layouts/Header";
import {ToastContainer} from "react-toastify";
import {Container} from "react-bootstrap";
import {Redirect, Route} from "react-router-dom";
import Footer from "../layouts/Footer";
import {RequestGet} from "../employer/Request";
import SideBar from "../layouts/SideBar";

export const UserContext = createContext();

const Private = ({children, sidebar, ...rest}) => {
    const [data, setData] = React.useState(false);
    const [update, setUpdate] = React.useState(true);
    React.useEffect(() => {
        if (data === false && localStorage.getItem('token')) {
            RequestGet('profile').then((response) => {
                setData({
                    'user': response,
                    'token': localStorage.getItem('token')
                })
                setUpdate(false)
            })
        }
    })
    if (localStorage.getItem('token')) {
        if (update === false) {
            if (data.user.role == 1) {
                return (
                    <>
                        <UserContext.Provider value={data}>
                            <Header authentication={data.user}/>
                            <ToastContainer/>
                            <div className="company">
                                <Container className="py-5">
                                    <div className="col-lg-12 pt-5 mx-auto">
                                        <div className="row">
                                            {sidebar === true && <SideBar authentication={data.user}/>}
                                            <div
                                                className={`${sidebar === true ? "col-lg-9 px-0 px-lg-2" : "col-lg-12"}`}>
                                                {<Route {...rest} render={() => (children)}/>}
                                            </div>
                                        </div>
                                    </div>
                                </Container>
                            </div>
                            <Footer/>
                        </UserContext.Provider>
                    </>
                );
            } else {
                return <Redirect to={'/'}/>
            }
        } else {
            return null;
        }
    } else {
        return <Redirect to="/"/>
    }
};

export default Private;
