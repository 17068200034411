import React from 'react';
import {WorkOutlineOutlined} from "@mui/icons-material";
import {RequestG} from "../routes/Request";
import {alertOptions, loader} from "../config";
import {FormControlLabel, Switch} from '@mui/material';
import {RequestGet, RequestM} from "../employer/Request";
import {toast} from "react-toastify";
import ProfileStatus from "./alerts/ProfileStatus";


const Setting = () => {
    const [set, setSet] = React.useState(null);
    const [fields, setFields] = React.useState(false);
    const [profile, setProfile] = React.useState(null);
    const [data, setData] = React.useState(null);

    React.useEffect(() => {
        if (fields === false) {
            RequestGet('user/profile').then((response) => {
                if (response.preference == null) {
                    window.location.href = '/pref'
                }
                if (response.profile == null) {
                    window.location.href = '/step'
                }
                setData(response);
            })
            RequestGet('user/job-seeker-profile-usr').then((response) => {
                if (response.status === 200) {
                    setSet(response.data.seeking_job)
                }
                setProfile(response)
            })
            RequestG('fields').then((response) => {
                setFields(response)
            })
        }
    }, [fields])
    const onChange = () => {
        if (set === 1) {
            setSet(0);
        } else {
            setSet(1)
        }
        if (profile.status === 200) {
            RequestM('user/profile-off').then((response) => {
                toast.success(response.message, alertOptions);
            })
        }
    }
    if (fields !== false && profile !== null && data !== null) {
        return (
            <>
                <ProfileStatus profile={data}/>
                <div className="content p-2">
                    <div className="d-flex py-2 px-2">
                        <WorkOutlineOutlined className="me-2 text-two"/>
                        <h4 className="heading text-main my-0">Privacy Setting</h4>
                    </div>
                    {profile.status === 200 ?
                        <form className="px-2 pb-4">
                            <p className="text-xs mb-2 text-gray-600 mt-4">Terning this ON will hide your profile from
                                the
                                JobSicle
                                Telentpool.
                                Employers will not be able to view browser your profile.</p>
                            <p className="mt-2 text-xs text-gray-600 mb-3">It will resuce your chance of getting noticed
                                by
                                a
                                potential Employers.</p>
                            <FormControlLabel
                                control={set == 1 ?
                                    <Switch onChange={(e) => onChange(e)}/> :
                                    <Switch onChange={(e) => onChange(e)} defaultChecked/>}
                                label="Hide my Profile"/>
                        </form> : "Please complete your profile"}
                </div>
            </>
        );
    } else {
        return loader("20rem")
    }
};

export default Setting;
