import React from 'react';
import {Modal} from "react-bootstrap";
import imgIco from "../assets/images/004m.png";
import {Link} from 'react-router-dom';
import "./design.scss";
import {FaRegBookmark} from "react-icons/fa";

const ApplyOnline = ({id}) => {
    const [modal, setModal] = React.useState(false);
    return (
        <>
            <FaRegBookmark onClick={() => setModal(true)}
                           className="h-8 w-8 cursor-pointer text-gray-500"/>
            <button onClick={() => setModal(true)}
                    className="bg-pr text-white py-1 rounded-sm px-8">
                Apply Online
            </button>
            <Modal
                size="sm"
                show={modal}
                onHide={() => setModal(false)} centered
                backdrop="static" className="modalAO"
                keyboard={false}>
                <Modal.Body>
                    <img src={imgIco} className="img-fluid imgIconAO" alt=""/>
                    <h5 className="my-2 font-weight-600 text-center">Please login to Apply / Saved jobs</h5>
                    <div>
                        {!localStorage.getItem('token') &&
                        <button className="btnAO1" onClick={() => window.location.href = '/login'}>Take me to
                            Login</button>
                        }
                        <button className="btnAO2" onClick={() => setModal(false)}>Maybe later</button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default ApplyOnline;
