import React from 'react';

const InvView = ({option, data, eTin, numberPage, inv}) => {
    const currency = parseInt(inv[0].currency) === 2 ? "USD" : "MVR";
    return (
        <>
            <div className="col-lg-8">
                <div className="pdf_gen rounded px-3 px-lg-4">
                    <div className="d-flex mt-3 pdfTopper">
                        <div className="left">
                            INV-{inv.id}
                        </div>
                        <div className="right">
                            <h3 className="text-main d-inline-block">Invoice</h3>
                        </div>
                    </div>
                    <p className="text-center text-main mb-0 mt-1">Jobsicle</p>
                    <p className="text-center my-1">Technology Pvt Ltd</p>
                    <p className="text-center my-1">Tel: +960 7379494
                        <span className="mx-2">|</span> mail@jobsicle.mv</p>
                    <hr/>
                    <h2>Bill to:</h2>
                    <p className="mt-2 mb-0">{data.company.name}</p>
                    <p className="my-0">Tel: {data.company.phone}</p>
                    <p className="my-0">Email: {data.company.phone}</p>
                    <p className="my-0">TIN: {eTin}</p>
                    <hr/>

                    {option == 1 ?
                        <>
                            <div className="row">
                                <div className="col-md-6">
                                    <h2>Service: </h2>
                                    <p className="mt-2 mb-0">Annual Subscription</p>
                                    <p className="mb-0">Sector: Private (Tourism)</p>
                                </div>
                                <div className="col-md-3">
                                    <h2>Rate: </h2>
                                    <p className="mt-2 mt-0">{currency} {inv[0].price_per_unit}</p>
                                </div>
                                <div className="col-md-3">
                                    <h2>Total: </h2>
                                    <p className="mt-2 mt-0">{currency} {inv[0].price_per_unit}</p>
                                </div>
                            </div>
                            <hr/>
                        </>
                        :
                        <>
                            <div className="row">
                                <div className="col-md-5">
                                    <h2>Service: </h2>
                                    <p className="mt-2 mb-0">Pay Per Job</p>
                                    <p className="mb-0">Sector: {data.company.sector}</p>
                                </div>
                                <div className="col-md-2">
                                    <h2>Rate: </h2>
                                    <p className="mt-2 mt-0">{currency} {inv[1].price_per_unit}</p>
                                </div>
                                <div className="col-md-3">
                                    <h2>Quantity: </h2>
                                    <p className="mt-2 mt-0">X{numberPage}</p>
                                </div>
                                <div className="col-md-2">
                                    <h2>Total: </h2>
                                    <p className="mt-2 mt-0">{currency} {numberPage * inv[1].price_per_unit}</p>
                                </div>
                            </div>
                            <hr/>
                        </>
                    }
                    <h2>Account Details: </h2>
                    <p className="mb-0 mt-2">Name: Technosys Pvt Ltd </p>
                    <p className="mb-0">Account: 7730000211913</p>
                    <p className="mb-0">Bank: Bank of Maldives</p>
                    <p className="mb-0">Currency: MVR</p>
                    <p className="mb-0">Swift: MALBMVMV</p>
                    <p className="my-4">Please send the payment slip to Viber +960
                        7379494
                        or
                        mail@jobsicle.mv</p>
                    <hr className="my-3"/>
                    <div className="text-center my-3">For any queries, contact us on +960
                        7379494
                    </div>
                </div>
            </div>
        </>
    );
};

export default InvView;
