import React from 'react';
import Email from "./warning/Email";
import {ReceiptOutlined} from "@mui/icons-material";
import VerifyIcon from "./warning/VerifyIcon";
import {Button, FormControlLabel, RadioGroup, Radio, TextField} from "@mui/material";
import {ComContext} from "../routes/Employer";
import {RequestGet, RequestM} from "./Request";
import {alertOptions, apiURI, loader} from "../config";
import {toast} from "react-toastify";
import SubscriptionsItem from "./items/SubscriptionsItem";
import Moment from "react-moment";
import SubsContent from "./items/SubsContent";
import InvView from "./items/InvView";

const Subscriptions = () => {
    const [subsForm, setSubsForm] = React.useState(false);
    const [option, setOption] = React.useState(1);
    const [eTin, setETin] = React.useState("");
    const [numberPage, setNumberPage] = React.useState(1);
    const {user, token} = React.useContext(ComContext);
    const [data, setData] = React.useState(false);
    const [dataTwo, setDataTwo] = React.useState(null);
    React.useEffect(() => {
        if (data === false) {
            RequestGet('employ/company').then((response) => {
                setData(response)
            })
        }
        if (dataTwo === null) {
            RequestGet('employ/company').then((response) => {
                if (response.invoice_pending !== null) {
                    setOption(response.invoice_pending.package);
                    setNumberPage(response.invoice_pending.no_of_jobs);
                    setDataTwo(response.invoice_pending)
                }
            })
        }
    }, [data, dataTwo])
    const formSubmit = (e) => {
        let myCurrentDate = new Date();
        let myFutureDate = new Date(myCurrentDate);
        myFutureDate.setDate(myFutureDate.getDate() + 50);
        const exDate = myFutureDate.getFullYear() + '-' + myFutureDate.getMonth() + '-' + myFutureDate.getDate() + " 00:00:00";
        e.preventDefault();
        let formdata = new FormData();
        formdata.append("company_id", data.company.id);
        formdata.append("package", option);
        formdata.append("tin", eTin);
        if (option === 1) {
            formdata.append('no_of_jobs', null);
        } else {
            formdata.append('no_of_jobs', numberPage);
        }
        RequestM('employ/invoice', formdata).then((response) => {
            setData(false);
            toast.success(response.message, alertOptions)
            setTimeout(() => {
                window.location.href = '/subscriptions'
            }, 3000)
        })
    }
    const onChangeSlipUpload = (e, id) => {
        let currentFile = e.target.files[0];
        if (currentFile.type == 'image/jpeg' || currentFile.type == 'image/png' || currentFile.type == 'application/pdf') {
            let formdata = new FormData();
            formdata.append("invoice_id", id);
            formdata.append("slip", currentFile);
            RequestM('employ/upload-slip', formdata, 'post').then((data) => {
                toast.success(data.message, alertOptions)
                if (parseInt(data.status) === 200) {
                    setTimeout(() => {
                        window.location.reload()
                    }, 3000)
                }
            })
        } else {
            toast.error('File Format Error', alertOptions)
        }
    }
    const formSubmitChange = (e) => {
        e.preventDefault();
        if (dataTwo !== null) {
            let formdata = new FormData();
            formdata.append('_method', 'put');
            formdata.append('id', dataTwo.id);
            formdata.append('company_id', data.company.id);
            formdata.append('package', option);
            formdata.append('tin', eTin);
            if (option == 2) {
                formdata.append('no_of_jobs', numberPage);
            } else {
                formdata.append('no_of_jobs', "");
            }
            RequestM('employ/invoice/' + dataTwo.id, formdata).then((response) => {
                toast.success(response.message, alertOptions)
                setData(false);
                setTimeout(() => {
                    window.location.href = '/subscriptions'
                }, 3000)
            })
        } else {
            toast.warning("Data not found!", alertOptions)
        }
    }

    if (data !== false) {
        return (
            <>
                <Email/>
                <div className="content subscriptions p-2">
                    <div className="d-flex mb-2">
                        <ReceiptOutlined className="me-2 text-two"/>
                        <h4 className="heading text-main my-0">Subscriptions</h4>
                    </div>
                    <VerifyIcon/>
                    {user.email_verified_at !== null &&
                    <>
                        {
                            !subsForm ?
                                <>
                                    {data.annual !== null &&
                                    <div className="my-4 row mx-0 small-2">
                                        <div className="col-md-10 text-two px-1">{data.annual.package} is
                                            active - Expiring on <Moment
                                                format="DD MMM YYYY">{data.annual.expiry_date}</Moment>
                                        </div>
                                        <div className="col-md-2 px-0 text-right text-main pe-4">Active
                                        </div>
                                    </div>}
                                    {data.payper !== null &&
                                    <div className="my-4 row mx-0 small-2">
                                        <div className="col-md-10 text-two px-1">{data.payper.package} is
                                            active - {data.payper.jobs_left} job ads remaining
                                        </div>
                                        <div className="col-md-2 px-0 text-right text-main pe-4">Active
                                        </div>
                                    </div>}
                                    {data.annual === null && data.payper === null && data.invoice_pending === null
                                        ?
                                        <div className="my-4 d-flex px-1">
                                            <p className="text-two mt-2 mb-0">No active subscriptions</p>
                                            <Button
                                                onClick={(e) => setSubsForm(!subsForm)}
                                                variant="contained" className="text-capitalize rounded-3 ms-5"
                                                color="info">
                                                Subscribe
                                            </Button>
                                        </div>
                                        : ""}
                                    {data.invoice_pending !== null && data.invoice_pending.paid_at === null
                                    &&
                                    <div className="my-4 px-1 d-flex">
                                        <p className="mb-0 mt-1 text-two">You have a pending payment
                                            for {parseInt(data.invoice_pending.package) === 1 ? 'Annual Subscription' : 'Pay-Per-Job'}.</p>
                                        {data.invoice_pending.slip === null ?
                                            <>
                                                <input type="file"
                                                       onChange={(e) => onChangeSlipUpload(e, data.invoice_pending.id)}
                                                       className="d-none"
                                                       id="uploadSlip"/>
                                                <label htmlFor="uploadSlip" className="btn mx-5 btn-sm btn-primary">Upload
                                                    Payment
                                                    Slip</label>
                                            </>
                                            : <>
                                        <span
                                            style={{marginTop: '7px'}} className="text-xs ms-2 italic">(Payment slip linked - Waiting for approval)</span>
                                            </>}
                                        <div className="ms-auto pe-3">
                                            <button
                                                onClick={(e) => setSubsForm(!subsForm)}
                                                className="text-capitalize text-warning">
                                                Change Package
                                            </button>
                                        </div>
                                    </div>}
                                </>
                                :
                                <>
                                    <div className="row subscriptionsContentArea mx-0">
                                        {dataTwo !== null ?
                                            <SubsContent
                                                eTin={eTin} numberPage={numberPage}
                                                userPack={data.packages}
                                                option={option}
                                                setETin={setETin} setNumberPage={setNumberPage}
                                                setOption={setOption}
                                                formSubmit={formSubmitChange}/>
                                            :
                                            <SubsContent
                                                eTin={eTin} numberPage={numberPage} option={option}
                                                userPack={data.packages}
                                                setETin={setETin} setNumberPage={setNumberPage}
                                                setOption={setOption}
                                                formSubmit={formSubmit}/>
                                        }
                                        {dataTwo !== null ?
                                            <InvView
                                                option={option} data={data} numberPage={numberPage} eTin={eTin}
                                                inv={data.packages}/> : ""}
                                    </div>
                                </>
                        }
                    </>
                    }
                </div>
                <div className="content mt-1 p-2">
                    <div className="d-flex mb-2">
                        <ReceiptOutlined className="me-2 text-two"/>
                        <h4 className="heading text-main my-0">History</h4>
                    </div>
                    {/*<div className="lg:col-span-6 col-span-5 text-gray-500 text-sm pl-8">Ref</div>*/}
                    <div className="mt-4 bg-gray-200 gap-2 py-2 grid grid-cols-6">
                        <div className="text-gray-500 text-xs ps-2">Ref</div>
                        <div className="text-gray-500 text-xs">Type</div>
                        <div className="text-gray-500 text-xs">Issued on</div>
                        <div className="text-gray-500 text-xs">Status</div>
                        <div className="text-gray-500 text-xs">Paid on</div>
                        <div className="text-gray-500 text-xs">Download</div>
                    </div>
                    <SubscriptionsItem token={token}
                                       status={true} email_verified_at={user.email_verified_at} item={data.invoice}
                                       company={data.company} text3={"Receipt"}
                                       text1={`is Expired`} text2={"Invoice"}/>
                </div>
            </>
        );
    } else {
        return loader("20rem")
    }
};

export default Subscriptions;
