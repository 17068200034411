import {apiURI} from "../config";
import axios from "axios";

const headers = {
    'Accept': 'application/json',
    'Authorization': "Bearer " + localStorage.getItem('token')
};
export const RequestGet = async (page) => {
    return await axios({
        headers,
        url: apiURI + page,
        method: "get"
    }).then(function (response) {
        return response.data;
    }).catch(function (error) {
    });
}
export const RequestD = async (page) => {
    return await axios({
        headers,
        url: apiURI + page,
        method: "delete"
    }).then(function (response) {
        return response.data;
    }).catch(function (error) {
        return error;
    });
}
export const RequestM = async (page, formData = "", method = "post") => {
    const config = {
        method: method,
        url: apiURI + page,
        headers: headers,
        data: formData
    };
    return await axios(config)
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            return error;
        });
}
export const blobFD = async (token, page, name) => {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/pdf");
    myHeaders.append("Authorization", "Bearer " + token);
    let requestOptions = {
        method: 'GET',
        headers: myHeaders,
    };
    fetch(apiURI + page, requestOptions)
        .then(response => response.blob())
        .then((blob) => {
            const url = window.URL.createObjectURL(
                new Blob([blob]),
            );
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
                'download',
                name,
            );
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        }).catch(error => console.log('error', error));
}