import React from 'react';
import {Button, FormControl, InputLabel, MenuItem, Select, TextField} from "@mui/material";
import {Modal} from "react-bootstrap";
import {RequestM} from "../../employer/Request";
import {toast} from "react-toastify";
import {alertOptions} from "../../config";

const AddLanguage = ({profile, setFields}) => {
    const [name, setName] = React.useState("");
    const [level, setLevel] = React.useState(0);
    const [modal, setModal] = React.useState(false);
    const formSubmit = (e) => {
        e.preventDefault();
        var formdata = new FormData();
        formdata.append("job_seeker_profile_id", profile.id);
        formdata.append("language", name);
        formdata.append("level", level);
        RequestM('job-seeker/languages', formdata).then((response) => {
            toast.success(response.message, alertOptions)
            setModal(false)
            setName("");
            setLevel(0);
            setFields(false)
        })
    }
    const year = new Date().getFullYear();
    let list = []
    for (let i = 2000; i <= year; i++) {
        list.push(i)
    }
    return (
        <>
            <Button onClick={() => setModal(true)} color="success" type="button" className="text-capitalize"
                    variant={"contained"}>+ Add Another</Button>
            <Modal
                size="lg"
                show={modal}
                onHide={() => setModal(false)} centeded
                aria-labelledby="example-modal-sizes-title-lg">
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Add Language
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={(e) => formSubmit(e)} className="px-2 py-2">
                        <div className="row">
                            <div className="col-lg-6">
                                <TextField required className="mt-3 w-100" id="outlined-basic" label="Name"
                                           variant="outlined" value={name}
                                           onChange={(e) => setName(e.target.value)}/>
                            </div>
                            <div className="col-lg-6">
                                <FormControl required className="mt-3" fullWidth>
                                    <InputLabel id="demo-simple-select-label">Fluency Level</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={level} onChange={(e) => setLevel(e.target.value)}
                                        label="Fluency Level">
                                        <MenuItem value={"Basic"}>Basic</MenuItem>
                                        <MenuItem value={"Conversational"}>Conversational</MenuItem>
                                        <MenuItem value={"Fluent"}>Fluent</MenuItem>
                                        <MenuItem value={"Native/Bilingual"}>Native/Bilingual</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                        <Button color="info" type="submit" className="text-capitalize mt-3"
                                variant={"contained"}>Save Language</Button>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default AddLanguage;
