import React from 'react';
import {AiFillCheckCircle, AiFillWarning} from "react-icons/ai";
import {BsBookmarkFill} from "react-icons/bs";
import {MdContactPage} from "react-icons/md";
import {FaShareSquare, FaRegBookmark} from "react-icons/fa";
import {Link, useParams} from 'react-router-dom'
import {RequestD, RequestGet, RequestM} from "../employer/Request";
import {alertOptions, loader, mainURI, rootURI} from "../config";
import {toast} from "react-toastify";
import {RequestG} from "../routes/Request";
import Moment from "react-moment";
import Incomplete from "../modals/Incomplete";
import ApplyOnline from "../modals/ApplyOnline";
import "./design.scss"
import AttachmentViewer from "../modals/AttachmentViewer";
import ApplyItem from "../layouts/ApplyItem";

const JobView = () => {
    const {jobId} = useParams();
    const [save, setSave] = React.useState(false)
    const [data, setData] = React.useState(false);
    const [profile, setProfile] = React.useState(false);
    const [applicationChecker, setApplicationChecker] = React.useState(null)
    React.useEffect(() => {
        if (data === false) {
            if (localStorage.getItem('token')) {
                RequestGet('user/applicationChecker/' + jobId).then((response) => {
                    setApplicationChecker(response)
                })
            }
            if (localStorage.getItem('token')) {
                RequestGet('user/profile').then((response) => {
                    setProfile(response)
                })
            }
            if (localStorage.getItem('token')) {
                RequestGet('job/save/' + jobId).then((response) => {
                    if (response.status === 200) {
                        setSave(true)
                    } else {
                        setSave(false)
                    }
                })
            }
            RequestG('jobView/' + jobId).then((response) => {
                if (response.status === 200) {
                    setData(response.data)
                }
            })
        }
    })
    const saveJob = () => {
        const formData = new FormData();
        formData.append('job_id', jobId)
        RequestM('job/save', formData).then((data) => setSave(true))
    }
    const removeJob = () => {
        RequestD('job/save/' + jobId).then((data) => setSave(false))
    }
    const copyText = () => {
        navigator.clipboard.writeText(rootURI + "/job/" + jobId);
        toast.success("Copied job url", alertOptions)
    }
    if (data !== false) {
        return (
            <div className="container jobViewModal text-gray-500 pb-3" style={{paddingTop: "5rem"}}>
                <div
                    className="flex items-center justify-between flex-wrap gap-3 border-b py-4 border-gray-300">
                    <div className="flex items-center gap-2">
                        <div>
                            <img className="w-20 h-20"
                                 src={`${data.logo !== undefined ? data.logo !== null ? data.logo : mainURI + "/uploads/no-img.jpg" : mainURI + "/uploads/no-img.jpg"}`}
                                 alt=""/>
                        </div>
                        <div>
                            <h1 className="text-xl">{data.title}</h1>
                            <div className="row customViewjobrow items-center mx-0 text-sm text-gray-600">
                                <div className="content px-0 mb-0">
                                    <p className="items-center d-flex my-0">{data.name} {parseInt(data.is_verified) === 1 &&
                                    <AiFillCheckCircle className="w-3 h-3 ml-2 ml-md-1 text-pr"/>}</p>
                                </div>
                                <div className="content px-0 ps-2">
                                    <p className="items-center my-0">Posted: <Moment
                                        fromNow>{data.created_at}</Moment></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex items-center gap-4">
                        <ApplyItem data={data} applicationChecker={applicationChecker} profile={profile}
                                   save={save}
                                   setSave={setSave} jobId={jobId}/>
                    </div>
                </div>
                <div className="mt-4">
                    {parseInt(data.prevent_international_applicants) === 1 &&
                    <div className="">
                        <AiFillWarning className="text-yellow-500 warningIcon mr-2 d-inline-block"/>
                        <p className="d-inline-block my-2 text-sm">This job is open for Maldivians only</p>
                    </div>}
                    {parseInt(data.prevent_online_application) === 1 &&
                    <div className="">
                        <AiFillWarning className="text-red-600 warningIcon w-90 mr-2 d-inline-block"/>
                        <p className="d-inline-block my-2 text-sm">This employer does not accept Online
                            Applications through Jobsicle for this
                            job. Please submit Applications in hard copy or as instructed below</p>

                    </div>}
                    {data.application_form === null || data.application_form === "" ?
                        "" :
                        <div className="">
                            <MdContactPage className="text-pr warningIcon mr-2 d-inline-block"/>
                            <p className="d-inline-block my-2 text-sm">This job requires an Applications
                                Form to
                                be filled and submitted. <a target="_blank" className="text-main"
                                                            href={data.application_form}>Click here to
                                    download</a>
                            </p>
                        </div>
                    }
                </div>
                <div className="grid grid-cols-1 lg:grid-cols-3 gap-y-2 lg:gap-5 mt-3">
                    <div className="border border-gray-300 text-sm p-3 bg-white col-span-2">
                        {<div style={{whiteSpace: 'pre-wrap'}}
                              dangerouslySetInnerHTML={{__html: data.description}}/>}
                        {data.req_email != null ?
                            <div className="mailto mt-2">You can apply online or send job application by
                                email to: {data.req_email}</div> : ""}
                    </div>
                    <div className="bg-white text-sm border border-gray-300">
                        <div className="p-3">
                            <div className="flex items-center gap-1">
                                <p className="mb-2 font-bold">Salary Range:</p>
                                <p className="mb-2 ">{data.salary_range}</p>
                            </div>
                            <div className="flex items-center gap-1">
                                <p className="mb-2 font-bold">Job Type:</p>
                                <p className="mb-2 ">{data.type}</p>
                            </div>
                            <div className="flex items-center gap-1">
                                <p className="mb-2 font-bold">Qualification: </p>
                                <p className="mb-2 ">{data.qualification}</p>
                            </div>
                            <div className="flex items-center gap-1">
                                <p className="mb-2 font-bold">Location: </p>
                                <p className="mb-2 ">{data.location}</p>
                            </div>
                            <div className="flex items-center gap-1">
                                <p className="mb-2 font-bold">Experience: </p>
                                <p className="mb-2 ">{data.experience}</p>
                            </div>
                            <div className="flex gap-1 flex-col">
                                <p className="font-bold mb-2">Requirement items: </p>
                                <ul className="list-disc mb-2" style={{marginLeft: "1rem"}}>
                                    {data.required_items !== undefined && data.required_items !== null ?
                                        JSON.parse(data.required_items).map((item, index) =>
                                            item !== null &&
                                            <li key={index}>{item}</li>
                                        ) : ""
                                    }
                                </ul>
                            </div>
                            <div className="flex items-center gap-1">
                                <p className="font-medium mb-2"><b>Ref No:</b> {data.ref_no}</p>
                            </div>
                            <div className="flex items-center gap-1">
                                <p className="font-medium mb-2"><b>Sector: </b> {data.sector}</p>
                            </div>
                            <div className="flex items-center gap-1">
                                <p className="font-medium mb-2"><b>Closing on: </b> <Moment
                                    format="DD MMM YYYY">{data.expired_at}</Moment></p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="py-3">
                    {data.attachment !== null ?
                        <AttachmentViewer url={data.attachment}/> : ""
                    }
                </div>
            </div>
        )
    } else {
        return <div className="container pt-loader">{loader("20rem")}</div>
    }
}
export default JobView
