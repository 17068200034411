import React from 'react';
import "../employer/design.scss";
import {Button, TextField} from '@mui/material';
import {Link} from "react-router-dom";
import {apiURI, alertOptions} from "../config";
import {toast} from "react-toastify";
import {InputGroup, FormControl} from 'react-bootstrap';
import {AiFillEye, AiFillEyeInvisible} from 'react-icons/ai'

const Login = () => {
    const [email, setEmail] = React.useState();
    const [password, setPassword] = React.useState();
    const [passType, setType] = React.useState(true);
    const formSubmit = (e) => {
        e.preventDefault()
        const headers = new Headers();
        headers.append("Accept", "application/json");
        let formdata = new FormData();
        formdata.append("email", email);
        formdata.append("password", password);
        let requestOptions = {
            method: 'POST',
            headers: headers,
            body: formdata,
            redirect: 'follow'
        };
        fetch(apiURI + "login", requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status === 200) {
                    localStorage.setItem('token', result.token);
                    if (result.data.role == 2) {
                        window.location.href = '/my-company'
                    } else {
                        window.location.href = '/profile'
                    }
                } else {
                    toast.error(result.message, alertOptions)
                }
            })
            .catch(error => console.log('error', error));
    }
    React.useEffect(() => {
        if (localStorage.getItem('token') !== null) {
            window.location.href = '/'
        }
    })
    return (
        <>
            <div className="login d-flex justify-content-center align-items-center">
                <div className="col-md-6 col-10 col-lg-4 col-xxl-3">
                    <form onSubmit={(e) => formSubmit(e)} className="content rounded">
                        <h2 className="text-center mb-3">Login </h2>
                        <hr/>
                        <div className="mx-5 my-4">
                            <TextField required className="mt-3 w-100" id="outlined-basic" label="Email Address"
                                       onChange={(e) => setEmail(e.target.value)}
                                       variant="outlined" value={email}/>
                            <InputGroup className="mt-3 text-sm">
                                <FormControl required placeholder="Password"
                                             type={passType ? "password" : "text"}
                                             onChange={(e) => setPassword(e.target.value)}
                                />
                                <InputGroup.Text id="basic-addon2" className="password-eye"
                                                 onClick={() => setType(!passType)}>
                                    {passType ? <AiFillEye/> : <AiFillEyeInvisible/>}
                                </InputGroup.Text>
                            </InputGroup>
                            <Button color="primary" type="submit" className="w-100 mt-3"
                                    variant={"contained"}>Login</Button>
                            <div className="text-center">
                                <Link to="forget-password"
                                      className="text-main text-decoration-none d-inline-block mb-2 mt-4">Forget
                                    Password</Link>
                                <h5 className="text-two text-center my-0 py-0">OR</h5>
                                <Link to="register" className="text-main text-decoration-none d-inline-block mt-2">Create
                                    a new
                                    account</Link>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};

export default Login;
