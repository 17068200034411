import React, {createContext} from 'react';
import Header from "../layouts/Header";
import {ToastContainer} from "react-toastify";
import {Redirect, Route} from "react-router-dom";
import Footer from "../layouts/Footer";
import {Container} from "react-bootstrap";
import SideBar from "../layouts/SideBar";
import {RequestGet} from "../employer/Request";

export const ComContext = createContext();

const Employer = ({children, sidebar, ...rest}) => {
    const [data, setData] = React.useState(false);
    const [update, setUpdate] = React.useState(true);
    React.useEffect(() => {
        if (data === false && localStorage.getItem('token')) {
            RequestGet('profile').then((response) => {
                setData({
                    'user': response,
                    'token': localStorage.getItem('token')
                })
                setUpdate(false)
            })
        }
    })
    if (localStorage.getItem('token')) {
        if (update === false) {
            if (parseInt(data.user.role) === 2) {
                return (
                    <>
                        <ComContext.Provider value={data}>
                            <Header authentication={data.user}/>
                            <ToastContainer/>
                            <div className="company">
                                <Container className="py-5">
                                    <div className="col-lg-12 pt-5 mx-auto">
                                        <div className="row">
                                            {sidebar === true && <SideBar authentication={data.user}/>}
                                            {sidebar === true ?
                                                <div className="col-lg-9 col-xxl-9 px-0 px-lg-2">
                                                    {<Route {...rest} render={() => (children)}/>}
                                                </div> :
                                                <Route {...rest} render={() => (children)}/>
                                            }
                                        </div>
                                    </div>
                                </Container>
                            </div>
                            <Footer/>
                        </ComContext.Provider>
                    </>
                );
            } else {
                return <Redirect to={'/'}/>
            }
        } else {
            return null;
        }
    } else {
        return <Redirect to="/"/>
    }
};

export default Employer;
