import React from 'react';
import "../employer/design.scss";
import {Button, TextField} from '@mui/material';
import {alertOptions, apiURI} from "../config";
import {toast} from "react-toastify";

const ForgetPassword = () => {
    const [update, setUpdate] = React.useState(false);
    const [email, setEmail] = React.useState();
    const formSubmit = (e) => {
        setUpdate(true)
        e.preventDefault()
        const headers = new Headers();
        headers.append("Accept", "application/json");
        let formdata = new FormData();
        formdata.append("email", email);
        let requestOptions = {
            method: 'POST',
            headers: headers,
            body: formdata,
            redirect: 'follow'
        };
        fetch(apiURI + "forgot-password", requestOptions)
            .then(response => response.json())
            .then(result => {
                setUpdate(false)
                setEmail("");
                toast.success(result.message, alertOptions)
            })
            .catch(error => console.log('error', error));
    }
    React.useEffect(() => {
        if (localStorage.getItem('token') !== null) {
            window.location.href = '/'
        }
    })
    return (
        <>
            <div className="login d-flex justify-content-center align-items-center">
                <div className="col-md-6 col-10 col-lg-4 col-xxl-3">
                    <form onSubmit={(e) => formSubmit(e)} className="content rounded">
                        <h2 className="text-center mb-3">Reset Password</h2>
                        <hr/>
                        <div className="mx-5 my-4">
                            <p className="my-3 text-two">
                                Please enter your email address and click the submit button. We will send you an email
                                containing a reset link. Don't forget to check the Junk/Spam folder too.
                            </p>
                            <TextField className="mt-3 w-100" id="outlined-basic" label="Email Address"
                                       onChange={(e) => setEmail(e.target.value)}
                                       variant="outlined" value={email}/>
                            {update === false ? <Button color="info" type="submit" className="w-100 mt-3"
                                                        variant={"contained"}>Submit</Button> :
                                <Button color="primary" disabled type="button" className="w-100 mt-3"
                                        variant={"contained"}>Please wait...</Button>}
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};

export default ForgetPassword;
