import React from 'react';
import {Button, FormControl, InputLabel, MenuItem, Select, TextField} from "@mui/material";
import {Modal} from "react-bootstrap";
import {RequestM} from "../../employer/Request";
import {toast} from "react-toastify";
import {alertOptions} from "../../config";

const AddExperience = ({fields, resume, setFields}) => {
    const [category, setCategory] = React.useState("");
    const [title, setTitle] = React.useState("");
    const [company, setCompany] = React.useState("");
    const [start, setStart] = React.useState("");
    const [end, setEnd] = React.useState("");
    const [refName, setRefName] = React.useState("");
    const [refEmail, setRefEmail] = React.useState("");
    const [refPhone, setRefPhone] = React.useState("");
    const [summary, setSummary] = React.useState("");
    const [current, setCurrent] = React.useState(false);
    const [modal, setModal] = React.useState(false);
    const formSubmit = (e) => {
        e.preventDefault();
        var formdata = new FormData();
        formdata.append("resume_id", resume.id);
        formdata.append("employer", company);
        formdata.append("job_category", category);
        formdata.append("job_title", title);
        formdata.append("referee_name", refName);
        formdata.append("referee_contact", refEmail);
        formdata.append("referee_phone", refPhone);
        formdata.append("work_summary", summary);
        formdata.append("year_from", start);
        if (current === true) {
            formdata.append("current", start);
        } else {
            formdata.append("year_to", end);
        }
        RequestM('job-seeker/experiences', formdata).then((response) => {
            toast.success(response.message, alertOptions)
            setCategory("")
            setTitle("")
            setCompany("")
            setStart("")
            setEnd("")
            setRefName("")
            setRefEmail("")
            setRefPhone("")
            setSummary("")
            setModal(false)
            setFields(false)
        })
    }
    const currentWork = () => {

    }
    return (
        <>
            <Button onClick={() => setModal(true)} color="success" type="button" className="text-capitalize"
                    variant={"contained"}>+ Add Another</Button>
            <Modal
                size="lg"
                show={modal} centered
                onHide={() => setModal(false)}
                aria-labelledby="example-modal-sizes-title-lg">
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Add Experience
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={(e) => formSubmit(e)} className="px-2 py-2">
                        <div className="row">
                            <div className="col-lg-6">
                                <FormControl required className="mt-3" fullWidth>
                                    <InputLabel id="demo-simple-select-label">Job Category</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={category} onChange={(e) => setCategory(e.target.value)}
                                        label="Job Category">
                                        {fields.map((data) => {
                                            if (data.field === 'work_categories') {
                                                return <MenuItem key={data.value}
                                                                 value={data.value}>{data.value}</MenuItem>;
                                            }
                                        })}
                                    </Select>
                                </FormControl>
                                <TextField required className="mt-3 w-100" id="outlined-basic" label="Company Name"
                                           variant="outlined" value={company}
                                           onChange={(e) => setCompany(e.target.value)}/>
                                <TextField required className="mt-3 w-100" id="outlined-basic" label="Job Title"
                                           variant="outlined" value={title} onChange={(e) => setTitle(e.target.value)}/>
                                <div className="form-group my-2">
                                    <label htmlFor="start" className="text-xs">Start Date</label>
                                    <input type="date" required onChange={(e) => setStart(e.target.value)}
                                           className="form-control w-100" value={start}/>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <TextField required className="mt-3 w-100" id="outlined-basic" label="Referee Name"
                                           variant="outlined" onChange={(e) => setRefName(e.target.value)}
                                           value={refName}/>
                                <TextField required className="mt-3 w-100" id="outlined-basic" label="Referee Phone"
                                           variant="outlined" onChange={(e) => setRefPhone(e.target.value)}
                                           value={refPhone}/>
                                <TextField required className="mt-3 w-100" id="outlined-basic" label="Referee Email"
                                           variant="outlined" onChange={(e) => setRefEmail(e.target.value)}
                                           value={refEmail}/>
                                <div className="form-group my-2">
                                    <label htmlFor="start" className="text-xs">End Date</label>
                                    {current === false && <input required type="date" className="form-control w-100"
                                                                 onChange={(e) => setEnd(e.target.value)} value={end}/>}
                                    {current === true && <input disabled type="date" className="form-control w-100"
                                                                onChange={(e) => setEnd(e.target.value)} value={end}/>}
                                </div>
                                <div className="form-group text-xs my-2">
                                    <input type="checkbox" onClick={() => setCurrent(!current)} className="mr-2"
                                           id="current"/>
                                    <label htmlFor="current">Currently working here</label>
                                </div>
                            </div>
                            <div className="col-lg-12 my-2">
                            <textarea name="" id="" required value={summary}
                                      onChange={(e) => setSummary(e.target.value)}
                                      cols="30" rows="12" className="form-control"
                                      placeholder="Summery of work done"/>
                            </div>
                        </div>
                        <Button color="info" type="submit" className="text-capitalize"
                                variant={"contained"}>Save Experience</Button>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default AddExperience;
