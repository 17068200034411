import React from 'react';
import {Modal} from "react-bootstrap";
import {ImProfile} from "react-icons/im";
import Moment from "react-moment";
import {blobFD} from "../Request";
import {ComContext} from "../../routes/Employer";

const ViewPitch = ({pitch, data}) => {
    const [modal, setModal] = React.useState(false);
    const {user, token} = React.useContext(ComContext);
    const download = (id) => {
        blobFD(token, "employ/download-application/" + id, "Cover_letter.pdf")
    }
    return (
        <>
            <p onClick={() => setModal(true)}
               className="my-2 text-sm text-gray-450 d-flex text-hover-main cursor-pointer">
                <ImProfile
                    className="mr-3 w-5 h-5"/>View Cover Letter</p>
            <Modal
                size="lg"
                show={modal}
                onHide={() => setModal(false)} centered
                aria-labelledby="example-modal-sizes-title-lg">
                <Modal.Header closeButton>
                    <div className="d-flex justify-content-center align-items-center">
                        <h4 className="text-2xl">Cover Letter</h4>
                        <button onClick={(e) => download(data.application.id)}
                                className="btn btn-outline-primary text-xxs btn-sm py-1 px-2 ms-2">Download
                        </button>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="my-2 px-2">
                        <p className="my-1"><Moment
                            format="DD MMM YYYY">{data.application.created_at}</Moment></p>
                        <p className="my-1"><b>From: </b> {data.profile.name}</p>
                        <p className="my-1"><b>Applying for the job: </b> "{data.profile.title}"</p>
                    </div>
                    <div className="px-2 pt-2 pb-5 text-md font-weight-400" style={{whiteSpace: 'pre-wrap'}}>
                        {pitch}
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default ViewPitch;
