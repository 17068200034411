import React from 'react';
import icon from "../../assets/images/001m.png";

const NoApplications = () => {
    return (
        <div className="col-lg-8 m-auto">
            <div className="text-center py-5">
                <div className="icon w-25 m-auto mb-4"><img src={icon} className="img-fluid" alt=""/></div>
                <h4 className="text-two">No applications found!</h4>
                <p className="mb-0 text-sm">It seems you have not received any job applications yet.</p>
            </div>
        </div>
    );
};

export default NoApplications;
