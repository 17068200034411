import React from 'react';
import img1 from "../assets/A001inaz.jpg";
import img2 from "../assets/A002samah.jpg";
import img3 from "../assets/A003sarim.jpg";
import img4 from "../assets/A004hussain.jpg";
import icon1 from "../assets/about/signupA.png";
import icon2 from "../assets/about/subscribeA.png";
import icon3 from "../assets/about/postjobA.png";
import icon4 from "../assets/about/relaxA.png";
import icon5 from "../assets/about/recieveApplA.png";
import icon6 from "../assets/about/viewApplA.png";
import icon7 from "../assets/about/filterA.png";
import icon8 from "../assets/about/createResumeA.png";
import icon9 from "../assets/about/applyA.png";
import {Link} from "react-router-dom";

const About = () => {
    return (
        <div className="about">
            <div className="min-h-screen" style={{paddingTop: "5rem"}}>
                <div className="container pt-4">
                    <div className="bar my-2 py-2 text-center">
                        Hire The Right People. Find Them Here
                    </div>
                    <div className="bg-white mt-2 pb-4">
                        <h6 className="font-medium text-gray-700 text-center text-xl pt-4">How Jobsicle Works - For
                            Employers</h6>
                        <div
                            className="items text-center d-xl-flex justify-content-center align-items-center pt-4 pb-3 px-3">
                            <div className="item">
                                <div className="icon"><img src={icon1} alt=""/></div>
                                <div className="text">Sign up</div>
                            </div>
                            <div className="item">
                                <div className="icon"><img src={icon2} alt=""/></div>
                                <div className="text">Subscribe</div>
                            </div>
                            <div className="item">
                                <div className="icon"><img src={icon3} alt=""/></div>
                                <div className="text">Post jobs</div>
                            </div>
                            <div className="item">
                                <div className="icon"><img src={icon4} alt=""/></div>
                                <div className="text">Relax</div>
                            </div>
                            <div className="item">
                                <div className="icon"><img src={icon5} alt=""/></div>
                                <div className="text">Receive applications</div>
                            </div>
                            <div className="item">
                                <div className="icon"><img src={icon6} alt=""/></div>
                                <div className="text">View applications</div>
                            </div>
                            <div className="item">
                                <div className="icon"><img src={icon7} alt=""/></div>
                                <div className="text">Filter & shortlist</div>
                            </div>
                        </div>
                    </div>
                    <div className="bg-white mb-2 pb-3" style={{marginTop: '2px'}}>
                        <h5 className="text-pr text-xl my-0 text-center py-2">Talent can’t hide</h5>
                        <p className="text-center mt-0 mb-2">Advertise your jobs and promote your employer brand to the
                            candidates
                            you want. Post open positions across web, mobile and social media.</p>
                        <h5 className="text-pr text-xl mb-0 mt-3 text-center py-2">Engage candidates with your company
                            story</h5>
                        <p className="text-center mt-0 mb-2">Ensure candidates can discover and further research your
                            company before they apply, with your Jobsicle company profile.</p>
                        <div className="text-center text-sm my-4">
                            <Link to="/pricing"
                                  className="btn btn-outline-primary">View
                                Pricing and Packages</Link>
                        </div>
                    </div>
                    <div className="bg-white py-2">
                        <h6 className="font-medium text-gray-700 text-center text-xl my-0 py-3">How Jobsicle Works - For
                            Jobseekers</h6>
                        <div className="row items">
                            <div className="col-md-5 m-auto">
                                <div className="row mt-4 pb-3">
                                    <div className="col-md-4 item mt-0 mx-auto">
                                        <div className="icon"><img src={icon1} alt=""/></div>
                                        <div className="text">Sign up</div>
                                    </div>
                                    <div className="col-md-4 item mt-0 mx-auto">
                                        <div className="icon"><img src={icon8} alt=""/></div>
                                        <div className="text">Create your <br/>
                                            profile & resume
                                        </div>
                                    </div>
                                    <div className="col-md-4 item mt-0 mx-auto">
                                        <div className="icon"><img src={icon9} alt=""/></div>
                                        <div className="text">Apply online
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="bg-white py-3 mb-2" style={{marginTop: '2px'}}>
                        <h5 className="text-pr text-xl my-0 text-center py-2">Find jobs that's right for you</h5>
                        <p className="text-center mt-0 mb-2">Search all open job offers. Easily filter by what matters
                            to you. The right job is out there!</p>
                        <h5 className="text-pr text-xl mb-0 mt-3 text-center py-2">Create a professional resume</h5>
                        <p className="text-center mt-0 mb-2">Our online resume builder creates a professional resume to
                            go with your online applications. Impress the HR manager!</p>
                    </div>
                    <div className="bg-white py-4">
                        <h6 className="font-medium text-gray-700 text-center text-xl">The Team</h6>
                        <div className="flex items-center justify-center gap-8 flex-wrap">
                            <div className="py-4">
                                <img className="h-40 w-40  rounded-full"
                                     src={img1}
                                     alt=""/>
                                <h2 className="text-gray-700 pt-3 font-medium text-lg text-center">Ibrahim Inaz</h2>
                                <p className="text-gray-500 text-center text-sm">CEO</p>
                            </div>
                            <div className="py-4">
                                <img className="h-40 w-40  rounded-full"
                                     src={img2}
                                     alt=""/>
                                <h2 className="text-gray-700 pt-3 font-medium text-lg text-center">Ahmed Samah</h2>
                                <p className="text-gray-500 text-center text-sm">Founder</p>
                            </div>
                            <div className="py-4">
                                <img className="h-40 w-40  rounded-full"
                                     src={img3}
                                     alt=""/>
                                <h2 className="text-gray-700 pt-3 font-medium text-lg text-center">Ahmed Sarim</h2>
                                <p className="text-gray-500 text-center text-sm">Director</p>
                            </div>
                            <div className="py-4">
                                <img className="h-40 w-40  rounded-full"
                                     src={img4}
                                     alt=""/>
                                <h2 className="text-gray-700 pt-3 font-medium text-lg text-center">Hussain Waheedh</h2>
                                <p className="text-gray-500 text-center text-sm">Director</p>
                            </div>
                        </div>
                    </div>
                    <div className="mt-2 w-full px-3 py-3 bg-white text-center">
                        <h6 className="font-medium text-gray-700 mb-2 pt-3 text-xl">About Jobsicle</h6>
                        <p className="text-gray-500 text-sm space-y-1 px-md-3 pt-3">Jobsicle™ is a Maldivian startup
                            founded in 2016.
                            Within weeks of initial launch, Jobsicle gained tremendous support and popularity among
                            hundreds of companies and institutions in Maldives. Meanwhile, thousands of job-seekers from
                            across the country started using the website daily. The easy user interface and innovative
                            features of the website and mobile application attracted jobseekers and made Jobsicle stay
                            ahead of the game. In 2017 Jobsicle started expanding it's services and features. Started as
                            a free job listing website, Jobsicle adapted the subscription model in mid-2018 and
                            additional “pay per job” service in January 2019. The entire website and mobile application
                            was remodeled and new innovative features were introduced in January 2019. In November 2018,
                            Ibrahim Inaz, a pioneer in the Maldivian hospitality HR sector joined Jobsicle as CEO with a
                            broad vision and years of professional experience in the industry. Today Jobsicle is managed
                            by a dedicated team focused on business development, public relations, and innovative
                            technological advancements. With a positive spirit and refusing to give up by defeating the
                            odds, Jobsicle stands strong as the most reliable and loved career brand in Maldives.</p>
                    </div>
                    <div className="mt-2 w-full px-3 py-3 bg-white text-center">
                        <h6 className="font-medium text-gray-700 mb-2 pt-3 text-xl">Contact Us</h6>
                        <p className="text-gray-500 text-sm space-y-1 pt-3">To Post a Job, simply sign up as an
                            employer,
                            subscribe for a package and start publishing and managing job listings on your own. For
                            assistance, reach us on +960 7379494 or mail@jobsicle.mv</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default About;
