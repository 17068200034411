import React from 'react';
import {FormControl, InputLabel, MenuItem, Select, TextField, Button} from "@mui/material";
import "../employer/design.scss";
import {Link} from "react-router-dom";
import {alertOptions, apiURI, loader} from "../config";
import {toast} from "react-toastify";
import ReactPhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css';
import {AiFillEye, AiFillEyeInvisible} from 'react-icons/ai'
import {FormControl as FormControlB, InputGroup} from "react-bootstrap";
import {DatePicker} from 'react-rainbow-components';
import {AiFillCalendar} from 'react-icons/ai';

const Register = () => {
    const [role, setRole] = React.useState(1);
    const [company, setCompany] = React.useState();
    const [sector, setSector] = React.useState();
    const [address, setAddress] = React.useState();
    const [contact, setContact] = React.useState("");
    const [designation, setDesignation] = React.useState("");
    const [size, setSize] = React.useState();
    const [mobile, setMobile] = React.useState();
    const [officePhone, setOfficePhone] = React.useState();
    const [email, setEmail] = React.useState();
    const [newPassword, setNewPassword] = React.useState();
    const [conPassword, setConPassword] = React.useState();
    const [passwordMatch, setPasswordMatch] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [passType1, setPassType1] = React.useState(true);
    const [passType2, setPassType2] = React.useState(true);
    const [dob, setDob] = React.useState("");
    const formSubmit = (e) => {
        e.preventDefault();
        if (newPassword === conPassword) {
            setLoading(true)
            let myHeaders = new Headers();
            myHeaders.append("Accept", "application/json");
            let formdata = new FormData();
            formdata.append("role", role);
            formdata.append("email", email);
            formdata.append("password", conPassword);
            let requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formdata,
                redirect: 'follow'
            };
            fetch(apiURI + "register", requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.status === 200) {
                        const id = result.data.id;
                        const token = result.token;
                        if (role == 2) {
                            let myHeaders2 = new Headers();
                            myHeaders2.append("Accept", "application/json");
                            myHeaders2.append("Authorization", "Bearer " + token);
                            let formdata = new FormData();
                            formdata.append("user_id", id);
                            formdata.append("name", company);
                            formdata.append("email", email);
                            formdata.append("address", address);
                            formdata.append("contact", contact);
                            formdata.append("designation", designation);
                            formdata.append("phone", mobile);
                            formdata.append("hr_phone", officePhone);
                            formdata.append("size", size);
                            formdata.append("sector", sector);
                            let requestOptions2 = {
                                method: 'POST',
                                headers: myHeaders2,
                                body: formdata,
                                redirect: 'follow'
                            };
                            fetch(apiURI + "employ/companies", requestOptions2)
                                .then(response => response.text())
                                .then(result => {
                                    setTimeout(() => {
                                        toast.success(result.message, alertOptions)
                                        setLoading(false)
                                        localStorage.setItem('token', token);
                                        window.location.href = '/my-company'
                                    }, 3000)
                                })
                                .catch(error => console.log('error', error));
                        } else {
                            toast.success(result.message, alertOptions)
                            localStorage.setItem('token', token);
                            setEmail("");
                            setNewPassword("");
                            setConPassword("");
                            setLoading(false)
                            window.location.href = '/pref'
                        }
                    } else {
                        setLoading(false)
                        toast.error("Email already exist", alertOptions)
                    }
                })
                .catch(error => console.log('error', error));
        } else {
            setPasswordMatch(true);
        }
    }
    React.useEffect(() => {
        if (localStorage.getItem('token') !== null) {
            setTimeout(() => {
                window.location.href = '/'
            }, 2000)
        }
    })
    return (
        <>
            <div className="register d-flex justify-content-center align-items-center">
                <div className="col-md-6 col-10 col-lg-4 col-xxl-3">
                    <form onSubmit={(e) => formSubmit(e)} className="content">
                        {loading === true ?
                            loader("20rem")
                            :
                            <>
                                {role !== 2 &&
                                <>
                                    <h2 className="text-center mb-3">Create Account</h2>
                                    <hr/>
                                </>
                                }
                                <div className="mx-5 my-4">
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Role</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={role}
                                            defaultValue={role}
                                            onChange={(e) => setRole(e.target.value)}
                                            label="Role">
                                            <MenuItem value={1}>Jobseeker</MenuItem>
                                            <MenuItem value={2}>Company / Employer</MenuItem>
                                        </Select>
                                    </FormControl>

                                    {role === 2
                                    &&
                                    <>
                                        <TextField className="mt-3 w-100" id="outlined-basic" label="Company Name"
                                                   onChange={(e) => setCompany(e.target.value)}
                                                   variant="outlined" required value={company}/>
                                        <FormControl className="mt-3" required fullWidth>
                                            <InputLabel id="demo-simple-select-label">Sector</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={sector}
                                                onChange={(e) => setSector(e.target.value)}
                                                label="Role">
                                                <MenuItem value={"Private (Others)"}>Private (Others)</MenuItem>
                                                <MenuItem value={"Private (Tourism)"}>Private (Tourism)</MenuItem>
                                                <MenuItem value={"Government / Civil Service"}>Government / Civil
                                                    Service</MenuItem>
                                            </Select>
                                        </FormControl>
                                        <FormControl className="mt-3" required fullWidth>
                                            <InputLabel id="demo-simple-select-label">Size</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={size}
                                                onChange={(e) => setSize(e.target.value)}
                                                label="Size">
                                                <MenuItem value={"11 - 25 Staffs"}>11 - 25 Staffs</MenuItem>
                                                <MenuItem value={"26 - 100 Staffs"}>26 - 100 Staffs</MenuItem>
                                                <MenuItem value={"101 - 250 Staffs"}>101 - 250 Staffs</MenuItem>
                                                <MenuItem value={"501 - 1000 Staffs"}>501 - 1000 Staffs</MenuItem>
                                            </Select>
                                        </FormControl>
                                        <TextField className="my-3 w-100" id="outlined-basic" required label="Address"
                                                   onChange={(e) => setAddress(e.target.value)}
                                                   variant="outlined" value={address}/>
                                        <TextField className="w-100" id="outlined-basic" required
                                                   label="Contact Person"
                                                   onChange={(e) => setContact(e.target.value)}
                                                   variant="outlined" value={contact}/>
                                        <TextField className="my-3 w-100" id="outlined-basic" required
                                                   label="Designation"
                                                   onChange={(e) => setDesignation(e.target.value)}
                                                   variant="outlined" value={designation}/>
                                        <label htmlFor="mobile" className="text-xxs mb-1 text-muted">Mobile
                                            Number</label>
                                        <ReactPhoneInput
                                            value={mobile}
                                            country='mv'
                                            placeholder="Mobile Number"
                                            onChange={setMobile}
                                            inputExtraProps={{
                                                name: 'mobile',
                                                required: true,
                                                autoFocus: true
                                            }}
                                        /><br/>
                                        <label htmlFor="office" className="text-xxs mb-1 text-muted">Office
                                            Number</label>
                                        <ReactPhoneInput
                                            value={officePhone}
                                            country='mv'
                                            placeholder="Office Number"
                                            onChange={setOfficePhone}
                                            inputExtraProps={{
                                                name: 'Office',
                                                required: true,
                                                autoFocus: true
                                            }}
                                        />
                                    </>
                                    }
                                    <TextField className="mt-3 w-100" required id="outlined-basic" label="Email Address"
                                               onChange={(e) => setEmail(e.target.value)}
                                               variant="outlined" value={email}/>
                                    {role === 2
                                    &&
                                    <small className="text-muted">Please use the official or general email of HR or
                                        another office email. You
                                        will
                                        receive alerts and emails regarding job applications to this email. This email
                                        address
                                        cannot be changed after registration </small>
                                    }

                                    <InputGroup className="mt-3 text-sm">
                                        <FormControlB required placeholder="Password"
                                                      type={passType1 ? "password" : "text"}
                                                      onChange={(e) => setNewPassword(e.target.value)}
                                        />
                                        <InputGroup.Text id="basic-addon2" className="password-eye"
                                                         onClick={() => setPassType1(!passType1)}>
                                            {passType1 ? <AiFillEye/> : <AiFillEyeInvisible/>}
                                        </InputGroup.Text>
                                    </InputGroup>
                                    <InputGroup className="mt-3 text-sm">
                                        <FormControlB required placeholder="Repeat Password"
                                                      type={passType2 ? "password" : "text"}
                                                      onChange={(e) => setConPassword(e.target.value)}
                                                      onKeyUp={(e) => {
                                                          if (conPassword !== newPassword) {
                                                              setPasswordMatch(true);
                                                          } else {
                                                              setPasswordMatch(false)
                                                          }
                                                      }}
                                        />
                                        <InputGroup.Text id="basic-addon2" className="password-eye"
                                                         onClick={() => setPassType2(!passType2)}>
                                            {passType2 ? <AiFillEye/> : <AiFillEyeInvisible/>}
                                        </InputGroup.Text>
                                    </InputGroup>
                                    <p style={{fontSize: "11px", fontWeight: "bold"}}
                                       className="text-danger mb-3 mt-1 text-center">{passwordMatch !== false && "Password doesn't match"}</p>
                                    <Button type="submit" color="primary" className="w-100"
                                            variant={"contained"}>Proceed</Button>
                                    <div className="text-center small my-3 text-muted">Already have an account <Link
                                        to="/login">Login</Link></div>
                                    <div className="text-center text-muted">
                                        By creating an account, you are agreeing to our <a href="/tos" target="_blank">terms
                                        &
                                        conditions</a>
                                    </div>
                                </div>
                            </>}
                    </form>
                </div>
            </div>
        </>
    );
};

export default Register;
