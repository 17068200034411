import React from 'react';
import {Button, FormControl, InputLabel, MenuItem, Select, TextField} from "@mui/material";
import {Link} from "react-router-dom";
import {RequestG} from "../routes/Request";
import {alertOptions, loader} from "../config";
import {RequestGet, RequestM} from "../employer/Request";
import {UserContext} from "../routes/Private";
import {toast} from "react-toastify";
import ReactPhoneInput from "react-phone-input-2";

const Step = () => {
    const {user} = React.useContext(UserContext)
    const [name, setName] = React.useState("");
    const [phone, setPhone] = React.useState("");
    const [gender, setGender] = React.useState("");
    const [nationality, setNationality] = React.useState("");
    const [birth, setBirth] = React.useState("");
    const [status, setStatus] = React.useState("");
    const [qualification, setQualification] = React.useState("");
    const [experience, setExperience] = React.useState("");
    const [location, setLocation] = React.useState("");
    const [fields, setFields] = React.useState(false);
    const [profile, setProfile] = React.useState(false);
    const [pageOption, setPageOption] = React.useState(1);
    const formSubmit = (e) => {
        e.preventDefault();
        let formdata = new FormData();
        formdata.append("user_id", user.id);
        formdata.append("name", name);
        formdata.append("phone", phone);
        formdata.append("gender", gender);
        formdata.append("nationality", nationality);
        formdata.append("dob", birth);
        formdata.append("employment_status", status);
        formdata.append("qualification", qualification);
        formdata.append("experience", experience);
        formdata.append("address", location);
        RequestM('job-seeker/profile', formdata).then((response) => {
            if (response.status == 200) {
                toast.success(response.message, alertOptions)
                setTimeout(() => {
                    if (pageOption == 1) {
                        window.location.href = '/profile'
                    } else {
                        window.location.href = '/'
                    }
                }, 3000)
            } else {
                toast.success(response.message, alertOptions)
                setTimeout(() => {
                    if (pageOption == 1) {
                        window.location.href = '/profile'
                    } else {
                        window.location.href = '/'
                    }
                }, 3000)
            }
        })
    }
    React.useEffect(() => {
        if (fields === false && profile === false) {
            RequestG('fields').then((response) => {
                setFields(response)
            })
            RequestGet('user/job-seeker-preference-usr').then((data) => {
                if (parseInt(data.status) === 404) {
                    window.location.href = '/pref'
                }
            })
            RequestGet('user/job-seeker-profile-usr').then((response) => {
                if (response.status != 404) {
                    window.location.href = '/profile'
                }
            })
        }
    }, [fields])
    if (fields !== false) {
        return (
            <>
                <div className="login d-flex justify-content-center align-items-center">
                    <div className="col-md-6 col-10 col-lg-4">
                        <form onSubmit={(e) => formSubmit(e)} className="content rounded">
                            <h2 className="text-center mb-3">Basic Information </h2>
                            <hr/>
                            <div className="mx-5 my-4">
                                <p className="my-3 text-dark text-center">Let's get started by telling a bit about
                                    you</p>
                                <TextField required className="my-3 w-100" id="outlined-basic" label="Full Name"
                                           onChange={(e) => setName(e.target.value)}
                                           variant="outlined" value={name}/>
                                <ReactPhoneInput
                                    value={phone}
                                    country='mv'
                                    placeholder="Mobile Number"
                                    onChange={setPhone}
                                    inputExtraProps={{
                                        name: 'Mobile',
                                        required: true,
                                        autoFocus: true
                                    }}
                                />
                                <FormControl required className="mt-3" fullWidth>
                                    <InputLabel id="demo-simple-select-label">Gender</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={gender}
                                        onChange={(e) => setGender(e.target.value)}
                                        label="Gender">
                                        {fields.map((data) => {
                                            if (data.field === 'gender') {
                                                return <MenuItem key={data.value}
                                                                 value={data.value}>{data.value}</MenuItem>;
                                            }
                                        })}
                                    </Select>
                                </FormControl>
                                <FormControl required className="mt-3" fullWidth>
                                    <InputLabel id="demo-simple-select-label">Nationality</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={nationality}
                                        onChange={(e) => setNationality(e.target.value)}
                                        label="Nationality">
                                        {fields.map((data) => {
                                            if (data.field === 'nationality') {
                                                return <MenuItem key={data.value}
                                                                 value={data.value}>{data.value}</MenuItem>;
                                            }
                                        })}
                                    </Select>
                                </FormControl>
                                <div className="form-group mx-0 row mt-3 w-100">
                                    <label htmlFor="" className="my-0 mx-0 text-xs px-0">Date of Birth <span
                                        className="text-small">*</span></label>
                                    <input type="date" required className="form-control mt-3 w-100" value={birth}
                                           onChange={(e) => setBirth(e.target.value)}/>
                                </div>
                                <FormControl required className="mt-3" fullWidth>
                                    <InputLabel id="demo-simple-select-label">Employment Status</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={status}
                                        onChange={(e) => setStatus(e.target.value)}
                                        label="Employment Status">
                                        {fields.map((data) => {
                                            if (data.field === 'rate_employer_role') {
                                                return <MenuItem key={data.value}
                                                                 value={data.value}>{data.value}</MenuItem>;
                                            }
                                        })}
                                    </Select>
                                </FormControl>
                                <FormControl required className="mt-3" fullWidth>
                                    <InputLabel id="demo-simple-select-label">Highest Qualification</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={qualification}
                                        onChange={(e) => setQualification(e.target.value)}
                                        label="Highest Qualification">
                                        {fields.map((data) => {
                                            if (data.field === 'qualifications') {
                                                return <MenuItem key={data.value}
                                                                 value={data.value}>{data.value}</MenuItem>;
                                            }
                                        })}
                                    </Select>
                                </FormControl>

                                <FormControl required className="mt-3" fullWidth>
                                    <InputLabel id="demo-simple-select-label">Total work Experience</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={experience}
                                        onChange={(e) => setExperience(e.target.value)}
                                        label="Total work Experience">
                                        {fields.map((data) => {
                                            if (data.field === 'experience_duration') {
                                                return <MenuItem key={data.value}
                                                                 value={data.value}>{data.value}</MenuItem>;
                                            }
                                        })}
                                    </Select>
                                </FormControl>

                                <TextField required className="mt-3 w-100" id="outlined-basic" label="Present Location"
                                           onChange={(e) => setLocation(e.target.value)}
                                           variant="outlined" value={location}/>
                                <p className="py-3 mb-0">All Fields marked with * are required</p>
                                <Button color="info" type="submit" className="w-100 mt-0"
                                        variant={"contained"}>Proceed to Create Full Profile</Button>
                                <Button color="info" onClick={(e) => setPageOption(2)}
                                        className="w-100 my-3" type="submit"
                                        variant={"contained"}>Save and Skip Profile for Now
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
            </>
        );
    } else {
        return <>
            <div className="login d-flex justify-content-center align-items-center">
                <div className="col-md-6 col-10 col-lg-4">
                    {loader("20rem")}
                </div>
            </div>
        </>
    }
};

export default Step;
