import React from 'react';
import {WorkOutlineOutlined} from "@mui/icons-material";
import {
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Input,
    IconButton,
    FormGroup,
    FormControlLabel,
    Checkbox
} from "@mui/material";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import Email from "../warning/Email";
import {ComContext} from "../../routes/Employer";
import VerifyIcon from "../warning/VerifyIcon";
import {RequestM} from "../Request";
import {alertOptions, loader} from "../../config";
import {toast} from "react-toastify";

const CreateJobItem = ({fields, data, company, subscription}) => {
    const [update, setUpdate] = React.useState(false);
    const {user} = React.useContext(ComContext);
    const [title, setTitle] = React.useState("");
    const [content, setContent] = React.useState('')
    const [due, setDue] = React.useState("");
    const [jobAd, setJobAd] = React.useState("");
    const [applicationPdf, setApplicationPdf] = React.useState("");
    const [photographOption, setPhotographOption] = React.useState(false);
    const [educationalOption, setEducationalOption] = React.useState(false);
    const [employmentOption, setEmploymentOption] = React.useState(false);
    const [identityOption, setIdentityOption] = React.useState(false);
    const [policeOption, setPoliceOption] = React.useState(false);
    const [drivingOption, setDrivingOption] = React.useState(false);
    const [specificCountryOption, setSpecificCountryOption] = React.useState(0);
    const [notOnlineApplication, setNotOnlineApplication] = React.useState(0);
    const [sector, setSector] = React.useState("");
    const [category, setCategory] = React.useState("");
    const [type, setType] = React.useState("");
    const [reference, setReference] = React.useState("");
    const [positions, setPositions] = React.useState("");
    const [qualification, setQualification] = React.useState("");
    const [experience, setExperience] = React.useState("");
    const [salary, setSalary] = React.useState("");
    const [location, setLocation] = React.useState("");
    const [interview, setInterview] = React.useState("");
    const [email, setEmail] = React.useState("");
    const formSubmit = (e) => {
        e.preventDefault();
        setUpdate(true);
        const itemRequired = {
            photographOption,
            educationalOption,
            employmentOption,
            identityOption,
            policeOption,
            drivingOption
        };
        let formdata = new FormData();
        formdata.append("subs_id", subscription.id);
        formdata.append("company_id", data.company.id);
        formdata.append("title", title);
        if (email !== "") {
            formdata.append("req_email", email);
        }
        formdata.append("description", content);
        formdata.append("sector", sector);
        formdata.append("category", category);
        formdata.append("salary_range", salary);
        formdata.append("company_name", company);
        formdata.append("expired_at", due);
        formdata.append("attachment", jobAd);
        formdata.append("application_form", applicationPdf);
        formdata.append("prevent_online_application", notOnlineApplication);
        formdata.append("prevent_international_applicants", specificCountryOption);
        formdata.append("type", type);
        formdata.append("ref_no", reference);
        formdata.append("qualification", qualification);
        formdata.append("experience", experience);
        formdata.append("location", location);
        formdata.append("required_items", JSON.stringify(itemRequired));
        if (interview !== "") {
            formdata.append("interview_starts_on", interview);
        }
        if (positions !== "") {
            formdata.append("positions", positions);
        }
        RequestM('jobs', formdata).then((response) => {
            if (response.status !== null) {
                if (response.status === 201) {
                    toast.success(response.message, alertOptions);
                    setTimeout(() => {
                        window.location.href = '/jobs'
                    }, 2000)
                } else {
                    setUpdate(false);
                    toast.warning(response.message.category[0], alertOptions);
                    toast.warning(response.message.description[0], alertOptions);
                    toast.warning(response.message.experience[0], alertOptions);
                    toast.warning(response.message.location[0], alertOptions);
                    toast.warning(response.message.positions[0], alertOptions);
                    toast.warning(response.message.qualification[0], alertOptions);
                    toast.warning(response.message.salary_range[0], alertOptions);
                    toast.warning(response.message.sector[0], alertOptions);
                    toast.warning(response.message.title[0], alertOptions);
                    toast.warning(response.message.type[0], alertOptions);
                }
            }
            setUpdate(false);
        })
    }
    const onChangeJobAd = (e) => {
        if (e.target.files[0].type === 'application/pdf') {
            setJobAd(e.target.files[0])
        } else {
            toast.error('File Format Not Support', alertOptions);
        }
    }
    const onChangeApplicationPdf = (e) => {
        const fileType = e.target.files[0].type;
        if (fileType === 'application/pdf' || fileType === 'application/msword' || fileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
            setApplicationPdf(e.target.files[0])
        } else {
            toast.error('File Format Not Support', alertOptions);
        }
    }
    if (data !== false && fields !== false) {
        return (
            <>
                <Email/>
                <div className="content p-2">
                    <div className="d-flex py-2 px-2">
                        <WorkOutlineOutlined className="me-2 text-two"/>
                        <h4 className="heading text-main my-0">Post a Job</h4>
                    </div>
                    {user.email_verified_at !== null && subscription !== null ?
                        <form onSubmit={(e) => formSubmit(e)} className="my-4">
                            <div className="mx-3 mb-2">
                                {update === true && loader("3rem")}
                            </div>
                            <div className="row mx-0">
                                <div className="col-md-8">
                                    <input type="text" className="form-control disabled" disabled value={company}/>
                                    <div className="row">
                                        <div className="col-md-8">
                                            <TextField className="mt-3 w-100" required id="outlined-basic"
                                                       label="Job Title"
                                                       onChange={(e) => setTitle(e.target.value)}
                                                       variant="outlined" value={title}/>
                                        </div>
                                        <div className="col-md-4">
                                            <TextField className="mt-3 w-100" required id="outlined-basic"
                                                       label="Due Date"
                                                       onChange={(e) => setDue(e.target.value)}
                                                       InputLabelProps={{
                                                           shrink: true,
                                                       }}
                                                       variant="outlined" value={due} type="datetime-local"/>
                                        </div>
                                        <div className="col-md-4">
                                            <TextField className="mt-3 w-100" id="outlined-basic"
                                                       label="Interview Date"
                                                       onChange={(e) => setInterview(e.target.value)}
                                                       InputLabelProps={{
                                                           shrink: true,
                                                       }}
                                                       variant="outlined" value={interview} type="datetime-local"/>
                                        </div>
                                        <div className="col-md-8">
                                            <TextField className="mt-3 w-100" id="outlined-basic"
                                                       label="HR email (optional)"
                                                       onChange={(e) => setEmail(e.target.value)}
                                                       variant="outlined" value={email}/>
                                        </div>
                                        <div className="col-md-12 mt-3">
                                            <textarea name="" id="" cols="30"
                                                      onChange={(e) => setContent(e.target.value)} rows="18"
                                                      className="form-control"
                                                      value={content} required
                                                      placeholder="Job Description. Please provide sufficient information about the position and how to apply"/>
                                        </div>
                                        <div className="col-md-4">
                                            <FormControl className="mt-3" fullWidth>
                                                <InputLabel id="demo-simple-select-label">Sector</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={sector} required
                                                    onChange={(e) => setSector(e.target.value)}
                                                    label="Sector">
                                                    <MenuItem value={"Private (Tourism)"}>Private (Tourism)</MenuItem>
                                                    <MenuItem value={"Private (Others)"}>Private (Others)</MenuItem>
                                                    <MenuItem value={"Government / Civil Service"}>Government / Civil
                                                        Service</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </div>
                                        <div className="col-md-4">
                                            <FormControl className="mt-3" fullWidth>
                                                <InputLabel id="demo-simple-select-label">Category</InputLabel>
                                                <Select required
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={category}
                                                        onChange={(e) => setCategory(e.target.value)}
                                                        label="Category">
                                                    {fields.map((data) => {
                                                        if (data.field === 'work_categories') {
                                                            return <MenuItem key={data.value}
                                                                             value={data.value}>{data.value}</MenuItem>;
                                                        }
                                                    })}
                                                </Select>
                                            </FormControl>
                                        </div>
                                        <div className="col-md-4">
                                            <FormControl className="mt-3" fullWidth>
                                                <InputLabel id="demo-simple-select-label">Job type</InputLabel>
                                                <Select required
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={type}
                                                        onChange={(e) => setType(e.target.value)}
                                                        label="Job Type">
                                                    {fields.map((data) => {
                                                        if (data.field === 'job_types') {
                                                            return <MenuItem key={data.value}
                                                                             value={data.value}>{data.value}</MenuItem>;
                                                        }
                                                    })}
                                                </Select>
                                            </FormControl>
                                        </div>
                                        <div className="col-md-4">
                                            <TextField className="mt-3 w-100" id="outlined-basic" label="Reference No."
                                                       onChange={(e) => setReference(e.target.value)}
                                                       variant="outlined" value={reference}/>
                                        </div>
                                        <div className="col-md-4">
                                            <FormControl className="mt-3" fullWidth>
                                                <InputLabel id="demo-simple-select-label">No of positions</InputLabel>
                                                <Select required
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={positions}
                                                        onChange={(e) => setPositions(e.target.value)}
                                                        label="positions">
                                                    <MenuItem value={1}>01</MenuItem>
                                                    <MenuItem value={2}>02</MenuItem>
                                                    <MenuItem value={3}>03</MenuItem>
                                                    <MenuItem value={4}>04</MenuItem>
                                                    <MenuItem value={5}>05</MenuItem>
                                                    <MenuItem value={6}>06</MenuItem>
                                                    <MenuItem value={7}>07</MenuItem>
                                                    <MenuItem value={8}>08</MenuItem>
                                                    <MenuItem value={9}>09</MenuItem>
                                                    <MenuItem value={10}>10</MenuItem>
                                                    <MenuItem value={"11+"}>11+</MenuItem>
                                                    <MenuItem value={"Not stated"}>Not stated</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </div>
                                        <div className="col-md-4">
                                            <FormControl className="mt-3" fullWidth>
                                                <InputLabel id="demo-simple-select-label">Qualification</InputLabel>
                                                <Select required
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={qualification}
                                                        onChange={(e) => setQualification(e.target.value)}
                                                        label="qualification">
                                                    {fields.map((data) => {
                                                        if (data.field === 'qualifications') {
                                                            return <MenuItem key={data.value}
                                                                             value={data.value}>{data.value}</MenuItem>;
                                                        }
                                                    })}
                                                </Select>
                                            </FormControl>
                                        </div>
                                        <div className="col-md-4">
                                            <FormControl className="mt-3" fullWidth>
                                                <InputLabel id="demo-simple-select-label">Experience</InputLabel>
                                                <Select required
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={experience}
                                                        onChange={(e) => setExperience(e.target.value)}
                                                        label="experience">
                                                    {fields.map((data) => {
                                                        if (data.field === 'experience') {
                                                            return <MenuItem key={data.value}
                                                                             value={data.value}>{data.value}</MenuItem>;
                                                        }
                                                    })}
                                                </Select>
                                            </FormControl>
                                        </div>
                                        <div className="col-md-4">
                                            <FormControl className="mt-3" fullWidth>
                                                <InputLabel id="demo-simple-select-label">Salary Range</InputLabel>
                                                <Select required
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={salary}
                                                        onChange={(e) => setSalary(e.target.value)}
                                                        label="salary">
                                                    {fields.map((data) => {
                                                        if (data.field === 'salary_range') {
                                                            return <MenuItem key={data.value}
                                                                             value={data.value}>{data.value}</MenuItem>;
                                                        }
                                                    })}
                                                </Select>
                                            </FormControl>
                                        </div>
                                        <div className="col-md-4">
                                            <FormControl className="mt-3" fullWidth>
                                                <InputLabel id="demo-simple-select-label">Work Location</InputLabel>
                                                <Select required
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={location}
                                                        onChange={(e) => setLocation(e.target.value)}
                                                        label="location">
                                                    {fields.map((data) => {
                                                        if (data.field === 'preferred_locations') {
                                                            return <MenuItem key={data.value}
                                                                             value={data.value}>{data.value}</MenuItem>;
                                                        }
                                                    })}
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="card rounded-0">
                                        <div className="card-body p-0">
                                            <div className="topper text-center">Attach Documents</div>
                                            <div className="content text-two">
                                                <div className="text-center my-2">
                                                    <p className="my-0">Attach a Job Ad (PDF)</p>
                                                    <label htmlFor="icon-button-file">
                                                        <Input onChange={(e) => onChangeJobAd(e)}
                                                               className="d-none"
                                                               id="icon-button-file" type="file"/>
                                                        {jobAd && <p>{jobAd.name} <b
                                                            className="text-warning font-medium">Change</b></p>}
                                                        {!jobAd &&
                                                        <>
                                                            <IconButton className="bg-info text-white" color="info"
                                                                        aria-label="upload pdf" component="span">
                                                                <AttachFileIcon/>
                                                            </IconButton><br/>
                                                            <small>Allowed, 5MB/PDF</small>
                                                        </>
                                                        }
                                                    </label>
                                                </div>
                                                <div className="text-center my-2">
                                                    <p className="my-0">Attach Application Form (PDF or DOC, DOCX)</p>
                                                    <label className="my-0" htmlFor="icon-button-file2">
                                                        <Input onChange={(e) => onChangeApplicationPdf(e)}
                                                               className="d-none" id="icon-button-file2" type="file"/>
                                                        {applicationPdf && <p>{applicationPdf.name} <b
                                                            className="text-warning font-medium">Change</b></p>}
                                                        {!applicationPdf &&
                                                        <>
                                                            <IconButton className="bg-info text-white" color="info"
                                                                        aria-label="upload pdf" component="span">
                                                                <AttachFileIcon/>
                                                            </IconButton><br/>
                                                            <small>Allowed, 5MB/PDF</small>
                                                        </>
                                                        }
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card rounded-0 mt-3 pb-2">
                                        <div className="card-body p-0">
                                            <div className="topper text-center">Required Items</div>
                                            <div className="content text-two p-3">
                                                <FormGroup>
                                                    <FormControlLabel
                                                        onChange={(e) => setPhotographOption(!photographOption)}
                                                        className="checkboxText" value={photographOption}
                                                        control={<Checkbox/>}
                                                        label="Photograph"/>
                                                    <FormControlLabel className="checkboxText" control={<Checkbox/>}
                                                                      onChange={(e) => setEducationalOption(!educationalOption)}
                                                                      label="Educational Certificates"/>
                                                    <FormControlLabel className="checkboxText" control={<Checkbox/>}
                                                                      onChange={(e) => setEmploymentOption(!employmentOption)}
                                                                      label="Employment Ref Letters"/>
                                                    <FormControlLabel className="checkboxText" control={<Checkbox/>}
                                                                      onChange={(e) => setIdentityOption(!identityOption)}
                                                                      label="Identity Card"/>
                                                    <FormControlLabel className="checkboxText" control={<Checkbox/>}
                                                                      onChange={(e) => setPoliceOption(!policeOption)}
                                                                      label="Police Report"/>
                                                    <FormControlLabel className="checkboxText" control={<Checkbox/>}
                                                                      onChange={(e) => setDrivingOption(!drivingOption)}
                                                                      label="Driving License"/>
                                                </FormGroup>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-3 border-custom p-3">
                                        <FormControlLabel className="checkboxText" value={specificCountryOption}
                                                          onChange={(e) => {
                                                              if (specificCountryOption !== 1) {
                                                                  setSpecificCountryOption(1)
                                                              } else {
                                                                  setSpecificCountryOption(0)
                                                              }
                                                          }}
                                                          control={<Checkbox/>}
                                                          label="Allow Maldivians Only"/>
                                        <small className="small-3 d-block text-warning alert-link">This will stop online
                                            applications from
                                            foreigners</small>
                                        <FormControlLabel className="checkboxText mt-3" value={notOnlineApplication}
                                                          onChange={(e) => {
                                                              if (notOnlineApplication !== 1) {
                                                                  setNotOnlineApplication(1)
                                                              } else {
                                                                  setNotOnlineApplication(0)
                                                              }
                                                          }}
                                                          control={<Checkbox/>}
                                                          label="Don't allow Online Applications"/>
                                        <small className="small-3 d-block text-warning alert-link">This will stop
                                            candidates
                                            from
                                            sending online
                                            applications through Jobsicle website or app</small>
                                    </div>
                                </div>
                            </div>
                            <div className="text-left my-3 mx-2">
                                <Button color="info" type="submit" className="text-capitalize" variant={"contained"}>
                                    Submit Job for Approval
                                </Button>
                            </div>
                        </form> : <VerifyIcon/>}
                </div>
            </>
        )
    } else {
        return loader("20rem")
    }
};

export default CreateJobItem;
