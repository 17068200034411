import React from 'react';
import {ReceiptOutlined} from "@mui/icons-material";
import {alertOptions, loader} from "../config";
import {Button, FormControl, InputLabel, MenuItem, Select, TextField} from "@mui/material";
import Email from "./warning/Email";
import NoSubscription from "./warning/NoSubscription";
import YaSubscription from "./warning/YaSubscription";
import {ComContext} from "../routes/Employer";
import {RequestGet, RequestM} from "./Request";
import {toast} from "react-toastify";
import ReactPhoneInput from 'react-phone-input-2';
import {MdCameraAlt} from 'react-icons/md';
import 'react-phone-input-2/lib/style.css';
import "./design.scss";
import CompanyProgress from "./items/CompanyProgress";

const MyCompany = () => {
    const {user} = React.useContext(ComContext);
    const [company, setCompany] = React.useState();
    const [companySize, setCompanySize] = React.useState();
    const [sector, setSector] = React.useState();
    const [processTime, setProcessTime] = React.useState();
    const [email, setEmail] = React.useState();
    const [location, setLocation] = React.useState();
    const [hrEmail, setHrEmail] = React.useState();
    const [hrPhone, setHrPhone] = React.useState();
    const [website, setWebsite] = React.useState();
    const [overview, setOverview] = React.useState();
    const [benifit, setBenifit] = React.useState();
    const [contact, setContact] = React.useState("");
    const [foundedOn, setFoundedOn] = React.useState("");
    const [designation, setDesignation] = React.useState("");
    const [imgPreview, setImgPreview] = React.useState(null);
    const [data, setData] = React.useState(false);
    const [countOText, setCountOText] = React.useState(0);
    const [countBText, setCountBText] = React.useState(0);
    React.useEffect(() => {
        if (data === false) {
            RequestGet('employ/company').then((response) => {
                if (response !== undefined) {
                    setCompany(response.company.name)
                    setCompanySize(response.company.size)
                    setEmail(response.company.email)
                    setSector(response.company.sector)
                    setProcessTime(response.company.process)
                    setLocation(response.company.location)
                    setHrEmail(response.company.hr_email)
                    setHrPhone(response.company.hr_phone)
                    setWebsite(response.company.website)
                    setOverview(response.company.introduction)
                    setBenifit(response.company.why_work_with_us)
                    setContact(response.company.contact)
                    setDesignation(response.company.designation)
                    setFoundedOn(response.company.founded_on)
                    if (response.company.logo !== null) {
                        setImgPreview(response.company.logo)
                    }
                    setData(response)
                    console.log(response.company.size)
                }
            })
        }
    }, [data])
    const onImageChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            let imgData = new FormData();
            imgData.append('_method', 'put');
            imgData.append('user_id', data.company.user_id);
            imgData.append('logo', event.target.files[0]);
            RequestM('employ/companiesImg/' + data.company.id, imgData).then((response) => {
                toast.success(response.message, alertOptions)
            })
            let reader = new FileReader();
            reader.onload = (e) => {
                setImgPreview(e.target.result)
            };
            reader.readAsDataURL(event.target.files[0]);
        }
    }
    const formSubmit = (e) => {
        e.preventDefault();
        let formdata = new FormData();
        formdata.append("_method", "put");
        formdata.append("user_id", user.id);
        formdata.append("name", company);
        formdata.append("website", website);
        formdata.append("size", companySize);
        formdata.append("sector", sector);
        formdata.append("process", processTime);
        formdata.append("email", email);
        formdata.append("address", location);
        formdata.append("location", location);
        formdata.append("hr_email", hrEmail);
        formdata.append("hr_phone", hrPhone);
        formdata.append("introduction", overview);
        formdata.append("why_work_with_us", benifit);
        formdata.append("founded_on", foundedOn);
        formdata.append("contact", contact);
        formdata.append("designation", designation);
        RequestM('employ/companies/' + data.company.id, formdata).then((response) => {
            if (response.status === 201) {
                toast.success(response.message, alertOptions)
            } else {
                toast.warning(response.message, alertOptions)
            }
        })
    }
    if (data !== false) {
        return (
            <>
                <Email/>
                {data.annual !== null || data.payper !== null ? <YaSubscription data={data}/> : <NoSubscription/>}
                <CompanyProgress data={data}/>
                <form onSubmit={(e) => formSubmit(e)} className="content mt-2 p-3">
                    <div className="d-flex">
                        <ReceiptOutlined className="me-2 text-two"/>
                        <h4 className="heading text-main my-0">Company Profile</h4>
                    </div>
                    <div className="row mt-3 mb-1 text-sm">
                        <div className="col-lg-10 text-left">
                            Talent can’t hide. A well crafted company profile attracts qualified Job-seekers.
                        </div>
                        <div className="col-lg-2 text-right">
                            <a href={`/viewCompany/${data.company.id}`} className="text-main" target="_blank">Public
                                View</a>
                        </div>
                    </div>
                    <hr className="mb-4 hr-color"/>
                    <div className="row">
                        <div className="col-lg-9">
                            <TextField className="w-100" id="outlined-basic" label="Company Name"
                                       onChange={(e) => setCompany(e.target.value)}
                                       variant="outlined" value={company}/>
                            <div className="row">
                                <div className="col-md-6 mt-2 mb-1">
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Company Size</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={companySize}
                                            onChange={(e) => setCompanySize(e.target.value)}
                                            label="Company Size">
                                            <MenuItem value={"1 - 10 Staffs"}>11 - 25 Staffs</MenuItem>
                                            <MenuItem value={"11 - 25 Staffs"}>11 - 25 Staffs</MenuItem>
                                            <MenuItem value={"26 - 100 Staffs"}>26 - 100 Staffs</MenuItem>
                                            <MenuItem value={"101 - 250 Staffs"}>101 - 250 Staffs</MenuItem>
                                            <MenuItem value={"501 - 1000 Staffs"}>501 - 1000 Staffs</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="col-md-6 mt-2 mb-1">
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Sector</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={sector}
                                            onChange={(e) => setSector(e.target.value)}
                                            label="Sector">
                                            <MenuItem value={"Private (Tourism)"}>Private (Tourism)</MenuItem>
                                            <MenuItem value={"Private (Others)"}>Private (Others)</MenuItem>
                                            <MenuItem value={"Government / Civil Service"}>Government / Civil
                                                Service</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 my-1">
                                    <TextField className="w-100" id="outlined-basic"
                                               label="Application Processing Time"
                                               onChange={(e) => setProcessTime(e.target.value)}
                                               variant="outlined" value={processTime}/>
                                </div>
                                <div className="col-md-6 my-1">
                                    <TextField className="w-100" id="outlined-basic" label="Website"
                                               defaultValue={website}
                                               onChange={(e) => setWebsite(e.target.value)}
                                               variant="outlined" value={website}/>
                                </div>
                                <div className="col-md-6 my-1">
                                    <TextField className="w-100" id="outlined-basic" label="HR Email"
                                               onChange={(e) => setHrEmail(e.target.value)}
                                               variant="outlined" value={hrEmail}/>
                                </div>
                                <div className="col-md-6 my-1">
                                    <TextField className="w-100" type="date" id="outlined-basic" label="Founded On"
                                               onChange={(e) => setFoundedOn(e.target.value)}
                                               variant="outlined" value={foundedOn}/>
                                </div>
                                <div className="col-md-6 mt-1 mb-3">
                                    <ReactPhoneInput
                                        value={hrPhone}
                                        country='mv'
                                        onChange={setHrPhone}
                                        inputExtraProps={{
                                            name: 'phone',
                                            required: true,
                                            autoFocus: true
                                        }}
                                    />
                                </div>
                                <div className="col-md-12 my-1">
                                    <TextField className="w-100" id="outlined-basic" label="Office Location"
                                               onChange={(e) => setLocation(e.target.value)}
                                               variant="outlined" value={location}/>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 mb-3 mb-lg-0">
                            <div className="profileImg2">
                                <img src={imgPreview} alt="" className="imgPreview border-1"/>
                                <input type="file" className="d-none" onChange={(event) => onImageChange(event)}
                                       id="logo"/>
                                <label htmlFor="logo" className="btn btn-primary d-block btn-sm mt-1">Change
                                    Logo</label>
                            </div>
                        </div>
                    </div>
                    <div className="">
                        <div className="d-flex mt-4">
                            <ReceiptOutlined className="me-2 text-two"/>
                            <h4 className="heading text-main my-0">Your Story</h4>
                        </div>
                        <p className="mt-2 mb-1 small">
                            Briefly introduce your business or company. Mention why Job-seekers should choose you, the
                            staff benefits, etc
                        </p>
                        <hr className="mb-4 hr-color"/>
                        <textarea name="" id="" cols="30" rows="7"
                                  maxLength={2500 - company.length} onChange={(e) => {
                            setOverview(e.target.value);
                            setCountOText((e.target.value.length - company.length))
                        }}
                                  className="mt-2 form-control" placeholder="Your Story / Overview"
                                  value={overview !== undefined ? overview : ""}/>
                        <div
                            className="text-right mb-3 w-100 text-two small">{overview !== undefined ? overview !== null ? 2500 - overview.length : 2500 : 2500} characters
                            left
                        </div>
                        <textarea name="" id="" cols="30" rows="7"
                                  maxLength={2500 - company.length} onChange={(e) => {
                            setBenifit(e.target.value);
                            setCountBText((e.target.value.length - company.length))
                        }}
                                  className="mt-2 form-control" placeholder="Common Staff Benefits"
                                  value={benifit !== undefined ? benifit : ""}/>
                        <div
                            className="text-right w-100 text-two small">{benifit !== undefined ? benifit !== null ? 2500 - benifit.length : 2500 : 2500} characters
                            left
                        </div>
                    </div>
                    <div className="text-left">
                        <Button color="info" type="submit" className="saveMyCompany" variant={"contained"}>Save</Button>
                    </div>
                </form>
            </>
        );
    } else {
        return loader("20rem");
    }
};

export default MyCompany;
