import React from 'react';
import {WorkOutlineOutlined} from "@mui/icons-material";
import {alertOptions, loader} from "../config";
import {RequestGet, RequestM} from "../employer/Request";
import {Button} from "@mui/material";
import {toast} from "react-toastify";
import ProfileStatus from "./alerts/ProfileStatus";

const Introduction = () => {
    const [data, setData] = React.useState(null);
    const [profile, setProfile] = React.useState(null);
    const [intro, setIntro] = React.useState(null);
    const [oldIntro, setOldIntro] = React.useState(null);
    React.useEffect(() => {
        if (data === null) {
            RequestGet("user/resumes-usr").then((response) => {
                if (response.status == 200) {
                    setOldIntro(response.data.introduction)
                    setIntro(response.data.introduction)
                }
                setData(response);
            })
            RequestGet('user/profile').then((response) => {
                if (response.preference == null) {
                    window.location.href = '/pref'
                }
                if (response.profile == null) {
                    window.location.href = '/step'
                }
                setProfile(response);
            })
        }
    }, [data])
    const formSubmit = (e) => {
        e.preventDefault();
        var formdata = new FormData();
        formdata.append("job_seeker_profile_id", profile.profile.id);
        formdata.append("introduction", intro);
        if (data.status != 200) {
            RequestM('job-seeker/resumes', formdata).then((response) => {
                toast.success(response.message, alertOptions)
            });
        } else {
            formdata.append('_method', 'put');
            RequestM('job-seeker/resumes/' + data.data.id, formdata).then((response) => {
                toast.success(response.message, alertOptions)
            });
        }
    }
    if (data !== null && profile !== null) {
        return (
            <>
                <ProfileStatus profile={profile}/>
                <div className="content p-2">
                    <div className="d-flex py-2 px-2">
                        <WorkOutlineOutlined className="me-2 text-two"/>
                        <h4 className="heading text-main my-0">Introduction</h4>
                    </div>
                    <form className="px-2 py-2" onSubmit={(e) => formSubmit(e)}>
                        <textarea name="" id="" cols="30" rows="7"
                                  maxLength={oldIntro !== null ? 2500 : 2500} onChange={(e) => {
                            setIntro(e.target.value);
                        }} className="mt-2 form-control" placeholder="Your introduction" value={intro}/>
                        <div className="text-right my-1 w-100 text-two small">
                            {intro !== null ? 2500 - intro.length : 2500} characters left
                        </div>
                        <Button type="submit" className="text-capitalize" size="small" variant={"contained"}
                                color="primary">Save</Button>
                    </form>
                </div>
            </>
        );
    } else {
        return loader("20rem")
    }
};

export default Introduction;
