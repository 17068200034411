import React from 'react'
import rightImg from "../assets/images/003m.png"
import logo from '../assets/images/talent-pool-logo.png'
import {Link} from "react-router-dom";
import {loader, mainURI, thumbnailsURI} from "../config";
import {RequestG} from "../routes/Request";
import {RequestGet} from "../employer/Request";
import {Pagination} from "@mui/material";

const Talent = () => {
    const [filters, setFilters] = React.useState(false);
    const [profile, setProfile] = React.useState(false);
    const [name, setName] = React.useState("");
    const [gender, setGender] = React.useState("");
    const [qualification, setQualification] = React.useState("");
    const [expirence, setExperience] = React.useState("");
    const [nationality, setNationality] = React.useState("");
    const [age, setAge] = React.useState("");
    const [sector, setSector] = React.useState("");
    const [type, setType] = React.useState("");
    const [category, setCategory] = React.useState("");
    const [location, setLocation] = React.useState("");
    const [talent, setTalent] = React.useState(null);
    const [page, setPage] = React.useState(1);
    React.useEffect(() => {
        if (localStorage.getItem('token')) {
            RequestGet('employ/company').then((response) => {
                setProfile(response)
            })
            if (profile.annual !== null) {
                RequestGet('employ/talent-pool?gender=' + gender + '&name=' + name + '&qualification=' + qualification
                    + '&experience=' + expirence + '&nationality=' + nationality
                    + '&age=' +
                    age + '&sector=' + sector + '&type=' + type + '&category' + category + '&location=' + location + '&page=' + page
                ).then((response) => {
                    setTalent(response)
                })
            }
        } else {
            setProfile(null)
        }
        if (filters === false) {
            RequestG('fields').then((response) => {
                setFilters(response)
            })
        }
    }, [filters, gender, qualification, expirence, nationality, age, sector, type, category, location, page, name])
    const pagination = (e) => {
        setPage(e.target.textContent);
        window.scrollTo(0, 0);
    }
    const resetFilter = () => {
        setName("")
        setGender("")
        setQualification("")
        setExperience("")
        setNationality("")
        setAge("")
        setSector("")
        setType("")
        setCategory("")
        setLocation("")
        setTalent(null)
        setPage(1)
    }
    if (filters !== false && profile !== false) {
        return (
            <>
                <div
                    className="talentPage align-items-center d-flex justify-content-center align-content-center"
                    style={{padding: "5rem 0"}}>
                    <div className="pt-4 container h-2/3 grid grid-cols-1  lg:grid-cols-3 gap-3 text-gray-500">
                        <form className="" onSubmit={(e) => e.preventDefault()}>
                            <div className="border-0 bg-white py-2">
                                <div className="w-full">
                                    <img src={logo} className="img-fluid w-50 m-auto py-3" alt=""/>
                                </div>
                                <div className="m-md-3 m-2 m-xl-4">
                                    <div className="form-group my-2">
                                        <input type="text" className="form-control" value={name} placeholder="Name"
                                               onChange={(e) => setName(e.target.value)}/>
                                    </div>
                                    <div className="form-group my-2">
                                        <select name="gender" value={gender} onChange={(e) => setGender(e.target.value)}
                                                id="gender"
                                                className="form-control">
                                            <option value="">Gender</option>
                                            {filters.map((data) => {
                                                if (data.field === 'gender') {
                                                    return <option value={data.value}>{data.value}</option>
                                                }
                                            })}
                                        </select>
                                    </div>
                                    <div className="form-group my-2">
                                        <select name="qualification" id="qualification" value={qualification}
                                                onChange={(e) => setQualification(e.target.value)}
                                                className="form-control">
                                            <option value="">Qualification</option>
                                            {filters.map((data) => {
                                                if (data.field === 'qualifications') {
                                                    return <option value={data.value}>{data.value}</option>
                                                }
                                            })}
                                        </select>
                                    </div>
                                    <div className="form-group my-2">
                                        <select name="experienced" id="experienced" value={expirence}
                                                onChange={(e) => setExperience(e.target.value)}
                                                className="form-control">
                                            <option value="">Experienced in</option>
                                            {filters.map((data) => {
                                                if (data.field === 'experience') {
                                                    return <option value={data.value}>{data.value}</option>
                                                }
                                            })}
                                        </select>
                                    </div>
                                    <div className="form-group my-2">
                                        <select name="nationality" id="nationality" value={nationality}
                                                onChange={(e) => setNationality(e.target.value)}
                                                className="form-control">
                                            <option value="">Nationality</option>
                                            {filters.map((data) => {
                                                if (data.field === 'nationality') {
                                                    return <option value={data.value}>{data.value}</option>
                                                }
                                            })}
                                        </select>
                                    </div>
                                    <div className="form-group my-2">
                                        <select name="age" id="age" className="form-control" value={age}
                                                onChange={(e) => setAge(e.target.value)}>
                                            <option value="">Age</option>
                                            <option value="lt25">Below 25</option>
                                            <option value="25-35">25 - 35 Years</option>
                                            <option value="gt35">Above 35</option>
                                        </select>
                                    </div>
                                    <div className="form-group my-2">
                                        <select name="work_sector" id="work_sector" className="form-control"
                                                value={sector}
                                                onChange={(e) => setSector(e.target.value)}>
                                            <option value="">Interested in: Work Sector</option>
                                            {filters.map((data) => {
                                                if (data.field === 'work_sectors') {
                                                    return <option value={data.value}>{data.value}</option>
                                                }
                                            })}
                                        </select>
                                    </div>
                                    <div className="form-group my-2">
                                        <select name="job_type" id="job_type" className="form-control" value={type}
                                                onChange={(e) => setType(e.target.value)}>
                                            <option value="">Interested in: Job Type</option>
                                            {filters.map((data) => {
                                                if (data.field === 'job_types') {
                                                    return <option value={data.value}>{data.value}</option>
                                                }
                                            })}
                                        </select>
                                    </div>
                                    <div className="form-group my-2">
                                        <select name="work_category" id="work_category" className="form-control"
                                                value={category} onChange={(e) => setCategory(e.target.value)}>
                                            <option value="">Interested in: Work Category</option>
                                            {filters.map((data) => {
                                                if (data.field === 'work_categories') {
                                                    return <option value={data.value}>{data.value}</option>
                                                }
                                            })}
                                        </select>
                                    </div>
                                    <div className="form-group my-2">
                                        <select name="location" id="location" className="form-control" value={location}
                                                onChange={(e) => setLocation(e.target.value)}>
                                            <option value="">Interested in: Location</option>
                                            {filters.map((data) => {
                                                if (data.field === 'preferred_locations') {
                                                    return <option value={data.value}>{data.value}</option>
                                                }
                                            })}
                                        </select>
                                    </div>
                                    <button type="reset" onClick={resetFilter}
                                            className="w-full bg-gray-600 mt-2 rounded text-white py-2 text-center">
                                        Clear all filters
                                    </button>
                                </div>
                            </div>
                        </form>
                        {profile !== null && profile !== undefined && profile.annual !== null && profile.annual !== undefined
                            ?
                            <div
                                className="col-span-2">
                                {talent !== null && talent.data !== undefined
                                    ?
                                    <>
                                        <div className="row">
                                            {talent.data.map((item) =>
                                                <>
                                                    <div className="col-6 col-lg-4 px-0">
                                                        <div
                                                            className="mx-1 text-center mb-2 card rounded-0 border-0">
                                                            <div className="card-image">
                                                                <img
                                                                    src={item.picture !== null ? thumbnailsURI + item.picture : mainURI + '/uploads/no-img.jpg'}
                                                                    alt="" className="img-fluid talentImg"/>
                                                            </div>
                                                            <div className="card-body">
                                                                <a href={`/view-profile/${item.id}`} target="_blank"
                                                                   className="talentHeading mt-2 mb-1 text-capitalize">{item.name}</a>
                                                                <p className="my-0 text-sm talentP font-medium">{item.qualification}</p>
                                                                <p className="my-0 text-sm talentP font-medium">{item.experience}</p>
                                                                <p className="my-0 text-sm talentP font-medium">{item.current_job}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                        <div>
                                            <Pagination
                                                count={Math.round(talent.total / 20)}
                                                onClick={(e) => pagination(e)}
                                                hidePrevButton hideNextButton
                                                color="primary"
                                                className="d-flex mt-5 justify-content-center"/>
                                        </div>
                                    </>
                                    : ""}
                            </div>
                            : <div
                                className="col-span-2 py-2 bg-white border border-gray-300 flex items-center justify-center flex-col">
                                <div className="flex items-center flex-col gap-3">
                                    <img src={rightImg} className="w-60" alt=""/>
                                    <div className="text-center">
                                        <h1 className="text-xl font-semibold text-gray-700">Get Subscribed</h1>
                                        <p className="font-medium text-sm">Talentpool access is limited to employers
                                            with active annual subscriptions. Browse hundreds of jobseeker profiles and
                                            contact them directly.</p>
                                    </div>
                                    {!localStorage.getItem('token') &&
                                    <Link to="/login"
                                          className="w-96 bg-pr mt-4 text-white py-3 font-medium text-center">
                                        Login
                                    </Link>}
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </>
        )
    } else {
        return (
            <>
                <div className="container" style={{paddingTop: "5rem"}}>
                    {loader("30rem")}
                </div>
            </>
        )
    }
}

export default Talent
