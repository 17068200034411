import React from 'react';
import {WorkOutlineOutlined} from "@mui/icons-material";
import {RequestG} from "../routes/Request";
import {loader} from "../config";
import AddExperience from "./modal/AddExperience";
import {RequestGet, RequestM} from "../employer/Request";
import Moment from "react-moment";
import "./design.scss";
import {AiFillDelete} from 'react-icons/ai';
import {IconButton} from '@mui/material';
import EditExp from "./modal/EditExp";
import ProfileStatus from "./alerts/ProfileStatus";

const Experience = () => {
    const [fields, setFields] = React.useState(false);
    const [resume, setResume] = React.useState(null);
    const [data, setData] = React.useState(null);
    const [update, setUpdate] = React.useState(true);
    const [profile, setProfile] = React.useState(null);
    React.useEffect(() => {
        if (fields === false) {
            RequestGet('user/profile').then((response) => {
                if (response.preference == null) {
                    window.location.href = '/pref'
                }
                if (response.profile == null) {
                    window.location.href = '/step'
                }
                setProfile(response)
            })
            RequestGet("user/resumes-usr").then((response) => {
                setResume(response);
            })
            RequestGet("user/experiences-usr").then((response) => {
                setData(response);
            })
            RequestG('fields').then((response) => {
                setFields(response)
            })
        }
        setUpdate(false)
    }, [fields])
    const deleteData = (id) => {
        RequestM('job-seeker/experiences/' + id, new FormData(), 'delete').then((response) => {
            setFields(false)
            setUpdate(true)
        })
    }
    if (fields !== false && resume !== null && profile !== null) {
        return (
            <>
                <ProfileStatus profile={profile}/>
                <div className="content p-2">
                    <div className="d-flex py-2 px-2">
                        <WorkOutlineOutlined className="me-2 text-two"/>
                        <h4 className="heading text-main my-0">Work Experience</h4>
                    </div>
                    <form className="px-2 py-2">
                        <div className="col-lg-12 text-right">
                            {resume.status === 200 ?
                                <AddExperience fields={fields}
                                               resume={resume.data}
                                               setFields={setFields}/> : "Please Fill-up your introduction"}
                        </div>
                        {data !== null &&
                        data.map((item, index) =>
                            <div className='grid grid-cols-1 lg:grid-cols-4 pt-3'>
                                <div>
                                    <p className='text-gray-500 my-1'>
                                        <Moment
                                            format="DD MMM YYYY">{item.year_from}</Moment> - {parseInt(item.current) === 1 ? "Current"
                                        : item.year_to !== null ?
                                            <Moment format="DD MMM YYYY">{item.year_to}</Moment> : "Current"
                                    }
                                    </p>
                                </div>
                                <div className='lg:col-span-2'>
                                    <p className='font-semibold text-gray-700 my-1'>{item.employer}</p>
                                    <p className='italic text-gray-500 my-1'>{item.job_title}</p>
                                    <div className='pt-2 flex items-center gap-1'>
                                        <p className='font-semibold text-gray-700 my-1'>Job Category :</p>
                                        <p className=' italic text-gray-500 my-1'>{item.job_category}</p>
                                    </div>
                                    <div className='flex items-center gap-1'>
                                        <p className='my-1 font-semibold text-gray-700'>Referee Name :</p>
                                        <p className='my-1 text-gray-500'>{item.referee_name}</p>
                                    </div>
                                    <div className='flex items-center gap-1'>
                                        <p className='my-1 font-semibold text-gray-700'>Referee Email :</p>
                                        <p className='my-1 text-gray-500'>{item.referee_contact}</p>
                                    </div>
                                    <div className='flex items-center gap-1'>
                                        <p className='my-1 font-semibold text-gray-700'>Referee Phone :</p>
                                        <p className='my-1 text-gray-500'>{item.referee_phone}</p>
                                    </div>
                                    <div>
                                        <p className='font-semibold text-gray-700 pt-1 mb-1'>Summary of Work Done
                                            :</p>
                                        <p className='text-gray-500 text-sm my-0'>{item.work_summary}</p>
                                    </div>
                                </div>
                                <div className='lg:col-span-1'>
                                    <EditExp item={item} fields={fields} setFields={setFields} setData={setData}
                                             resume={resume.data}/>
                                    <IconButton aria-label="delete" onClick={() => deleteData(item.id)} size="small">
                                        <AiFillDelete/>
                                    </IconButton>
                                </div>
                            </div>
                        )
                        }
                    </form>
                </div>
            </>
        );
    } else {
        return loader("20rem")
    }
};

export default Experience;
