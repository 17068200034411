import React from 'react'
import {AiFillCheckCircle} from "react-icons/ai"
import {loader, sortedFields} from "../config";
import {RequestG} from "../routes/Request";
import {Pagination} from '@mui/material';
import Moment from "react-moment";
import ViewJob from "../modals/ViewJob";
import "./design.scss";
import {RequestGet} from "../employer/Request";
import {FaTimesCircle} from 'react-icons/fa';

const Index = () => {
    const [update, setUpdate] = React.useState(true);
    const [data, setData] = React.useState(false);
    const [filters, setFilters] = React.useState(false);
    const [countData, setCountData] = React.useState(false);
    const [sector, setSector] = React.useState("");
    const [category, setCategory] = React.useState("");
    const [type, setType] = React.useState("");
    const [salary, setSalary] = React.useState("");
    const [location, setLocation] = React.useState("");
    const [page, setPage] = React.useState(1);
    const [profile, setProfile] = React.useState(false);
    const [openJob, setOpenJob] = React.useState(0);
    React.useEffect(() => {
        if (update === true) {
            if (filters === false) {
                RequestG('fields').then((response) => {
                    setFilters(response)
                })
            }
            if (data === false) {
                RequestG('allJobs/filter?page=' + page).then((response) => {
                    setOpenJob(response.count)
                    setData(response.jobs)
                })
            }
            if (countData === false) {
                RequestG('homedash').then((response) => {
                    setCountData(response)
                })
            }
            if (localStorage.getItem('token')) {
                RequestGet('user/profile').then((response) => {
                    setProfile(response)
                })
            }
            setUpdate(false)
        }
    }, [update, data, filters, countData])
    const changeAll = (e) => {
        setSector("")
        setCategory("")
        setType("")
        setSalary("")
        setLocation("")
        setPage(1)
    }
    React.useEffect(() => {
        RequestG("allJobs/filter?sector=" + sector + "&category=" + category + "&type=" + type + "&salary=" + salary + "&location=" + location + "&page=" + page).then((response) => {
            setOpenJob(response.count)
            setData(response.jobs)
        })
    }, [sector, category, type, salary, location, page]);
    const pagination = (e) => {
        setPage(e.target.textContent);
        window.scrollTo(0, 0);
    }
    const roundedNumber = 1000;

    function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    if (update !== true && data !== false && filters !== false && countData !== false) {
        return (
            <div className="container" style={{paddingTop: '5rem'}}>
                <div
                    className="bg-pr d-none d-sm-flex mt-4 flex items-center justify-center gap-x-10 text-white py-3 rounded-md flex-wrap">
                    <div
                        className="text-xl font-medium">{numberWithCommas(parseInt(countData.posted) + 6471)} Jobs
                        Posted
                    </div>
                    <div className="text-xl font-medium">{countData.open} Open Jobs</div>
                    <div
                        className="text-xl font-medium">{numberWithCommas(countData.seekers)} Jobseekers
                    </div>
                    <div
                        className="text-xl font-medium">1,000+
                        Employers
                    </div>
                </div>
                <form
                    className="filterContentIndex mt-4">
                    <select name="sector" id="sector" value={sector} onChange={(e) => setSector(e.target.value)}
                            className="form-select item first">
                        <option value="">Sector</option>
                        {sortedFields("work_sectors", filters).map((data) => {
                            return <option key={data.value} value={data.value}>{data.value}</option>
                        })}
                    </select>
                    <select name="category" id="category" className="form-select item" value={category}
                            onChange={(e) => setCategory(e.target.value)}>
                        <option value="">Category</option>
                        {sortedFields('work_categories', filters).map((data) => {
                            return <option key={data.value} value={data.value}>{data.value}</option>
                        })}
                    </select>
                    <select name="type" id="type" className="form-select item" value={type}
                            onChange={(e) => setType(e.target.value)}>
                        <option value="">Job Type</option>
                        {sortedFields('job_types', filters).map((data) => {
                            return <option key={data.value} value={data.value}>{data.value}</option>
                        })}
                    </select>
                    <select name="salary" id="salary" className="form-select item" value={salary}
                            onChange={(e) => setSalary(e.target.value)}>
                        <option value="">Salary</option>
                        {filters.map((data) => {
                            if (data.field === 'salary_range') {
                                return <option key={data.value} value={data.value}>{data.value}</option>
                            }
                        })}
                    </select>
                    <select name="location" id="location" className="form-select item" value={location}
                            onChange={(e) => setLocation(e.target.value)}>
                        <option value="">Location</option>
                        {sortedFields('preferred_locations', filters).map((data) => {
                            return <option key={data.value} value={data.value}>{data.value}</option>
                        })}
                    </select>
                    <div className="item last">
                        <button type='button' onClick={(e) => changeAll()} className="text-gray-600 btn font-medium"
                                style={{fontWeight: '700'}}>
                            <FaTimesCircle className="w-5 h-5"/>
                        </button>
                    </div>
                </form>
                <h2 className="text-center font-medium headingSection py-4 mb-0">
                    <span className="me-sm-2">Nation's greatest job-machine. Never miss a job offer</span>
                    <span className="openJobWarn d-block d-sm-inline my-3 my-sm-0">{openJob} openings</span>
                </h2>
                <>
                    {data.data.map((item, index) => (
                        <div key={item.id}
                             className={`py-2 my-1 row mx-0 pl-4 jobItems border-b border-gray-300 ${index === 0 ? "round-main-top" : ""} ${index === 19 ? "round-main-bottom" : ""}`}>
                            <div className="col-lg-6">
                                <div className="md:col-span-6 col-span-5 text-gray-700 text-sm pl-0 lg:pl-8">
                                    <h1 className="text-main2 text-ellipsis mb-2">
                                        <ViewJob id={item.id} title={item.title} profile={profile}/>
                                    </h1>
                                    <div
                                        className="flex salary_range_main_sm_con items-center gap-3 text-xs text-gray-600">
                                        <p className="flex mb-0 items-center gap-1">{item.name} {item.is_verified == 1 &&
                                        <AiFillCheckCircle className="w-3 h-3 ml-1 text-pr"/>}</p>
                                        <p className="flex mb-0 salary_range_main items-center gap-1">{item.salary_range} / {item.location}</p>
                                        <p className="flex mb-0 salary_range_main_sm items-center gap-1">
                                            Posted <Moment fromNow>{item.created_at}</Moment>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="text-gray-600 salary_range_lg mt-3 text-xs">
                                    {item.salary_range} / {item.location}
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="text-gray-600 salary_range_lg mt-3 text-xs">
                                    Posted <Moment fromNow>{item.created_at}</Moment>
                                </div>
                            </div>
                        </div>
                    ))}
                    <div className="py-3 text-center">
                        <Pagination
                            count={Math.ceil(data.total / 20)}
                            onClick={(e) => pagination(e)}
                            hidePrevButton hideNextButton
                            color="primary"
                            className="d-flex justify-content-center"/>
                    </div>
                </>
            </div>
        )
    } else {
        return (
            <div style={{paddingTop: "5rem"}}>
                <div className="container">{loader("50rem")}</div>
            </div>
        )
    }
}

export default Index
