import React from 'react';
import {Button, FormControlLabel, Radio, RadioGroup, TextField} from "@mui/material";
import InvForm from "./InvForm";

const SubsContent = ({option, setOption, numberPage, setNumberPage, eTin, setETin, formSubmit, userPack}) => {
    return (
        <>
            <form onSubmit={(e) => formSubmit(e)} className="col-lg-4">
                <p>Please choose a package to continue</p>
                {/*defaultChecked*/}
                <RadioGroup
                    className="my-3"
                    defaultValue={option}
                    onChange={(e) => setOption(e.target.value)}
                    name="radio-buttons-group">
                    <FormControlLabel value="1" control={<Radio/>} label="Annual"/>
                    <FormControlLabel value="2" control={<Radio/>} label="Pay-per-job"/>
                </RadioGroup>
                <InvForm setNumberPage={setNumberPage} option={option} numberPage={numberPage} userPack={userPack}/>
                <div className="mt-2">
                    <TextField className="mt-3 w-100" id="outlined-basic"
                               label="Enter TIN Number (optional)"
                               onChange={(e) => setETin(e.target.value)}
                               variant="outlined" value={eTin}/>
                </div>
                <div className="mt-2">
                    <Button color="info" type="submit" className="w-100 text-capitalize"
                            variant={"contained"}>Generate Invoice</Button>
                    <p className="mt-2 mb-0 small">Note: Your account will be activated once
                        payment settled.</p>
                </div>
            </form>
        </>
    );
};

export default SubsContent;
