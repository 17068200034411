import React from 'react';

const ViewApplications = () => {
    return (
        <>
        sdlfh
        </>
    );
};

export default ViewApplications;
