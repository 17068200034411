import React from 'react';
import {Container} from "react-bootstrap";
import image1 from "../assets/images/foot-01.png";
import image2 from "../assets/images/foot-02.png";
import image3 from "../assets/images/foot-03.png";
import {Link} from "react-router-dom";

const Footer = () => {
    return (
        <div className="footer py-3">
            {/*fluid*/}
            <Container>
                <div className="text-center pt-3">
                    <h4>©Jobsicle - The no. 1 career brand and Job portal in Maldives since 2016</h4>
                    <p className="pt-2 mb-1">Every job posted on <a href="https://jobsicle.mv" target="_blank"
                                                                    rel="noreferrer">Jobsicle</a> will be
                        instantly published on <a href="https://careers.mv" target="_blank"
                                                  rel="noreferrer">careers.mv</a>, <a
                            href="https://atolljobs.com" target="_blank" rel="noreferrer">atolljobs.com</a>, <a
                            href="https://vazyfa.com" target="_blank" rel="noreferrer">vazyfa.com</a> and our social
                        media accounts</p>
                    <p className="py-3 text-center">
                        <b>To Post a Job: </b> Simply sign up as an employer, subscribe for a package and start
                        publishing and managing job listing on your own. <br className="my-1"/>
                        <a href="/pricing">Click here to view pricing.</a> For assistance, reach us on <b>+960
                        7379494</b> or <b>mail@jobsicle.mv</b>
                    </p>
                </div>
            </Container>
            <hr className="my-2"/>
            <Container>
                <div className="d-flex footerImg py-4 justify-content-center">
                    <div className="item"><img src={image1} className="img-fluid" alt=""/></div>
                    <div className="item"><img src={image2} className="img-fluid" alt=""/></div>
                    <div className="item"><img src={image3} className="img-fluid" alt=""/></div>
                </div>
                <div className="text-center footLinks">
                    <a href="https://facebook.com/jobsicle" target="_blank" rel="noreferrer">Facebook </a> <span
                    className="mx-1">|</span>
                    <a href="https://www.instagram.com/jobsicle" target="_blank" rel="noreferrer">Instagram </a> <span
                    className="mx-1">|</span>
                    <a href="https://twitter.com/jobsiclemv" target="_blank" rel="noreferrer">Twitter </a> <span
                    className="mx-1">|</span>
                    <a href="https://mv.linkedin.com/company/jobsicle-maldives/" target="_blank"
                       rel="noreferrer">Linkedin </a>
                    <br/>
                    <p><a href="/tos" target="_blank">Terms of Use & Privacy Policy</a></p>
                </div>
            </Container>
        </div>
    );
};

export default Footer;
