import React from 'react';
import {Nav} from "react-bootstrap";
import {NavLink} from "react-router-dom";
import {loader, websiteData} from "../config";
import {ReceiptOutlined, WorkOutlineOutlined, DescriptionOutlined} from "@mui/icons-material";
import {RequestGet} from "../employer/Request";
import {
    MdOutlineContactPage,
    MdOutlineWorkOutline,
    MdFilterList,
    MdOutlinePublic,
    MdOutlineUploadFile
} from 'react-icons/md';
import {RiAccountBoxLine} from 'react-icons/ri';
import {IoSchoolOutline, IoSettingsOutline} from 'react-icons/io5';
import "./design.scss";

const SideBar = ({authentication}) => {
    const [company, setCompany] = React.useState(false);
    const [user, setUser] = React.useState(false);
    React.useEffect(() => {
        if (company === false && authentication.role == 2) {
            RequestGet('employ/company').then((response) => {
                setCompany(response)
            })
        }
        if (user === false && authentication.role == 1) {
            RequestGet('user/job-seeker-profile-usr').then((response) => {
                setUser(response.data)
            })
        }
    }, [company, user])
    if (company !== false || user !== false) {
        return (
            <>
                <div className="col-lg-3 col-xxl-3 px-0">
                    <div className="leftSide">
                        <Nav defaultActiveKey="/company" className="flex-column">
                            <NavLink to={authentication.role == 2 ? "/my-company" : "/profile"}
                                     activeClassName={"active"}
                                     className="companyNav align-items-center row mx-0 py-3">
                                <div className="col-3">
                                    <img className="logo" src={websiteData.favicon} alt=""/>
                                </div>
                                {authentication.role == 2 &&
                                <div className="col-9 ps-0">
                                    <h4 className="heading">{company.company.name}</h4>
                                    <p className="mb-0">Company Profile</p>
                                </div>
                                }
                                {authentication.role == 1 &&
                                <div className="col-9 ps-0">
                                    <h4 className="heading">{user !== undefined ? user.name : "N/A"}</h4>
                                    <p className="mb-0">User Profile</p>
                                </div>
                                }
                            </NavLink>
                            {authentication.role == 2 &&
                            <>
                                <NavLink activeClassName={"active"} className="nav-link d-flex py-3" to="/subscriptions">
                                    <ReceiptOutlined className="me-2"/> Subscriptions
                                </NavLink>
                                <NavLink activeClassName={"active"} className="nav-link d-flex py-3" to="/jobs">
                                    <WorkOutlineOutlined className="me-2"/> Jobs
                                </NavLink>
                                <NavLink activeClassName={"active"} className="nav-link d-flex py-3" to="/job-applications">
                                    <DescriptionOutlined className="me-2"/> Job
                                    Applications <b
                                    className="totalApplications">{company.applications !== undefined ? company.applications : 0}</b>
                                </NavLink>
                            </>
                            }
                            {authentication.role == 1 &&
                            <>
                                <NavLink activeClassName={"active"} className="nav-link py-3" to="/basic-info">
                                    <RiAccountBoxLine className="me-2 w-5 h-5 d-inline-block"/> Basic Information
                                </NavLink>
                                <NavLink activeClassName={"active"} className="nav-link py-3" to="/introduction">
                                    <MdOutlineContactPage className="me-2 w-5 h-5 d-inline-block"/> Introduction
                                </NavLink>
                                <NavLink activeClassName={"active"} className="nav-link py-3" to="/work-experience">
                                    <MdOutlineWorkOutline className="me-2 w-5 h-5 d-inline-block"/> Work Experience
                                </NavLink>
                                <NavLink activeClassName={"active"} className="nav-link py-3" to="/educations">
                                    <IoSchoolOutline className="me-2 w-5 h-5 d-inline-block"/> Education
                                </NavLink>
                                <NavLink activeClassName={"active"} className="nav-link py-3" to="/skills">
                                    <MdFilterList className="me-2 w-5 h-5 d-inline-block"/> Skills
                                </NavLink>
                                <NavLink activeClassName={"active"} className="nav-link py-3" to="/languages">
                                    <MdOutlinePublic className="me-2 w-5 h-5 d-inline-block"/> Languages
                                </NavLink>
                                <NavLink activeClassName={"active"} className="nav-link py-3" to="/documents">
                                    <MdOutlineContactPage className="me-2 w-5 h-5 d-inline-block"/> Documents
                                </NavLink>
                                <NavLink activeClassName={"active"} className="nav-link py-3" to="/resumes">
                                    <MdOutlineUploadFile className="me-2 w-5 h-5 d-inline-block"/> Upload Resume
                                </NavLink>
                                <NavLink activeClassName={"active"} className="nav-link py-3" to="/setting">
                                    <IoSettingsOutline className="me-2 w-5 h-5 d-inline-block"/> Privacy Settings
                                </NavLink>
                                <hr className="my-0"/>
                                <NavLink activeClassName={"active"} className="nav-link py-2" to="/my-applications">
                                    Job Applications
                                </NavLink>
                                <NavLink activeClassName={"active"} className="nav-link py-2" to="/my-jobs">
                                    Saved Jobs
                                </NavLink>
                                <NavLink activeClassName={"active"} className="nav-link py-2" to="/my-preference">
                                    My Preference
                                </NavLink>
                            </>
                            }
                        </Nav>
                    </div>
                </div>
            </>
        );
    } else {
        return <div className="col-lg-3 col-xxl-2 px-0">{loader("20rem")}</div>;
    }
};

export default SideBar;
